import React, { Component } from "react";
import FormError from "./FormError";
import { Link, navigate } from "@reach/router";
import Axios from "axios";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileId: "",
      email: "",
      password: "",
      errorMessage: null,
      isChecked: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (localStorage.checkbox && localStorage.email !== "") {
        this.setState({
            isChecked: true,
            email: localStorage.username,
            password: localStorage.password
        })
    }
}

  handleChange(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;
    this.setState({ [itemName]: itemValue });
  }

  onChangeCheckbox = event => {
    this.setState({
        isChecked: event.target.checked
    })
}
  handleSubmit(e) {
    e.preventDefault();
    const { email, password, isChecked } = this.state
        if (isChecked && email !== "") {
            localStorage.username = email
            localStorage.password = password
            localStorage.checkbox = isChecked
        }
    Axios.post("https://foodsystemsdb.extension.iastate.edu/api/login", {
      email: this.state.email,
      password: this.state.password,
    }).then((response) => {
      if (!response.data.auth) {
        this.setState({ errorMessage: "Wrong Email/Password Combination" });
      } else {
        localStorage.setItem("token", response.data.token);
        this.props.loginUser(this.state.displayName);
        navigate("/");
      }
    });
  }

  render() {
    return (
      <form className="my-5 pb-5 mnHeight" onSubmit={this.handleSubmit}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="card bg-light">
                <div className="card-body">
                  <h3 className="font-weight-light mb-3">Login</h3>
                  <section className="form-group">
                    {this.state.errorMessage !== null ? (
                      <FormError theMessage={this.state.errorMessage} />
                    ) : null}
                    <label className="form-control-label sr-only">Email</label>
                    <input
                      required
                      className="form-control"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </section>
                  <section className="form-group">
                    <input
                      required
                      className="form-control"
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                  </section>
                  <section className="form-group">
                    <span className="float-left"><Link to="/forgotPassword">Forgot password?</Link></span><span className="float-right"><input className="form-check-input" type="checkbox" checked={this.state.isChecked} name="lsRememberMe" onChange={this.onChangeCheckbox}/>
                    <label className="form-check-label">Remember me</label> </span>
                  </section>
                  <div className="form-group mt-5">
                   <button className="btn col-4 ml-5 btn-primary float-left" type="submit">
                      Log in
                     </button><span className="btn ml-5 col-4 btn-outline-primary"><Link to="/register">Register</Link></span>
                  </div> 
                  {/* <div className="pt-3 text-center">
                    <span className="float-left"></span> <span className="float-right"></span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Login;
