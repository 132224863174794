import React, { useEffect, Component } from "react";
// import AwesomeSlider from "react-awesome-slider";
// import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import Axios from "axios";
import { divIcon } from "leaflet";
import L from "leaflet";
import { Link } from "@reach/router";
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, GoogleProvider } from "leaflet-geosearch";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { renderToStaticMarkup } from "react-dom/server";

// const AutoplaySlider = withAutoplay(AwesomeSlider);
function LeafletgeoSearch() {
  const map = useMap();
  useEffect(() => {
    const provider = new GoogleProvider({
      params: {
        key: "AIzaSyBILXbE28XInB6iIU-y3vzbIo1jvUr0Zo0",
      },
    });

    const searchControl = new GeoSearchControl({
      provider,
    });

    map.addControl(searchControl);

    return () => map.removeControl(searchControl);
  }, []);

  return null;
}

function Legend() {
  const map = useMap();
  useEffect(() => {
    const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      div.innerHTML =
        '<span><i class="far fa-user"></i><span class ="legend_color">  National Practitioner</span></br>' +
        '<i class="far fa-book-open"></i> <span class ="legend_color">  National Educational Resource</span></br>' +
        '<i class="fas fa-user-alt"></i> <span class ="legend_color"> State/Local Practitioner</span></br>' +
        '<i class="fas fa-book-open"></i> <span class ="legend_color"> State/Local Educational Resource</span></span>';
      return div;
    };

    legend.addTo(map);
  }, []);

  return null;
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      eduMarkers: [],
    };
  }

  componentDidMount() {
    Axios.get("https://foodsystemsdb.extension.iastate.edu/api/allGeoLoc").then(
      (response) => {
        if (response.data.length > 0) {
          this.setState({ markers: response.data });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );

    Axios.get(
      "https://foodsystemsdb.extension.iastate.edu/api/allEduGeoLoc"
    ).then(
      (response) => {
        if (response.data.length > 0) {
          this.setState({ eduMarkers: response.data });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );

    Axios.get("files/us-counties.json").then((response) => {
      this.setState({ us_counties: response.data });
    });
  }

  render() {
    const practitionerIconMarkup = renderToStaticMarkup(
      <i className="fas fa-user-alt fa-2x" />
    );
    const practitionerMarkerIcon = divIcon({
      html: practitionerIconMarkup,
    });

    const eduIconMarkup = renderToStaticMarkup(
      <i className="fas fa-book-open fa-2x" />
    );
    const eduMarkerIcon = divIcon({
      html: eduIconMarkup,
    });

    const nationalPractitionerIconMarkup = renderToStaticMarkup(
      <i className="far fa-user fa-2x" />
    );
    const nationalPractitionerMarkerIcon = divIcon({
      html: nationalPractitionerIconMarkup,
    });

    const nationalEduIconMarkup = renderToStaticMarkup(
      <i className="far fa-book-open fa-2x" />
    );
    const nationalEduMarkerIcon = divIcon({
      html: nationalEduIconMarkup,
    });

    return (
      <div className="text-center mxWidth">
        <div className="container-fluid bg-light p-0">
          
          {/* <AutoplaySlider play={true} interval={3000}>
            <div data-src="images/4.jpg" />
            <div data-src="images/1.jpg" />
            <div data-src="images/FS Database Logo_2021.png" />
            <div data-src="images/5.jpg" />
            <div data-src="images/6.jpg" />
            <div data-src="images/7.jpg" />
            <div data-src="images/8.jpg" />
            <div data-src="images/9.jpg" />
            <div data-src="images/10.jpg" />
          </AutoplaySlider> */}
          <div className="row justify-content-center align-items-center p-5">
            <img src="images/4.jpg" className="col-12 opacity-50"/>
          </div>
          <div className="row justify-content-center align-items-center bg-cover">
          
            <div className="col-10 col-md-10 col-lg-8 col-xl-7 font-weight-bold fspTextandButton">
              <h1 className="text-light py-2">
                Food Systems Practitioner and Education Resource Database{" "}
              </h1>
            </div>
          </div> 
          <div className="row justify-content-center align-items-center bg-light p-5 rounded">
            <h4 className="pb-5">
              Our mission is to aggregate and identify educational resources to
              improve the competencies of food systems practitioners through
              various levels of learning that in turn stabilizes and strengthens
              our food systems workforce.
            </h4>
            <h4 className="pb-5">
              We believe in the critical juncture of local, regional, and global food systems evolution and recognize the need for highlighting partner networks and educational resources for food systems practitioners. We are continually experiencing changes in educational offerings. Whether related to accessibility, equitable access, language translations, or sliding-scale pricing, this database serves as a research tool for identifying learning opportunities and assessing educational resources in food systems.
            </h4>
            <div className="row">
              <div className="col-sm p-3">
                <a href="/register">
                  <img src="CreateProfile.png"  alt="Create a Profile" className="img-fluid p-5"/>
                </a>
              </div>
              <div className="col-sm p-3">
                <a href="/newEducationalResource">
                  <img src="ShareResource.png" alt="Add a Resource" className="img-fluid p-5"/>
                </a>
              </div>
              <div className="col-sm p-3">
                <a href="/search">
                  <img src="search.png"
                    alt="Search"
                    className="img-fluid p-5"
                  />
                </a>
              </div>
              </div>
            
          </div>
        </div>
        <div className="row bg-light justify-content-center align-items-center">
          <h3>Find practitioners and resources by location </h3>
          <MapContainer
            center={[37.09, -95.71]}
            zoom={5}
            scrollWheelZoom={false}
          >
            <Legend />
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <MarkerClusterGroup>
              {this.state.markers.map((ven, index) => (
                <Marker 
                  key={index}
                  position={JSON.parse(ven.geojson).coordinates}
                  icon={ven.area_of_work == "National"? nationalPractitionerMarkerIcon: practitionerMarkerIcon}
                >
                  <Popup>
                    <div className="poup-text">
                      <Link to={"/profileSearch?Id=" + ven.profileId}>
                        {ven.name}
                      </Link>
                    </div>
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>

            <MarkerClusterGroup>
              {this.state.eduMarkers.map((ven, index) => (
                <Marker
                  key={index}
                  position={JSON.parse(ven.geojson).coordinates}
                  icon={ven.location == "National" ? nationalEduMarkerIcon:eduMarkerIcon}
                >
                  <Popup>
                    <div className="poup-text">
                      <Link to={"/eduResourceSearch?Id=" + ven.curricula_id}>
                        {ven.curricula_title}
                      </Link>
                    </div>
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
            <LeafletgeoSearch />
          </MapContainer>
        </div>
        <div className="mb-5 mt-5">
          <h2>Questions?</h2>
              <p>Contact Kaley Hohenshell at <a href="mailto:foodsystemsdb@iastate.edu">foodsystemsdb@iastate.edu </a> or visit the <a href="/FAQ">FAQ </a>page. </p>

          <h2>Categories </h2>
          <p>We use core competencies to aggregate and highlight individuals’ and organizations’ work through nine categories: </p>
          <div className="row">
            <div className="col-sm"><p></p></div>
            <div className="col-sm"><p className="ml-5">Food Systems</p></div>
            <div className="col-sm"><p>Economy and Business Analysis </p></div>
            <div className="col-sm"><p className="mr-5">Environment</p></div>
            <div className="col-sm"><p></p></div>
          </div>
          <div className="row">
            <div className="col-sm"><p></p></div>
            <div className="col-sm"><p className="ml-5">Equity</p></div>
            <div className="col-sm"><p>Governance and Policy</p></div>
            <div className="col-sm"><p className="mr-5">Leadership</p></div>
            <div className="col-sm"><p></p></div>
          </div>
          <div className="row">
            <div className="col-sm"><p></p></div>
            <div className="col-sm"><p  className="ml-5">Community Capacity</p></div>
            <div className="col-sm"><p>Health and Wellness</p></div>
            <div className="col-sm"><p className="mr-5">Evaluation</p></div>
            <div className="col-sm"><p></p></div>
          </div>
          <a href="/competencies">Learn more about food system core competencies. </a>
        </div>
        <div className="mb-5 mt-5">
          {/* <div>
            <p>
              Special thanks to partners who supported development of core
              competencies.
            </p>
            <p>
              For a list of partners and project outcomes, please see reports
              below. <br />
              <a
                href="https://www.extension.iastate.edu/ffed/wp-content/uploads/2020_CoreCompetencyFinalReport2.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Food System Core Competency Project
              </a>{" "}
              <br />
              <a
                href="https://www.ams.usda.gov/sites/default/files/media/IowaStateFoodSystemCoreCompetencyProject.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Food System Core Competency Project Summary
              </a>
            </p>
            <p>Thank you to partners associated with database development.</p>
          </div> */}
          <h2>Parters</h2>
          <p>Thank you to our partners who supported the development of this database. </p>
          <div className="p-5">
            <div className="row">
              <div className="col-sm p-4">
                <a
                  href="https://extension.osu.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="OSU-FAES-Stacked-RGBHEX.png"
                    alt="Ohio State University"
                    className="img-fluid p-5"
                  />
                </a>
              </div>
              <div className="col-sm p-4">
                <a
                  href="https://www.ncat.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="ATTRAlogo-RGB 3-2019.jpg"
                    alt="National Center for Appropriate Technology and ATTRA- Sustainable Agriculture Program"
                    className="img-fluid"
                  />
                </a>
                <a
                  href="https://farmland.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="AFT logo with tagline.jpg"
                    alt="American Farmland Trust"
                    className="img-fluid mt-5"
                  />
                </a>
              </div>
              <div className="col-sm p-4">
                <a
                  href="http://foodsystems.colostate.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="FoodSys-AgResEco-AG-CSU-C357.png"
                    alt="Colorado State University"
                    className="img-fluid p-2"
                  />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm p-4">
                <a
                  href="https://www.wallacecenter.org/ "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="Wallace Logo FINAL.jpg"
                    alt="Wallace Center"
                    className="img-fluid p-5"
                  />
                </a>
              </div>
              <div className="col-sm p-4">
                <a
                  href="https://localfood.ces.ncsu.edu/ "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="ncstate-ext-2x1-b-h-red-blk-cmyk.jpg"
                    alt="North Carolina State Extension"
                    className="img-fluid"
                  />
                </a>
                <a
                  href="https://clf.jhsph.edu/ "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="CLF.jpg"
                    alt="North Carolina State Extension"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="col-sm p-4">
                <a
                  href="https://foodsystems.extension.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="eXtension .png"
                    alt="eXtension: Community, Local and Regional Food Systems"
                    className="img-fluid p-3"
                  />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm p-3">
                <a
                  href="https://www.uvm.edu/extension/necafs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="necafslogo_1000x338.gif"
                    alt="Northeast Center to Advance Food Safety"
                    className="img-fluid p-3"
                  />
                </a>
              </div>
              <div className="col-sm p-4">
              <a
                  href="https://extension.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="extensionFoundation.jpg"
                    alt="Extension Foundation"
                    className="img-fluid p-3"
                  />
                </a>
                <a
                  href="https://www.extension.iastate.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="ISUEO.jpg"
                    alt="Iowa State University Extension and Outreach"
                    className="img-fluid mt-2"
                  />
                </a>
              </div>
              <div className="col-sm p-4">
                <a
                  href="https://www.foodsystemsnetwork.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="nafsn-logo.jpg"
                    alt="North American Food Systems Network"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
