import React, { Component } from "react";

class FAQ extends Component {
  render() {
    return (
      <div className="container-fluid mnHeight">
        <div className="row justify-content-center align-items-center bg-colorPantone p-4">
          <h1 className="text-dark">Frequently Asked Questions (FAQ)</h1>
        </div>
        <div className="container pb-5 mb-5">
          {/* <p className="mt-3"><iframe width="100%" height="515" src="https://www.youtube.com/embed/eSKuWg03rN0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
          <h4 className="mt-3 p-2">
            Practitioner profiles vs. Educational Resource profiles
          </h4>
          <p className="ml-3">
            Individuals will begin by creating practitioner profiles.
            Practitioner Profiles will be publicly searchable. It is required to
            share your name, contact email, organization affiliation (primary
            workplace), location of work, and personal core competencies related
            to food systems.
            <br />
            Each practitioner profile must include the following:
            <ul>
              <li>Full Name</li>
              <li>Email</li>
              <li>Primary Organization Affiliation</li>
              <li>Primary and Secondary Competency</li>
              <li>Work Location</li>
            </ul>
            <p>
              Individuals will also be able to share biographies, websites,
              educational resources and additional resources.{" "}
            </p>
            <p>
              After creating a practitioner profile, educational resources can
              be added.
            </p>
            <p>
              Educational resources will be publicly searchable. Educational
              resources shared through this database must provide the following:{" "}
            </p>
            <ul>
              <li>Title of Resource</li>
              <li>Audience</li>
              <li>Location</li>
              <li>Method of Delivery</li>
              <li>Contact</li>
              <li>Organization Affiliation</li>
              <li>
                <a href="/competencies">Competencies</a> taught through
                educational resource- see competency page for more information
              </li>
              <ul>
                <li>Category</li>
                <li>Theme</li>
                <li>Competence</li>
              </ul>
            </ul>
            In addition, the educational resource profile can share hours needed
            for completion, fee for participation, completion certificate and
            website. Once an educational resource is created it will be
            searchable through the &quot;search&quot; aspect of the database.
          </p> */}
          <h4 className="mt-4 p-2">How do I create a profile? </h4>
          <p className="ml-3">
            Individuals can create a profile by{" "}
            <a href="/register">registering</a> in the database. Select
            “Register” at the top right of the menu bar. Complete your
            practitioner profile by sharing required information: name, email,
            primary organization, work address, and competencies. Profiles will
            be added to the database and be publicly searchable.{" "}
          </p>
          <p className="ml-3">
            Watch our{" "}
            <a
              href="https://www.youtube.com/watch?v=eSKuWg03rN0"
              target="_blank"
              rel="noopener noreferrer"
            >
              video tutorial
            </a>{" "}
            for more information.{" "}
          </p>

          <h4 className="mt-4 p-2">
            What happens after I create a practitioner profile?{" "}
          </h4>
          <p className="ml-3">
            After registering, a confirmation email with further instructions
            will be sent to the email address provided. This email will share
            information for logging into your profile, accessing educational
            resource updates, and additional suggestions for engaging with the
            database and other networks.
          </p>

          <h4 className="mt-4 p-2">
            How do I edit or update information on my profile?{" "}
          </h4>
          <p className="ml-3">
            Update your profile information by selecting “your practitioner
            profile.” At the top right of the page, select “Edit.” Update or add
            information and select “Save” at the top right of the page when
            completed. Practitioner profiles will be live immediately after
            creation. Iowa State University Extension and Outreach reserves the
            right to review practitioner profiles and educational resources
            periodically. If there are discrepancies or inaccurate information,
            we will freeze the account and request edits or additional
            information. Upon this action, you will receive an email to notify
            you of the concern and how to amend information{" "}
          </p>
          <h4 className="mt-4 p-2">
          How do I add educational resources to my profile?{" "}
          </h4>
          <p className="ml-3">
          Add educational resources offered through your organization to your profile by logging in to your profile, select “your educational resources” in the dropdown menu under your profile name in the top right of the menu bar. Complete required information for the resource: title, audience, location, method of delivery, core competencies, learning objectives, and primary contact for each listing. Educational resources will be added to the database and publicly searchable. {" "}
          </p>
          <p className="ml-3">
            Watch our{" "}
            <a
              href="https://www.youtube.com/watch?v=eSKuWg03rN0"
              target="_blank"
              rel="noopener noreferrer"
            >
              video tutorial
            </a>{" "}
            for more information.{" "}
          </p>
          <h4 className="mt-4 p-2">
            What are the requirements for an educational resource?
          </h4>
          <p className="ml-3">
            Educational resources must:
            <ul>
              <li>
                Associate with at least one of the nine <a href="/competencies">categories</a> and state which learning objectives are met {" "}
              </li>
              <li>
                Provide information relating to logistics of educational resource 
              </li>
              <li>
                Share specific learning objectives with links to programs in profile (syllabi is strongly suggested) {" "}
              </li>
            </ul>
          </p>
          <h4 className="mt-4 p-2">
            Can I share additional resources that don’t qualify as an educational resource?{" "}
          </h4>
          <p className="ml-3">
            Yes! Additional resources can be shared on your practitioner profile. To add, select “your practitioner profile” in the dropdown menu under your profile name in the top right of the menu bar. Scroll to the “Additional Resources” section on your profile page to add listings.{" "}
          </p>
          {/* <p className="ml-3">
            While your profile will go live immediately after creation, ISU FFED
            reserves the right to review practitioner profiles and educational
            resources and freezing the account or removing if information is
            incorrect. Upon this action, you will receive an email to notify you
            of the concern and how to amend information.{" "}
          </p> */}

          <h4 className="mt-4 p-2">How do I use the search feature/function? </h4>
          <p className="ml-3">
           The “Search” button is located at the top left of the screen. There you will see tabs for general search, practitioner profile search, and educational resource search. For a general search of the database, you can find practitioner profiles and educational resources by typing keywords, locations, names or affiliations. To specifically search for practitioners, click the “Practitioner Search” tab and use the dropdowns to select specific organizations, competencies, and sub-competencies. To specifically search for educational resources, click the “Educational Resource Search” tab and use the dropdowns to select specific competencies, levels of learning and organizations. {" "}
          </p>

          <h4 className="mt-4 p-2">
            How is this different from other directories?{" "}
          </h4>
          <p className="ml-3">
            This is a specific searchable database for educational resources,
            including food systems practitioners, based on the core competency
            that is either taught or known by an individual. The overall goal is
            to heighten awareness of existing resources and promote connection
            between individuals that are seeking specific learning opportunities
            related to food systems competencies.{" "}
          </p>

          <p className="ml-3">
           <b>Additional directories that may be helpful:</b>
            <ul>
              <li>
                <p>
                  <a
                    href="https://foodsystemsleadershipnetwork.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Food Systems Leadership Network:
                  </a>{" "}
                  The FSLN is a national peer learning community that connects
                  current and emerging leaders, strengthens individual and
                  collective leadership capacity, and fosters collaboration
                  across communities. The goal of the network is to accelerate
                  the realization of a just, equitable, and sustainable food
                  system that generates good food, health, and opportunity for
                  all.
                </p>
                <p>
                  The FSLN is facilitated by the{" "}
                  <a
                    href="https://www.wallacecenter.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Wallace Center
                  </a>{" "}
                  at{" "}
                  <a
                    href="https://winrock.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Winrock International
                  </a>{" "}
                  to serve and support the growing community of organizations,
                  businesses, and public agencies involved in building a good
                  farming and food system in the United States.{" "}
                </p>
              </li>
              <li>
                <a
                  href="http://www.fssourcebook.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sustainable Food Systems Sourcebook:
                </a>{" "}
                A searchable database of degree programs, consultants,
                scholarships, funding, conferences, and other education and
                training resources for professionals and students focused on
                food systems work.
              </li>
              <li>
                <p>
                  <a
                    href="https://foodsafetyclearinghouse.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Food Safety Clearinghouse:
                  </a>{" "}
                  The Clearinghouse is a curated and crowd-sourced collection of
                  Produce Safety Rule (PSR) and Preventive Controls for Human
                  Food Rule (PCHFR) resources related to the Food Safety
                  Modernization Act (FSMA)
                </p>
              </li>
              <li>
                <p>
                  <a
                    href="http://www.foodpolicynetworks.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Food Policy Networks:
                  </a>{" "}
                  The Johns Hopkins Center for a Livable Future’s Food Policy
                  Networks (FPN) project supports the development of effective
                  state and local food policy through networking, capacity
                  building, research, and technical assistance. We work directly
                  with food policy councils, national organizations, and other
                  groups seeking to improve the food system through public
                  policy.
                </p>
              </li>
            </ul>
          </p>
          <p>
            <b>Existing resources:</b>
            <ul>
              <li>
                <p>
                  <a
                    href="https://attra.ncat.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ATTRA:
                  </a>{" "}
                  Contains existing databases that include technical assistance,
                  specialists in all facets of sustainable agriculture, a
                  massive resource library, and one of the largest farm
                  internship databases in the nation.
                </p>
              </li>
              <li>
                <p>
                  <a
                    href="http://www.ncat.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NCAT:
                  </a>{" "}
                  Helping people build resilient communities through local and
                  sustainable solutions that reduce poverty, strengthen
                  self-reliance, and protect natural resources. The website
                  offers various resources related to production, agriculture,
                  energy and more.
                </p>
              </li>
              {/* <li>
                <p>
                  <a
                    href="https://lfscovid.localfoodeconomics.com/resource-hub/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    COVID-19 Resource Hub:
                  </a>{" "}
                  The COVID-19 pandemic and associated public health and social
                  distancing mandates caused unprecedented shifts and
                  disruptions for Local and Regional Food Systems (LRFS).
                  Impacts on farm enterprises, value chain stakeholders, market
                  channels, and food system infrastructure are both vast and
                  varied, and require rapid adaptation by all involved. The
                  pandemic has also brought new and heightened attention to our
                  food system, and LRFS may be positioned to significantly
                  increase the scope and scale of their market reach as a
                  result. During this moment of simultaneous disruption and
                  rapid innovation, our project supports LRFS communities of
                  practice by documenting and disseminating innovations and best
                  practices developed on the ground and drawing on LRFS thought
                  leaders to frame research on COVID-related shifts in LRFS
                  markets with the aim of supporting long term resilience.
                </p>
              </li> */}
              <li>
                <p>
                  <a
                    href="https://www.ams.usda.gov/services/local-regional/food-directories"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    USDA’s Local Food Directories:
                  </a>{" "}
                  USDA&apos;s Local Food Directories help you locate{" "}
                  <a
                    href="https://www.ams.usda.gov/local-food-directories/farmersmarkets"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    farmers markets
                  </a>
                  ,{" "}
                  <a
                    href="https://www.ams.usda.gov/local-food-directories/onfarm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    on-farm markets
                  </a>
                  ,{" "}
                  <a
                    href="https://www.ams.usda.gov/local-food-directories/csas"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CSAs
                  </a>
                  , and{" "}
                  <a
                    href="https://www.ams.usda.gov/local-food-directories/foodhubs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    food hubs
                  </a>
                  . The directories are managed and operated by the Agricultural
                  Marketing Service (AMS), a USDA agency with the core mission
                  of facilitating the fair and efficient marketing of U.S.
                  agricultural products. From customers looking to buy fresh
                  local foods for their families to wholesale food buyers, you
                  can quickly identify nearby suppliers of local food. By
                  listing their business information in the directories,
                  farmers, producers and distributors of local foods are able to
                  take advantage of opportunities in both direct-to-consumer and
                  wholesale markets.
                </p>
              </li>
            </ul>
          </p>

          <h4 className="mt-4 p-2">Contact: </h4>
          <p className="ml-3">For other questions or assistance, please email Kaley Hohenshell at <a href="mailto:foodsystemsdb@iastate.edu">foodsystemsdb@iastate.edu</a>
          </p>
        </div>
      </div>
    );
  }
}

export default FAQ;
