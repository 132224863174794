import React, { Component } from "react";
import { Link } from "@reach/router";

class FormSuccess extends Component {
  render() {
    const theMessage = this.props.theMessage;
    const loginDisplay = this.props.loginDisplay;

    return (
      <div className="col-12 alert alert-success px-3 text-center">
        {theMessage}
        {loginDisplay && (
          <Link to="/login" className="btn btn-outline-primary mr-2">
            Login
          </Link>
        )}
      </div>
    );
  }
}

export default FormSuccess;
