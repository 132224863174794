import React, { Component } from "react";
import { navigate, Link } from "@reach/router";
import Axios from "axios";
import { confirmAlert } from "react-confirm-alert"; 
import "react-confirm-alert/src/react-confirm-alert.css"; 
import FormError from "./FormError";
import FormSuccess from "./FormSuccess";

class MyEducationalResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curriculaList: [],
      errorMessage: null,
      successMessage: null,
      loginDisplay: false,
    };
    this.deleteCurricula = this.deleteCurricula.bind(this);
  }
  componentDidMount() {
    this.updateMyEducationalResource();
  }
  updateMyEducationalResource() {
    Axios.post(
      "https://foodsystemsdb.extension.iastate.edu/api/getCurriculaByProfile",
      {
        profileId: this.props.profileId,
      }
    ).then(
      (response) => {
        if (response.data.result.length > 0) {
          this.setState({ curriculaList: response.data.result });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }
  newEducationalResource() {
    navigate("/newEducationalResource");
  }

  handleDelete(e, curricula_id) {
    e.preventDefault();
    Axios.post(
      "https://foodsystemsdb.extension.iastate.edu/api/deleteCurricula",
      {
        curricula_id: curricula_id,
      }
    ).then(
      (response) => {
        if (response.data.message === "Deleted Successfully!") {
          this.setState({
            successMessage: response.data.message,
            curriculaList: [],
          });
          this.updateMyEducationalResource();
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }

  deleteCurricula(e, curricula_id) {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>You want to delete this Curricula?</p>
            <button className="btn btn-primary mr-2" onClick={onClose}>
              No
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                this.handleDelete(e, curricula_id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }
  editCurricula(e, item) {
    e.preventDefault();
    navigate("/newEducationalResource?ID=" + item.curricula_id);
  }

  render() {
    return (
      <div className="container mb-5 pb-5 mnHeight">
        {this.props.userName && (
          <div className="mt-5 text-center">
            <button
              className="btn btn-sm btn-primary text-center"
              onClick={this.newEducationalResource}
            >
              Add New Educational Resource
            </button>
            <div>
              <div className="container w-50 my-3">
                {this.state.errorMessage !== null ? (
                  <FormError theMessage={this.state.errorMessage} />
                ) : null}
                {this.state.successMessage !== null ? (
                  <FormSuccess
                    theMessage={this.state.successMessage}
                    loginDisplay={this.state.loginDisplay}
                  />
                ) : null}
              </div>
              {this.state.curriculaList.map((item) => (
                <section className="card mt-3 mb-3" key={item.curricula_id}>
                  <div className="text-right">
                    <button
                      className="btn btn-sm btn-primary mr-2"
                      onClick={(e) => this.editCurricula(e, item)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={(e) => {
                        this.deleteCurricula(e, item.curricula_id);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                  <div className="card-body text-center">
                    <Link to={"/eduResourceSearch?Id=" + item.curricula_id}>
                      <h2 className="card-title">{item.curricula_title}</h2>
                    </Link>
                    <h5 className="card-subtitle mb-2">{item.org_name}</h5>
                    <p className="card-text">{item.description}</p>
                    <ul className="list-group list-group-horizontal-md row">
                      <li className="list-group-item col-sm-4">
                        <span className="font-weight-bold">Cost:</span>{" "}
                        <span>{item.full_fee}</span>
                      </li>
                      <li className="list-group-item col-sm-4">
                        <span className="font-weight-bold">
                          Delivery Method:
                        </span>{" "}
                        <span>{item.method_of_delivery}</span>
                      </li>
                      <li className="list-group-item col-sm-4">
                        <span className="font-weight-bold">Hours:</span>{" "}
                        <span>{item.hours}</span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-horizontal-md row">
                      <li className="list-group-item col-sm-6">
                        <span className="font-weight-bold">Certification:</span>{" "}
                        <span>{item.completion}</span>
                      </li>
                      <li className="list-group-item col-sm-6">
                        <span className="font-weight-bold">Location:</span>{" "}
                        <span>{item.location_desc}</span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-horizontal-md row">
                      <li className="list-group-item col-sm-6">
                        <span className="font-weight-bold">Website:</span>{" "}
                        <span>{item.website}</span>
                      </li>
                      <li className="list-group-item col-sm-6">
                        <span className="font-weight-bold">Contact:</span>{" "}
                        <span>
                          <Link to={"/profileSearch?Id=" + item.profileId}>
                            {item.name};
                          </Link>{" "}
                          <a href={"mailto:" + item.email}>{item.email}</a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </section>
              ))}
            </div>
          </div>
        )}
        {!this.props.userName && this.props.navigateToLogin()}
      </div>
    );
  }
}

export default MyEducationalResource;
