import React, { Component } from "react";
import { FaHome } from "react-icons/fa";
import { Link } from "@reach/router";
class Navigation extends Component {
  render() {
    const { user, email, logOutUser } = this.props;
    const NavLink = (props) => (
      <Link
        {...props}
        getProps={({ isCurrent }) => {
          // the object returned here is passed to the
          // anchor element's props
          return {
            style: {
              color: isCurrent ? "black" : "grey",
            },
          };
        }}
      />
    );

    return (
      <nav className="site-nav navbar navbar-expand-sm bg-light navbar-light higher font-weight-normal">
        <div className="container-fluid mxWidth">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#togglerNav"
            aria-controls="togglerNav"
            aria-expanded="false"
            aria-label="Toggle Navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="togglerNav">
            <div className="navbar-nav nl-sm-auto">
              <NavLink to="/" className="navbar-brand nav-link">
                <h4>
                  <FaHome className="mr-1" />
                </h4>
              </NavLink>
              <NavLink to="/search" className="navbar-brand nav-link">
                Search
              </NavLink>
              {/* <NavLink to="/about" className="navbar-brand nav-link"> 
                About
              </NavLink>*/}
              <NavLink to="/competencies" className="navbar-brand nav-link">
                Competencies
              </NavLink>
              <NavLink to="/FAQ" className="navbar-brand nav-link">
                FAQ
              </NavLink>
            </div>
            <div className="navbar-nav ml-auto fs12">
              {email && (
                <div className="dropdown">
                  <button
                    className="btn btn-white dropdown-toggle fs12"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {user}
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <NavLink
                      to="/profile"
                      className="navbar-item nav-link dropdown-item"
                    >
                      Your practitioner profile
                    </NavLink>
                    <NavLink
                      to="/myEducationalResource"
                      className="navbar-item nav-link dropdown-item"
                    >
                      Your educational resources
                    </NavLink>
                    <NavLink
                      to="/login"
                      className="navbar-item nav-link dropdown-item"
                      onClick={(e) => logOutUser(e)}
                    >
                      Log out
                    </NavLink>
                  </div>
                </div>
              )}
              {!email && (
                <NavLink className="nav-item nav-link" to="/login">
                  Login
                </NavLink>
              )}
              {!email && (
                <NavLink className="nav-item nav-link" to="/register">
                  Register
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
