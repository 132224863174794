import React, { Component } from "react";

import { Link } from "@reach/router";
import FormError from "./FormError";
import FormSuccess from "./FormSuccess";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: "",
      email: "",
      passOne: "",
      passTwo: "",
      orgOne: "",
      orgTwo: "",
      work_location: "",
      errorMessage: null,
      successMessage: null,
      loginDisplay: true,
      pronouns: "",
      enableOtherOrg: false,
      enableOtherSubOrg: false,
      orgOneOther: "",
      orgTwoOther: "",
      categories: [
        {
          id: 1,
          value: "Food Systems",
          dbValue: "Food_Systems",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 2,
          value: "Equity",
          dbValue: "Equity",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 3,
          value: "Community Capacity",
          dbValue: "Community_Capacity",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 4,
          value: "Economy and Business Analysis",
          dbValue: "Economy_and_Business_Analysis",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 5,
          value: "Governance and Policy",
          dbValue: "Governance_and_Policy",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 6,
          value: "Health and Wellness",
          dbValue: "Health_and_Wellness",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 7,
          value: "Environment",
          dbValue: "Environment",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 8,
          value: "Leadership",
          dbValue: "Leadership",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 9,
          value: "Evaluation",
          dbValue: "Evaluation",
          isChecked: false,
          sub_categories: [],
        },
      ],
      c_ids: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.enableOrgOtherText = this.enableOrgOtherText.bind(this);
    this.handleSecondaryAffChange = this.handleSecondaryAffChange.bind(this);
  }

  handleChange(e) {
    e.preventDefault();
    const itemName = e.target.name;
    const itemValue = e.target.value;
    this.setState({ [itemName]: itemValue }, () => {
      if (itemName === "passTwo") {
        if (this.state.passOne !== this.state.passTwo) {
          this.setState({ errorMessage: "Passwords no not match" });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    });
  }

  handleSecondaryAffChange = (e) => {
    var str = "";
    for(var i = 0; i < e.length;i++){
      if(e[i].value != "other"){
        str += e[i].value + ";";
      }
    }
    str = str.slice(0, str.length -1);
    this.setState({ orgTwo: str });

    if (e.length>0 && e.some(function(o){return o["value"] === "other";})) {
      this.setState({ enableOtherSubOrg: true});
    } else {
      this.setState({ enableOtherSubOrg: false});
    }
    console.log(this.state.orgTwo);
  };
  

  enableOrgOtherText(e) {
    e.preventDefault();
    if (e.target.value === "Other") {
      this.setState({ enableOtherOrg: true });
    } else {
      this.setState({ enableOtherOrg: false });
    }
  }

  //

  populateSubCategory(category) {
    var sub_categories = [];
    this.props.categories
      .filter((item) => item.category === category)
      .map((filteredItem) => sub_categories.push(filteredItem.sub_category));
    sub_categories = Array.from(new Set(sub_categories));
    return sub_categories;
  }

  handleChecks(event, checkedCat) {
    var categories = this.state.categories;
    categories.forEach((item) => {
      if (item.id === checkedCat.id) {
        item.isChecked = event.target.checked;
        var sub_categories = this.populateSubCategory(item.value);
        if (item.isChecked) {
          item.sub_categories = sub_categories;
          //this.setState({ check_limit: this.state.check_limit + 1 });
        } //else {
        //this.setState({ check_limit: this.state.check_limit - 1 });
        // var sub_categories_dict = this.props.sub_categories;
        //var ss_categories_dict = this.props.ss_categories;
        // var ss_categories = {};
        //var selected_cat = item.value;
        // var selected_sub_cat, selected_ss_cat = "";
        // sub_categories.forEach(sub_key => {
        //     if (sub_categories_dict[sub_key].isChecked) {
        //         selected_sub_cat = sub_key;
        //         sub_categories_dict[sub_key].isChecked = false;

        //         ss_categories = this.populateSSubCategory(item.value, selected_sub_cat);
        //         ss_categories.forEach(ss_key => {
        //             if (ss_categories_dict[ss_key].isChecked) {
        //                 selected_ss_cat = ss_key;
        //                 ss_categories_dict[ss_key].isChecked = false;

        //                 Object.keys(ss_categories_dict[ss_key].level).forEach(key => {
        //                     if (ss_categories_dict[ss_key].level[key].isChecked) {
        //                         ss_categories_dict[ss_key].level[key].isChecked = false;
        //                         this.getCatDetails(event, selected_cat, selected_sub_cat, selected_ss_cat, key);
        //                     }
        //                 });
        //             }
        //         });
        //     }
        // });
        //this.setState({ sub_categories: sub_categories_dict, ss_categories: ss_categories_dict });
        //}
      }
    });
    // if (event.target.checked) {
    //     if (this.state.check_limit >= 2) {
    //         categories.forEach(item => {
    //             if (!item.isChecked) {
    //                 item.disabled = true;
    //             }
    //         })
    //     } else {
    //         this.setState({ check_limit: this.state.check_limit + 1 });
    //     }
    // } else {
    //     if (this.state.check_limit >= 2) {
    //         categories.forEach(item => {
    //             if (item.disabled) {
    //                 item.disabled = false;
    //             }
    //         })
    //     }
    //     this.setState({ check_limit: this.state.check_limit - 1 })
    // }
    this.setState({ categories: categories });
  }

  getCatDetails(e, category, sub_category) {
    var c_ids = this.state.c_ids;
    var checked_cat = this.props.categories.filter(
      (item) =>
        item.category === category.value && item.sub_category === sub_category
    );
    if (e.target.checked) {
      c_ids[checked_cat[0].category_id] = checked_cat[0].category;
    } else {
      delete c_ids[checked_cat[0].category_id];
    }
    this.setState({ c_ids: c_ids });
  }

  handleSubmit(e) {
    e.preventDefault();
    var c_ids = this.state.c_ids;
    if (!Object.keys(c_ids).length) {
      alert("Please Select atleast on Sub Competency in Comptencies");
      return;
    }
    var comp_ids = {};
    Object.keys(c_ids).forEach((key) => {
      if (c_ids[key] in comp_ids) {
        comp_ids[c_ids[key]].push(key);
      } else {
        comp_ids[c_ids[key]] = [key];
      }
    });
    console.log(this.state.orgTwo);
    Axios.post("https://foodsystemsdb.extension.iastate.edu/api/register", {
      name: this.state.displayName,
      pronouns: this.state.pronouns,
      email: this.state.email,
      password: this.state.passOne,
      aff1: this.state.orgOne,
      aff2: this.state.orgTwo,
      competencyList: comp_ids,
      work_location: this.state.work_location,
      otherOrg: this.state.enableOtherOrg,
      otherSubOrg: this.state.enableOtherSubOrg,
      aff1Other: this.state.orgOneOther,
      aff2Other: this.state.orgTwoOther,
    }).then(
      (response) => {
        if (response.data.message !== "RegisterSuccess") {
          this.setState({ errorMessage: response.data.message });
          e.target.reset();
        } else {
          e.target.reset();
          this.setState({
            successMessage: "Registration Successful!",
            errorMessage: null,
            displayName: "",
            email: "",
            work_location: "",
            passTwo: "",
          });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message, successMessage: null });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
    this.props.updateOrgList();
  }

  render() {
    var orgDropDown = this.props.orgList.map((v, i) => (
      <option key={i} value={v.org_name} label={v.org_name} >
        {v.org_name}
      </option>
    ));

    var multiOrgDropDown = this.props.orgList.map(data => ({ label: data.org_name, value: data.org_name }));
    multiOrgDropDown.push({ value: "other", label: "other" })

    return (
      <form className="my-5 pb-5 mnHeight" onSubmit={this.handleSubmit}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="card bg-light">
                <div className="card-body">
                  <h3 className="font-weight-light">Register</h3>
                  <span>
                      Already have an account?
                      <Link to="/login"> Login </Link>{" "}
                    </span>
                  <p className="mb-3">
                  Practitioner Profiles will be publicly searchable. Complete required(*) fields below. After, you will receive an email to verify your account, which will include further instructions for personalizing your profile and adding educational resources. 
                  </p>

                  <p>Check out our FAQ page or contact <a href="mailto:foodsystemsdb@iastate.edu">foodsystemsdb@iastate.edu</a> for support.</p>

                  <p className="small">Practitioner profiles will be live immediately after creation. Iowa State University Extension and Outreach reserves the right to review practitioner profiles and educational resources periodically. If there are discrepancies or inaccurate information, we will freeze the account and request edits or additional information. Upon this action, you will receive an email to notify you of the concern and how to amend information.  </p>
                  <div className="form-row">
                    {this.state.errorMessage !== null ? (
                      <FormError theMessage={this.state.errorMessage} />
                    ) : null}
                    <section className="col-sm-12 form-group">
                      {/* <label
                        className="form-control-label sr-only"
                        htmlFor="displayName"
                      >
                        Display Name
                      </label> */}
                      <input
                        className="form-control"
                        type="text"
                        id="displayName"
                        placeholder="Full Name*"
                        name="displayName"
                        required
                        value={this.state.displayName}
                        onChange={this.handleChange}
                      />
                    </section>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      id="pronouns"
                      placeholder="Pronouns (She/Her, He/His, They/Them)"
                      name="pronouns"
                      value={this.state.pronouns}
                      onChange={this.handleChange}
                    />
                  </div>
                  <section className="form-group">
                    {/* <label
                      className="form-control-label sr-only"
                      htmlFor="email"
                    >
                      Email
                    </label> */}
                    <input
                      className="form-control"
                      type="email"
                      id="email"
                      placeholder="Email Address*"
                      required
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </section>
                  <section className="form-group">
                    <label>Primary Organization* </label>{" "}
                    <FaInfoCircle
                      data-tip="Primary workplace, business, etc."
                      data-place="right"
                      color="blue"
                      data-background-color="#fff"
                      data-text-color="black "
                      data-arrow-color="#fff"
                    ></FaInfoCircle>
                    <ReactTooltip place="right" type="info" effect="solid" />
                    <select
                      id="orgOne"
                      className="form-control"
                      required
                      name="orgOne"
                      value={this.state.orgOne}
                      onChange={(e) => {
                        this.handleChange(e);
                        this.enableOrgOtherText(e);
                      }}
                    >
                      <option>Select</option>
                      {orgDropDown}
                      <option key="other" value="Other">
                        Other
                      </option>
                    </select>
                    {this.state.enableOtherOrg && (
                      <div className="form-group mt-3">
                        <input
                          name="orgOneOther"
                          className="form-control form-control-alternative"
                          placeholder="Type your Organization name"
                          onChange={this.handleChange}
                        />
                      </div>
                    )}
                  </section>
                  <section className="form-group">
                    <label>Secondary Affliations </label>{" "}
                    <FaInfoCircle
                      data-tip="List partner organizations, networks, etc."
                      data-place="right"
                      color="blue"
                      data-background-color="#fff"
                      data-text-color="black "
                      data-arrow-color="#fff"
                    ></FaInfoCircle>
                    <ReactTooltip place="right" type="info" effect="solid" />
                    {/* <select
                      id="orgTwo"
                      className="form-control"
                      name="orgTwo"
                      isMulti="true"
                      isSearchable="true"
                      value={this.state.orgTwo}
                      onChange={(e) => {
                        this.handleChange(e);
                        this.enableOrgOtherSubText(e);
                      }}
                    >
                      <option>Select</option>
                      {orgDropDown}
                      <option key="other" value="OtherSub">
                        Other
                      </option>
                    </select>
                    {this.state.enableOtherSubOrg && (
                      <div className="form-group mt-3">
                        <input
                          name="orgTwoOther"
                          className="form-control form-control-alternative"
                          placeholder="Type your Affliation name"
                          onChange={this.handleChange}
                        />
                      </div>
                    )} */}
                    <Select 
                      components={animatedComponents}
                      isMulti
                      options={multiOrgDropDown} 
                      id="orgTwo"
                      name="orgTwo"
                      onChange={this.handleSecondaryAffChange}
                      />
                    {this.state.enableOtherSubOrg && (
                      <div className="form-group mt-3">
                        <input
                          name="orgTwoOther"
                          className="form-control form-control-alternative"
                          placeholder="Type your Affliation name"
                          onChange={this.handleChange}
                        />
                      </div>
                    )} 
                  </section>
                  <section className="form-group">
                    <label className="mr-2">
                      Select{" "}
                      <a href="/competencies" target="_blank">
                        Categories*
                      </a>{" "}
                    </label>
                    <FaInfoCircle
                      data-tip="Identify up to three categories, and associated themes as appropriate."
                      data-place="right"
                      color="blue"
                      data-background-color="#fff"
                      data-text-color="black "
                      data-arrow-color="#fff"
                    ></FaInfoCircle>
                    <ReactTooltip place="right" type="info" effect="solid" />
                    <br />
                    <div className="form-group mx-4">
                      {this.state.categories.map((category, index) => {
                        return (
                          <div key={index}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={index}
                                value={category.value}
                                onChange={(e) => this.handleChecks(e, category)}
                                disabled={category.disabled}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                {category.value}
                              </label>
                            </div>
                            {category.isChecked && (
                              <div className="mx-5">
                                {category.sub_categories.map(
                                  (sub_category, index) => {
                                    return (
                                      <div key={index}>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={index}
                                            value={sub_category}
                                            disabled={category.disabled}
                                            onChange={(e) =>
                                              this.getCatDetails(
                                                e,
                                                category,
                                                sub_category
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="inlineCheckbox2"
                                          >
                                            {sub_category}
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </section>
                  <section className="form-group">
                    <label>
                      Work  Location/Address*
                    </label>
                    <FaInfoCircle
                      data-tip="MUST state at least city, state for location identification"
                      data-place="right"
                      color="blue"
                      data-background-color="#fff"
                      data-text-color="black "
                      data-arrow-color="#fff"
                    ></FaInfoCircle>
                    <ReactTooltip place="right" type="info" effect="solid" />
                    <input
                      className="form-control"
                      id="location"
                      placeholder="1234 Street Name, City, State, Zipcode"
                      required
                      name="work_location"
                      value={this.state.work_location}
                      onChange={this.handleChange}
                    />
                  </section>
                  <div className="form-row">
                    <section className="col-sm-6 form-group">
                      <input
                        className="form-control"
                        type="password"
                        name="passOne"
                        required
                        placeholder="Password"
                        onChange={this.handleChange}
                      />
                    </section>
                    <section className="col-sm-6 form-group">
                      <input
                        className="form-control"
                        type="password"
                        required
                        name="passTwo"
                        placeholder="Confirm Password"
                        value={this.state.passTwo}
                        onChange={this.handleChange}
                      />
                    </section>
                  </div>
                  <div className="form-group text-center">
                    <button className="col-4 btn btn-primary" type="submit">
                      Register
                    </button>{" "}
                    {this.state.successMessage !== null ? (
                      <FormSuccess
                        theMessage={this.state.successMessage}
                        loginDisplay={this.state.loginDisplay}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Register;
