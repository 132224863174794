import React, { Component } from "react";
import FormError from "./FormError";
import Axios from "axios";
import FormSuccess from "./FormSuccess";

class ForgotPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorMessage: null,
      successMessage: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {}

  handleChange(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;
    this.setState({ [itemName]: itemValue });
  }

  handleSubmit(e) {
    e.preventDefault();
    Axios.post("https://foodsystemsdb.extension.iastate.edu/api/forgotPass", {
      email: this.state.email,
    }).then((response) => {
      e.target.reset();
      console.log(response);
      if (response.data.error) {
        this.setState({ errorMessage: response.data.error });
      } else {
        this.setState({ successMessage: response.data.message });
      }
    });
  }

  render() {
    return (
      <form className="my-5 pb-5 mnHeight" onSubmit={this.handleSubmit}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="card bg-light">
                <div className="card-body">
                  <h3 className="font-weight-light mb-3">Forgot Password</h3>
                  <section className="form-group">
                    {this.state.errorMessage !== null ? (
                      <FormError theMessage={this.state.errorMessage} />
                    ) : null}
                    {this.state.successMessage !== null ? (
                      <FormSuccess theMessage={this.state.successMessage} />
                    ) : (
                      ""
                    )}
                    <label className="form-control-label sr-only">Email</label>
                    <input
                      required
                      className="form-control"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </section>
                  <div className="form-group text-center mb-0">
                    <button className="btn col-6 btn-primary" type="submit">
                      Sumbit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default ForgotPass;
