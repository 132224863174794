import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer container-fluid py-4 bg-light">
        <div className="row mxWidth">
          <div className="text-muted col-sm-6">
            <p>Funding provided by:</p>
            <div className="row">
              <img
                src="ams-color-lockup.svg"
                alt="USDA AMS logo"
                className="col-sm-7 footerLogoWidth"
              />
              <span> </span>
              <img
                src="agmrclogodisplay-2-New-Blue.png"
                alt="AgMRC"
                className="col-sm-5 footerLogoWidth"
              />
            </div>
          </div>
          <div className="text-muted col-sm-6 pt-1">
            <h4>Contact</h4>
            <p>
              Kaley Hohenshell at <a href="mailto:foodsystemsdb@iastate.edu">foodsystemsdb@iastate.edu </a> 
              {/* For all inquiries, please contact <a href="mailto:foodsystemsdb@iastate.edu">Kaley Hohenshell</a> */}
            </p>
            <p>
              The names, words, symbols, and graphics representing Iowa State
              University are trademarks and copyrights of the university,
              protected by trademark and copyright laws of the U.S. and other
              countries.
            </p>
            <p>
              Copyright &copy; {new Date().getFullYear()} - Iowa State
              University. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
