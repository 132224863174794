import React, { Component } from "react";
import FormError from "./FormError";
import FormSuccess from "./FormSuccess";
import Axios from "axios";

class ChangePass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPass: "",
      passOne: "",
      passTwo: "",
      errorMessage: null,
      successMessage: null,
      loginDisplay: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    e.preventDefault();
    const itemName = e.target.name;
    const itemValue = e.target.value;

    this.setState({ [itemName]: itemValue }, () => {
      if (this.state.passOne !== this.state.passTwo) {
        this.setState({ errorMessage: "Passwords no not match" });
      } else {
        this.setState({ errorMessage: null });
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    Axios.post("https://foodsystemsdb.extension.iastate.edu/api/changePass", {
      email: this.props.email,
      oldPass: this.state.oldPass,
      newPass: this.state.passOne,
    }).then(
      (response) => {
        if (response.data.message !== "Success") {
          this.setState({ errorMessage: response.data.message });
        } else {
          this.setState({
            successMessage: "Password Changed!",
            errorMessage: null,
            oldPass: "",
            passOne: "",
            passTwo: "",
          });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message, successMessage: null });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }

  render() {
    return (
      <div className="mnHeight">
        {this.props.userName && (
          <form className="mt-3" onSubmit={this.handleSubmit}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="card bg-light">
                    <div className="card-body">
                      <h3 className="font-weight-light mb-3">
                        Change Password
                      </h3>
                      <div className="form-row">
                        {this.state.errorMessage !== null ? (
                          <FormError theMessage={this.state.errorMessage} />
                        ) : null}
                        <section className="col-sm-12 form-group">
                          <input
                            className="form-control"
                            type="password"
                            name="oldPass"
                            required
                            placeholder="Old Password"
                            onChange={this.handleChange}
                          />
                        </section>
                        <section className="col-sm-12 form-group">
                          <input
                            className="form-control"
                            type="password"
                            name="passOne"
                            required
                            placeholder="New Password"
                            onChange={this.handleChange}
                          />
                        </section>
                        <section className="col-sm-12 form-group">
                          <input
                            className="form-control"
                            type="password"
                            required
                            name="passTwo"
                            placeholder="Repeat Password"
                            onChange={this.handleChange}
                          />
                        </section>
                        <section className="col-sm-12 form-group text-right">
                          <button className="btn btn-primary" type="submit">
                            {" "}
                            Change Password
                          </button>
                          {this.state.successMessage !== null ? (
                            <FormSuccess
                              theMessage={this.state.successMessage}
                              loginDisplay={this.state.loginDisplay}
                            />
                          ) : null}
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
        {!this.props.userName && this.props.navigateToLogin()}
      </div>
    );
  }
}

export default ChangePass;
