import React, { useEffect, Component } from "react";
import { Link } from "@reach/router";
import FormError from "./FormError";
import Axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaPlus } from "react-icons/fa";
import "react-tabs/style/react-tabs.css";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  GeoJSON,
  LayersControl,
} from "react-leaflet";
import { divIcon } from "leaflet";
import L from "leaflet";
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, GoogleProvider } from "leaflet-geosearch";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { renderToStaticMarkup } from "react-dom/server";
// import ReactTooltip from "react-tooltip";
// import { FaInfoCircle } from "react-icons/fa";

function LeafletgeoSearch() {
  const map = useMap();
  useEffect(() => {
    const provider = new GoogleProvider({
      params: {
        key: "AIzaSyBILXbE28XInB6iIU-y3vzbIo1jvUr0Zo0",
      },
    });

    //new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider,
    });

    map.addControl(searchControl);

    return () => map.removeControl(searchControl);
  }, []);

  return null;
}

function Legend() {
  const map = useMap();
  useEffect(() => {
    const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      div.innerHTML =
        '<span><i class="far fa-user"></i><span class ="legend_color"> National Practitioner</span></br>' +
        '<i class="far fa-book-open"></i> <span class ="legend_color"> National Educational Resource</span></br>' +
        '<i class="fas fa-user-alt"></i> <span class ="legend_color">State/Local Practitioner</span></br>' +
        '<i class="fas fa-book-open"></i> <span class ="legend_color">State/Local Educational Resource</span></span>';
      return div;
    };

    legend.addTo(map);
  }, []);

  return null;
}

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      core_competency: "",
      completion_Ack: null,
      fee: null,
      completion_Hours: null,
      location: null,
      audience: null,
      delivery_Method: null,
      organization: null,
      learning_Level: null,
      second_SubCompetency: null,
      first_SubCompetency: null,
      errorMessage: null,
      searchText: null,
      curricula: [],
      // org: this.props.orgList,
      categories: [],
      sub_category: [],
      sub_sub_category: [],
      level: [],
      practitioner: [],
      // pCategories: [],
      pSub_category: [],
      pSub_sub_category: [],
      pOrganization: null,
      pCore_competency: null,
      // pSecond_SubCompetency: null,
      pFirst_SubCompetency: null,
      pLocation: null,
      // mapCore_competency: null,
      // mapLocation: null,
       genPractitioner: [],
       genCurricula: [],
      // EducationalCheckBox: true,
      // PractitionerCheckBox: true,
      us_counties: null,
      mapGeoJSON: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAllCategories = this.getAllCategories.bind(this);
    this.selectSubCategories = this.selectSubCategories.bind(this);
    this.selectSubSubCategories = this.selectSubSubCategories.bind(this);
    this.populateLevel = this.populateLevel.bind(this);
    this.handlePractitionerSubmit = this.handlePractitionerSubmit.bind(this);
    this.pSelectSubCategories = this.pSelectSubCategories.bind(this);
    this.pSelectSubSubCategories = this.pSelectSubSubCategories.bind(this);
    // this.handleMapSubmit = this.handleMapSubmit.bind(this);
    this.handleGeneralSubmit = this.handleGeneralSubmit.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  componentDidMount() {
    Axios.get("files/us-counties.json").then((response) => {
      this.setState({ us_counties: response.data });
    });
  }

  style() {
    return {
      weight: 0.5,
      opacity: 1,
      dashArray: "5",
      fillOpacity: 0.001,
    };
  }
  handleSubmit(e) {
    this.setState({ curricula: [] });
    e.preventDefault();
    Axios.post("https://foodsystemsdb.extension.iastate.edu/api/search", {
      core_competency: this.state.core_competency,
      sub_competency: this.state.first_SubCompetency,
      ss_competency: this.state.second_SubCompetency,
      learning_Level: this.state.learning_Level,
      organization: this.state.organization,
      advanced: this.props.formDisplay,
      delivery_Method: this.state.delivery_Method,
      audience: this.state.audience,
      location: this.state.location,
      completion_Hours: this.state.completion_Hours,
      fee: this.state.fee,
      completion_Ack: this.state.completion_Ack,
    }).then(
      (response) => {
        if (response.data.length > 0) {
          this.setState({ curricula: response.data, errorMessage: null });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }

  handlePractitionerSubmit(e) {
    this.setState({ practitioner: [] });
    e.preventDefault();
    Axios.post("https://foodsystemsdb.extension.iastate.edu/api/practitionerSearch", {
      organization: this.state.pOrganization,
      core_competency: this.state.pCore_competency,
      sub_competency: this.state.pFirst_SubCompetency,
      // ss_competency: this.state.pSecond_SubCompetency,
      location: this.state.pLocation,
    }).then(
      (response) => {
        if (response.data.length > 0) {
          this.setState({ practitioner: response.data , errorMessage: null });
          // this.setState({ errorMessage: null });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }

  handleGeneralSubmit(e){
    this.setState({ genCurricula: [], genPractitioner: []  });
    e.preventDefault();
    console.log("insidegeneral")
    Axios.post("https://foodsystemsdb.extension.iastate.edu/api/generalSearch", {
      searchText: this.state.searchText,
    }).then(
      (response) => {
        if (response.data) {
          this.setState({ genPractitioner: response.data.practitioner, genCurricula: response.data.curricula, errorMessage: null });
          // this.setState({ errorMessage: null });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }
  
  /*handleMapSubmit(e) {
    this.setState({ mapPractitioner: [], mapCurricula: [] });
    e.preventDefault();
    Axios.post("https://foodsystemsdb.extension.iastate.edu/api/search", {
      core_competency: this.state.mapCore_competency,
      location: this.state.mapLocation,
      sub_competency: null,
      ss_competency: null,
      learning_Level: null,
      organization: null,
      advanced: null,
      delivery_Method: null,
      audience: null,
      completion_Hours: null,
      fee: null,
      completion_Ack: null,
    }).then(
      (response) => {
        if (response.data.length > 0) {
          this.setState({ mapCurricula: response.data, errorMessage: null });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );

    Axios.post(
      "https://foodsystemsdb.extension.iastate.edu/api/practitionerSearch",
      {
        core_competency: this.state.mapCore_competency,
        location: this.state.mapLocation,
        sub_competency: null,
        organization: null,
      }
    ).then(
      (response) => {
        if (response.data.length > 0) {
          this.setState({ mapPractitioner: response.data });
          this.setState({ errorMessage: null });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }*/

  handleChange(e) {
    var itemName = e.target.name;
    var itemValue = e.target.value;
    if (itemValue === "") {
      itemValue = null;
    }
    this.setState({ [itemName]: itemValue });
  }

  handleCheckBox(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  // Get Categories from category_identification

  getAllCategories() {
    Axios.get("https://foodsystemsdb.extension.iastate.edu/api/getCategories").then((response) => {
      if (response) {
        if (response.data.result.length > 0) {
          this.setState({ categories: response.data.result });
        }
      }
    });
  }

  selectSubCategories(coreCatValue) {
    if (coreCatValue === "") {
      this.setState({
        sub_category: [],
        sub_sub_category: [],
        level: [],
      });
    }
    var sub_categories = [];
    this.props.categories
      .filter((item) => item.category === coreCatValue)
      .map((filteredItem) => sub_categories.push(filteredItem.sub_category));
    sub_categories = Array.from(new Set(sub_categories));
    this.setState({
      sub_category: sub_categories,
    });
  }

  selectSubSubCategories(subCatValue) {
    if (subCatValue === "") {
      this.setState({ level: [] });
    }
    var sub_sub_categories = [];
    this.props.categories
      .filter(
        (item) =>
          item.category === this.state.core_competency &&
          item.sub_category === subCatValue
      )
      .map((filteredItem) => sub_sub_categories.push(filteredItem.ss_category));
    sub_sub_categories = Array.from(new Set(sub_sub_categories));
    this.setState({
      sub_sub_category: sub_sub_categories,
    });
  }

  pSelectSubCategories(coreCatValue) {
    if (coreCatValue === "") {
      this.setState({
        pSub_category: [],
        pSub_sub_category: [],
      });
    }
    var sub_categories = [];
    this.props.categories
      .filter((item) => item.category === coreCatValue)
      .map((filteredItem) => sub_categories.push(filteredItem.sub_category));
    sub_categories = Array.from(new Set(sub_categories));
    this.setState({
      pSub_category: sub_categories,
    });
  }

  pSelectSubSubCategories(subCatValue) {
    var sub_sub_categories = [];
    this.props.categories
      .filter(
        (item) =>
          item.category === this.state.pCore_competency &&
          item.sub_category === subCatValue
      )
      .map((filteredItem) => sub_sub_categories.push(filteredItem.ss_category));
    sub_sub_categories = Array.from(new Set(sub_sub_categories));
    this.setState({
      pSub_sub_category: sub_sub_categories,
    });
  }

  populateLevel(item) {
    if (item === "") {
      this.setState({ level: [] });
    } else {
      this.setState({
        level: ["Level 1", "Level 2", "Level 3"],
      });
    }
  }
  getClickableLink = (link) => {
    if (link != null) {
      return link.startsWith("http://") || link.startsWith("https://")
        ? link
        : `http://${link}`;
    }
  };

  render() {
    // const { user } = this.props;
    var orgDropDown = this.props.orgList.map((v, index) => (
      <option key={index} value={v.org_id}>
        {v.org_name}
      </option>
    ));

    const practitionerIconMarkup = renderToStaticMarkup(
      <i className="fas fa-user-alt fa-2x" />
    );

    const practitionerMarkerIcon = divIcon({
      html: practitionerIconMarkup,
    });

    const eduIconMarkup = renderToStaticMarkup(
      <i className="fas fa-book-open fa-2x" />
    );
    const eduMarkerIcon = divIcon({
      html: eduIconMarkup,
    });
    const nationalPractitionerIconMarkup = renderToStaticMarkup(
      <i className="far fa-user fa-2x" />
    );
    const nationalPractitionerMarkerIcon = divIcon({
      html: nationalPractitionerIconMarkup,
    });

    const nationalEduIconMarkup = renderToStaticMarkup(
      <i className="far fa-book-open fa-2x" />
    );
    const nationalEduMarkerIcon = divIcon({
      html: nationalEduIconMarkup,
    });

    return (
      <div className="pb-5 mb-5 mnHeight">
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center bg-colorPantone p-4">
            <h1 className="text-dark">
              Food Systems Practitioner and Education Resource Database
            </h1>
          </div>
        </div>
        <div className="container">
          <Tabs className="mt-4">
            <TabList>
              <Tab>General Search</Tab>
              <Tab>Practitioner Search</Tab>
              <Tab>Educational Resource Search</Tab>
            </TabList>
            {/* Map Based Search */}
            {/* <TabPanel>
              <form onSubmit={this.handleMapSubmit}>
                <section className="form-group">
                  {this.state.errorMessage !== null ? (
                    <FormError theMessage={this.state.errorMessage} />
                  ) : null}
                </section>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="EducationalCheckBox"
                    checked={this.state.EducationalCheckBox}
                    onChange={this.handleCheckBox}
                  />
                  <i className="fas fa-book-open" />
                  &nbsp;
                  <label
                    className="form-check-label"
                    htmlFor="EducationalCheckBox"
                  >
                    Educational Resource
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="PractitionerCheckBox"
                    checked={this.state.PractitionerCheckBox}
                    onChange={this.handleCheckBox}
                  />
                  <i className="fas fa-user-alt" />
                  &nbsp;{" "}
                  <label
                    className="form-check-label"
                    htmlFor="PractitionerCheckBox"
                  >
                    Practitioner
                  </label>
                </div>

                <div className="form-row mt-3">
                  <div className="form-group col-md-6">
                    <label hmtlfor="mapCore_competency">Core Competency</label>
                    <select
                      id="core_competency"
                      name="mapCore_competency"
                      className="form-control"
                      onChange={(e) => {
                        this.handleChange(e);
                        this.selectSubCategories(e.target.value);
                      }}
                    >
                      <option key="0" value="">
                        Select
                      </option>
                      <option key="1" value="Food Systems">
                        Food Systems
                      </option>
                      <option key="2" value="Equity">
                        Equity
                      </option>
                      <option key="3" value="Community Capacity">
                        Community Capacity
                      </option>
                      <option key="4" value="Economy and Business Analysis">
                        Economy and Business Analysis
                      </option>
                      <option key="5" value="Governance and Policy">
                        Governance and Policy
                      </option>
                      <option key="6" value="Health and Wellness">
                        Health and Wellness
                      </option>
                      <option key="7" value="Environment">
                        Environment
                      </option>
                      <option key="8" value="Leadership">
                        Leadership
                      </option>
                      <option key="9" value="Evaluation">
                        Evaluation
                      </option>
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label hmtlfor="mapLocation">Area of Work </label> &nbsp;
                    <FaInfoCircle
                      data-tip="The location or region that the resource is offered"
                      data-place="right"
                      color="blue"
                      data-background-color="#fff"
                      data-text-color="black "
                      data-arrow-color="#fff"
                    ></FaInfoCircle>
                    <ReactTooltip place="right" type="info" effect="solid" />
                    <select
                      id="location"
                      name="mapLocation"
                      className="form-control"
                      onChange={this.handleChange}
                    >
                      <option key="0" value="">
                        Select
                      </option>
                      <option key="1" value="City">
                        City
                      </option>
                      <option key="2" value="County">
                        County
                      </option>
                      <option key="3" value="State">
                        State
                      </option>
                      <option key="4" value="Region">
                        Region
                      </option>
                      <option key="5" value="National">
                        National
                      </option>
                    </select>
                  </div>
                </div>
                <div className="form-row float-right">
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                </div>
              </form>
              <div className="mapPopulateText"><p>*Map will not populate until search button is clicked.</p></div>
              <div className="row bg-light justify-content-center align-items-center mt-5">
                <MapContainer
                  center={[37.09, -95.71]}
                  zoom={5}
                  scrollWheelZoom={false}
                >
                  <Legend />
                  <LayersControl position="topright">
                    <LayersControl.BaseLayer
                      checked
                      name="OpenStreetMap.Mapnik"
                    >
                      <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="USGS.USImagery">
                      <TileLayer
                        attribution='Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                        url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}"
                      />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                      <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                      />
                    </LayersControl.BaseLayer>
                    <LayersControl.Overlay checked name="Practitioner">
                      <MarkerClusterGroup>
                        {this.state.PractitionerCheckBox &&
                          this.state.mapPractitioner.map(
                            (item) =>
                              item.geojson != null && (
                                <Marker
                                  key={item.profileId}
                                  position={
                                    JSON.parse(item.geojson).coordinates
                                  }
                                  icon={item.area_of_work == "National"? nationalPractitionerMarkerIcon: practitionerMarkerIcon}
                                >
                                  <Popup>
                                    <div className="poup-text">
                                      <Link
                                        to={
                                          "/profileSearch?Id=" + item.profileId
                                        }
                                      >
                                        {item.name}
                                      </Link>
                                    </div>
                                  </Popup>
                                  );
                                </Marker>
                              )
                          )}
                      </MarkerClusterGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay checked name="Educational Resource">
                      <MarkerClusterGroup>
                        {this.state.EducationalCheckBox &&
                          this.state.mapCurricula.map(
                            (ven, index) =>
                              ven.geojson != null && (
                                <Marker
                                  key={index}
                                  position={JSON.parse(ven.geojson).coordinates}
                                  icon={ven.location == "National" ? nationalEduMarkerIcon:eduMarkerIcon}
                                >
                                  <Popup>
                                    <div className="poup-text">
                                      <Link
                                        to={
                                          "/eduResourceSearch?Id=" +
                                          ven.curricula_id
                                        }
                                      >
                                        {ven.curricula_title}
                                      </Link>
                                    </div>
                                  </Popup>
                                  );
                                </Marker>
                              )
                          )}
                      </MarkerClusterGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="US Counties" id="countyGrid">
                      {this.state.us_counties != null && (
                        <GeoJSON
                          data={this.state.us_counties}
                          style={this.style}
                        />
                      )}
                    </LayersControl.Overlay>
                  </LayersControl>
                  <LeafletgeoSearch />
                </MapContainer>
              </div>
              <div>
                {this.state.EducationalCheckBox &&
                  this.state.mapCurricula.map((item) => (
                    <section className="card mt-3 mb-3" key={item.curricula_id}>
                      <div className="card-body text-center">
                        <h1 className="card-title d-inline-block float-left">
                          <i className="fas fa-book-open " />
                        </h1>
                        <Link to={"/eduResourceSearch?Id=" + item.curricula_id}>
                          <h2 className="card-title d-inline-block">
                            {item.curricula_title}
                          </h2>
                        </Link>
                        <h5 className="card-subtitle">{item.org_name}</h5>
                        <p className="card-text">{item.description}</p>
                        <ul className="list-group list-group-horizontal-md row">
                          <li className="list-group-item col-sm-4">
                            <span className="font-weight-bold">Fee:</span>{" "}
                            <span>{item.full_fee}</span>
                          </li>
                          <li className="list-group-item col-sm-4">
                            <span className="font-weight-bold">
                              Delivery Method:
                            </span>{" "}
                            <span>{item.method_of_delivery}</span>
                          </li>
                          <li className="list-group-item col-sm-4">
                            <span className="font-weight-bold">Hours:</span>{" "}
                            <span>{item.hours}</span>
                          </li>
                        </ul>
                        <ul className="list-group list-group-horizontal-md row">
                          <li className="list-group-item col-sm-4">
                            <span className="font-weight-bold">
                              Certification:
                            </span>{" "}
                            <span>{item.completion}</span>
                          </li>
                          <li className="list-group-item col-sm-4">
                            <span className="font-weight-bold">Audience:</span>{" "}
                            <span>{item.audience}</span>
                          </li>
                          <li className="list-group-item col-sm-4">
                            <span className="font-weight-bold">Location:</span>{" "}
                            <span>{item.location_desc}</span>
                          </li>
                        </ul>
                        <ul className="list-group list-group-horizontal-md row">
                          <li className="list-group-item col-sm-6">
                            <span className="font-weight-bold">Website: </span>{" "}
                            <span>
                              <a
                                href={this.getClickableLink(item.website)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.website}
                              </a>
                            </span>
                          </li>
                          <li className="list-group-item col-sm-6">
                            <span className="font-weight-bold">Contact:</span>{" "}
                            <span>
                              <Link to={"/profileSearch?Id=" + item.profileId}>
                                {item.name};
                              </Link>{" "}
                              <a href={"mailto:" + item.email}>{item.email}</a>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </section>
                  ))}
              </div>
              <div>
                {this.state.PractitionerCheckBox &&
                  this.state.mapPractitioner.map((item) => (
                    <section className="card mt-3 mb-3" key={item.email}>
                      <div className="card-body text-center">
                        <h1 className="card-title d-inline-block float-left">
                          <i className="fas fa-user-alt " />
                        </h1>
                        <Link to={"/profileSearch?Id=" + item.profileId}>
                          <h2 className="card-title d-inline-block ">
                            {item.name}
                          </h2>
                        </Link>
                        <h5 className="card-subtitle mb-2">{item.aff1}</h5>
                        <p className="card-text">{item.bio} </p>
                        <ul className="list-group list-group-horizontal-md row">
                          <li className="list-group-item col-sm-6">
                            <span className="font-weight-bold">
                              Secondary Affliation:
                            </span>{" "}
                            <span>{item.aff2}</span>
                          </li>
                          <li className="list-group-item col-sm-6">
                            <span className="font-weight-bold">
                              Work Location:
                            </span>{" "}
                            <span>{item.work_location}</span>
                          </li>
                        </ul>
                        <ul className="list-group list-group-horizontal-md row">
                          <li className="list-group-item col-sm-6">
                            <span className="font-weight-bold">Contact:</span>{" "}
                            <span>
                              <Link to={"/profileSearch?Id=" + item.profileId}>
                                {item.name};
                              </Link>{" "}
                              <a href={"mailto:" + item.email}>{item.email}</a>
                            </span>
                          </li>
                          <li className="list-group-item col-sm-6">
                            <span className="font-weight-bold">Website:</span>{" "}
                            <span>
                              <a
                                href={this.getClickableLink(item.website)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.website}
                              </a>
                            </span>
                          </li>
                        </ul>
                        <ul className="list-group list-group-horizontal-md row">
                          <li className="list-group-item col-sm-4">
                            <span className="font-weight-bold">
                              Area Of Work:
                            </span>{" "}
                            <span>{item.area_of_work}</span>
                          </li>
                          <li className="list-group-item col-sm-8"></li>
                        </ul>
                      </div>
                    </section>
                  ))}
              </div>
            </TabPanel> */}
            <TabPanel>
              <form onSubmit={this.handleGeneralSubmit}>
                <section className="form-group">
                  {this.state.errorMessage !== null ? (
                    <FormError theMessage={this.state.errorMessage} />
                  ) : null}
                </section>
                <div className="form-row">
                  <div className="form-group mb-2"> <p>Search practitioner and educational resource profiles by keyword, location, name or affiliation. </p></div>
                  <div className="form-group col-md-6">
                    {/* <label hmtlfor="Search Box">General Search</label> */}
                    <input
                      required
                      className="form-control"
                      type="text"
                      id="searchText"
                      name="searchText"
                      placeholder="Enter query"
                      // value={this.state.search}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </div>
              </form>
              {/* {(this.state.genPractitioner.length > 0 ||
                this.state.genCurricula.length > 0) && ( */}
                <div className="row bg-light justify-content-center align-items-center mt-5">
                  <MapContainer
                    center={[37.09, -95.71]}
                    zoom={5}
                    scrollWheelZoom={false}
                  >
                    <Legend />
                    <LayersControl position="topright">
                      <LayersControl.BaseLayer
                        checked
                        name="OpenStreetMap"
                      >
                        <TileLayer
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                      </LayersControl.BaseLayer>
                      <LayersControl.BaseLayer name="Aerial Imagery">
                        <TileLayer
                          attribution='Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                          url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}"
                        />
                      </LayersControl.BaseLayer>
                      <LayersControl.Overlay
                        checked
                        name="Educational Resource"
                      >
                        <MarkerClusterGroup>
                          {this.state.genCurricula.map(
                            (ven, index) =>
                              ven.geojson != null && (
                                <Marker
                                  key={index}
                                  position={JSON.parse(ven.geojson).coordinates}
                                  icon={
                                    ven.location == "National"
                                      ? nationalEduMarkerIcon
                                      : eduMarkerIcon
                                  }
                                >
                                  <Popup>
                                    <div className="poup-text">
                                      <Link
                                        to={
                                          "/eduResourceSearch?Id=" +
                                          ven.curricula_id
                                        }
                                      >
                                        {ven.curricula_title}
                                      </Link>
                                    </div>
                                  </Popup>
                                  );
                                </Marker>
                              )
                          )}
                        </MarkerClusterGroup>
                      </LayersControl.Overlay>
                      <LayersControl.Overlay checked name="Practitioner">
                        <MarkerClusterGroup>
                          {this.state.genPractitioner.map(
                            (item) =>
                              item.geojson != null && (
                                <Marker
                                  key={item.profileId}
                                  position={
                                    JSON.parse(item.geojson).coordinates
                                  }
                                  icon={
                                    item.area_of_work == "National"
                                      ? nationalPractitionerMarkerIcon
                                      : practitionerMarkerIcon
                                  }
                                >
                                  <Popup>
                                    <div className="poup-text">
                                      <Link
                                        to={
                                          "/profileSearch?Id=" + item.profileId
                                        }
                                      >
                                        {item.name}
                                      </Link>
                                    </div>
                                  </Popup>
                                  );
                                </Marker>
                              )
                          )}
                        </MarkerClusterGroup>
                      </LayersControl.Overlay>
                      <LayersControl.Overlay name="US Counties" id="countyGrid">
                        {this.state.us_counties != null && (
                          <GeoJSON
                            data={this.state.us_counties}
                            style={this.style}
                          />
                        )}
                      </LayersControl.Overlay>
                    </LayersControl>
                    <LeafletgeoSearch />
                  </MapContainer>
                </div>
               {/* )} */}
              <div className="row">
              <div className="mt-5 col-sm">
              {(this.state.genCurricula.length > 0 && <h1>Educational Resources</h1>)}
                {this.state.genCurricula.map((item) => (
                  <section className="card mt-3 mb-3" key={item.curricula_id}>
                    <div className="card-body text-center">
                      <Link to={"/eduResourceSearch?Id=" + item.curricula_id}>
                        <h2 className="card-title">{item.curricula_title}</h2>
                      </Link>
                      <h5 className="card-subtitle mb-2">{item.org_name}</h5>
                      {/* <p className="card-text">{item.description}</p>
                      <ul className="list-group list-group-horizontal-md row">
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">Fee:</span>{" "}
                          <span>{item.full_fee}</span>
                        </li>
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">
                            Delivery Method:
                          </span>{" "}
                          <span>{item.method_of_delivery}</span>
                        </li>
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">Hours:</span>{" "}
                          <span>{item.hours}</span>
                        </li>
                      </ul>
                      <ul className="list-group list-group-horizontal-md row">
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">
                            Certification:
                          </span>{" "}
                          <span>{item.completion}</span>
                        </li>
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">Audience:</span>{" "}
                          <span>{item.audience}</span>
                        </li>
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">Location:</span>{" "}
                          <span>{item.location_desc}</span>
                        </li>
                      </ul> */}
                      <ul className="list-group list-group-horizontal-md row">
                        <li className="list-group-item col-sm-6">
                          <span className="font-weight-bold">Website: </span>{" "}
                          <span>
                            <a className="text-dark"
                              href={this.getClickableLink(item.website)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.website}
                            </a>
                          </span>
                        </li>
                        <li className="list-group-item col-sm-6">
                          <span className="font-weight-bold">Contact:</span>{" "}
                          <span>
                            <Link className="text-dark" to={"/profileSearch?Id=" + item.profileId}>
                              {item.name};
                            </Link>{" "}
                            <a className="text-dark" href={"mailto:" + item.email}>{item.email}</a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </section>
                ))}
              </div>
              <div className="mt-5 col-sm">
              {(this.state.genPractitioner.length > 0 && <h1>Practitioners</h1>)}
                {this.state.genPractitioner.map((item) => (
                  <section className="card mt-3 mb-3" key={item.email}>
                    <div className="card-body text-center">
                      <Link to={"/profileSearch?Id=" + item.profileId}>
                        <h2 className="card-title">{item.name}</h2>
                      </Link>
                      <h5 className="card-subtitle mb-2">{item.aff1}</h5>
                      {/* <p className="card-text">{item.bio} </p>
                      <ul className="list-group list-group-horizontal-md row">
                        <li className="list-group-item col-sm-6">
                          <span className="font-weight-bold">
                            Secondary Affliation:
                          </span>{" "}
                          <span>{item.aff2}</span>
                        </li>
                        <li className="list-group-item col-sm-6">
                          <span className="font-weight-bold">
                            Work Location:
                          </span>{" "}
                          <span>{item.work_location}</span>
                        </li>
                      </ul> */}
                      <ul className="list-group list-group-horizontal-md row">
                        <li className="list-group-item col-sm-6">
                          <span className="font-weight-bold">Contact:</span>{" "}
                          <span>
                            <Link to={"/profileSearch?Id=" + item.profileId}>
                              {item.name};
                            </Link>{" "}
                            <a className="text-dark" href={"mailto:" + item.email}>{item.email}</a>
                          </span>
                        </li>
                        <li className="list-group-item col-sm-6">
                          <span className="font-weight-bold">Website:</span>{" "}
                          <span>
                            <a className="text-dark"
                              href={this.getClickableLink(item.website)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.website}
                            </a>
                          </span>
                        </li>
                      </ul>
                      {/* <ul className="list-group list-group-horizontal-md row">
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">
                            Area Of Work:
                          </span>{" "}
                          <span>{item.area_of_work}</span>
                        </li>
                        <li className="list-group-item col-sm-8"></li>
                      </ul> */}
                    </div>
                  </section>
                ))}
              </div>
              </div>
            </TabPanel>
            {/* Practitioner Search */}
            <TabPanel>
              <form onSubmit={this.handlePractitionerSubmit}>
                <section className="form-group">
                  {this.state.errorMessage !== null ? (
                    <FormError theMessage={this.state.errorMessage} />
                  ) : null}
                </section>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label hmtlfor="pOrganization">Organization</label>
                    <select
                      id="pOrganization"
                      name="pOrganization"
                      className="form-control"
                      onChange={this.handleChange}
                    >
                      <option value="">Select</option>
                      {/* {orgDropDown} */}
                      {this.props.orgList.map((v, index) => (
                        <option key={index} value={v.org_name}>
                          {v.org_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group col-md-6">
                    <label hmtlfor="pCore_competency">Core Competency</label>
                    <select
                      id="pCore_competency"
                      name="pCore_competency"
                      className="form-control"
                      onChange={(e) => {
                        this.handleChange(e);
                        this.pSelectSubCategories(e.target.value);
                      }}
                    >
                      <option key="0" value="">
                        Select
                      </option>
                      <option key="1" value="Food Systems">
                        Food Systems
                      </option>
                      <option key="2" value="Equity">
                        Equity
                      </option>
                      <option key="3" value="Community Capacity">
                        Community Capacity
                      </option>
                      <option key="4" value="Economy and Business Analysis">
                        Economy and Business Analysis
                      </option>
                      <option key="5" value="Governance and Policy">
                        Governance and Policy
                      </option>
                      <option key="6" value="Health and Wellness">
                        Health and Wellness
                      </option>
                      <option key="7" value="Environment">
                        Environment
                      </option>
                      <option key="8" value="Leadership">
                        Leadership
                      </option>
                      <option key="9" value="Evaluation">
                        Evaluation
                      </option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label hmtlfor="pFirst_SubCompetency">Sub-Competency</label>
                    <select
                      id="pFirst_SubCompetency"
                      name="pFirst_SubCompetency"
                      className="form-control"
                      onChange={(e) => {
                        this.handleChange(e);
                        this.pSelectSubSubCategories(e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      {/* {first_SubCompetencyDropDown} */}
                      {this.state.pSub_category.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-row d-none ">
                  <div className="form-group col-md-6">
                    <label hmtlfor="State">State</label>
                    <select
                      id="state"
                      name="state"
                      className="form-control"
                      onChange={this.handleChange}
                    >
                      <option key="0" value="">
                        Select
                      </option>
                    </select>
                  </div>
                </div>
                <div className="form-row float-right">
                  <button type="submit" className="btn btn-primary">
                    Practitioner Search
                  </button>
                </div>
              </form>
              {/* {this.state.practitioner.length > 1 && ( */}
                <div className="row bg-light justify-content-center align-items-center mt-5">
                  <MapContainer
                    center={[37.09, -95.71]}
                    zoom={5}
                    scrollWheelZoom={false}
                  >
                    <Legend />
                    <LayersControl position="topright">
                      <LayersControl.BaseLayer
                        checked
                        name="OpenStreetMap"
                      >
                        <TileLayer
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                      </LayersControl.BaseLayer>
                      <LayersControl.BaseLayer name="OpenStreetMap">
                        <TileLayer
                          attribution='Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                          url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}"
                        />
                      </LayersControl.BaseLayer>
                      {/* <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                        <TileLayer
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                          url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                        /> */}
                      {/* </LayersControl.BaseLayer> */}
                      <LayersControl.Overlay checked name="Practitioner">
                        <MarkerClusterGroup>
                          {this.state.practitioner.map(
                            (item) =>
                              item.geojson != null && (
                                <Marker
                                  key={item.profileId}
                                  position={
                                    JSON.parse(item.geojson).coordinates
                                  }
                                  icon={
                                    item.area_of_work == "National"
                                      ? nationalPractitionerMarkerIcon
                                      : practitionerMarkerIcon
                                  }
                                >
                                  <Popup>
                                    <div className="poup-text">
                                      <Link
                                        to={
                                          "/profileSearch?Id=" + item.profileId
                                        }
                                      >
                                        {item.name}
                                      </Link>
                                    </div>
                                  </Popup>
                                  );
                                </Marker>
                              )
                          )}
                        </MarkerClusterGroup>
                      </LayersControl.Overlay>
                      <LayersControl.Overlay name="US Counties" id="countyGrid">
                        {this.state.us_counties != null && (
                          <GeoJSON
                            data={this.state.us_counties}
                            style={this.style}
                          />
                        )}
                      </LayersControl.Overlay>
                    </LayersControl>
                    <LeafletgeoSearch />
                  </MapContainer>
                </div>
               {/* )} */}
              <div className="mt-5">
                {this.state.practitioner.map((item) => (
                  <section className="card mt-3 mb-3" key={item.email}>
                    <div className="card-body text-center">
                      <Link to={"/profileSearch?Id=" + item.profileId}>
                        <h2 className="card-title">{item.name}</h2>
                      </Link>
                      <h5 className="card-subtitle mb-2">{item.aff1}</h5>
                      <p className="card-text">{item.bio} </p>
                      <ul className="list-group list-group-horizontal-md row">
                        <li className="list-group-item col-sm-6">
                          <span className="font-weight-bold">
                            Secondary Affliation:
                          </span>{" "}
                          <span>{item.aff2}</span>
                        </li>
                        <li className="list-group-item col-sm-6">
                          <span className="font-weight-bold">
                            Work Location:
                          </span>{" "}
                          <span>{item.work_location}</span>
                        </li>
                      </ul>
                      <ul className="list-group list-group-horizontal-md row">
                        <li className="list-group-item col-sm-6">
                          <span className="font-weight-bold">Contact:</span>{" "}
                          <span>
                            <Link to={"/profileSearch?Id=" + item.profileId}>
                              {item.name};
                            </Link>{" "}
                            <a href={"mailto:" + item.email}>{item.email}</a>
                          </span>
                        </li>
                        <li className="list-group-item col-sm-6">
                          <span className="font-weight-bold">Website:</span>{" "}
                          <span>
                            <a
                              href={this.getClickableLink(item.website)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.website}
                            </a>
                          </span>
                        </li>
                      </ul>
                      <ul className="list-group list-group-horizontal-md row">
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">
                            Area Of Work:
                          </span>{" "}
                          <span>{item.area_of_work}</span>
                        </li>
                        <li className="list-group-item col-sm-8"></li>
                      </ul>
                    </div>
                  </section>
                ))}
              </div>
            </TabPanel>
            {/*Educational Search*/}
            <TabPanel>
              <form onSubmit={this.handleSubmit}>
                <section className="form-group">
                  {this.state.errorMessage !== null ? (
                    <FormError theMessage={this.state.errorMessage} />
                  ) : null}
                </section>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label hmtlfor="core_competency">Core Competency</label>
                    <select
                      id="core_competency"
                      name="core_competency"
                      className="form-control"
                      onChange={(e) => {
                        this.handleChange(e);
                        this.selectSubCategories(e.target.value);
                      }}
                    >
                      <option key="0" value="">
                        Select
                      </option>
                      <option key="1" value="Food Systems">
                        Food Systems
                      </option>
                      <option key="2" value="Equity">
                        Equity
                      </option>
                      <option key="3" value="Community Capacity">
                        Community Capacity
                      </option>
                      <option key="4" value="Economy and Business Analysis">
                        Economy and Business Analysis
                      </option>
                      <option key="5" value="Governance and Policy">
                        Governance and Policy
                      </option>
                      <option key="6" value="Health and Wellness">
                        Health and Wellness
                      </option>
                      <option key="7" value="Environment">
                        Environment
                      </option>
                      <option key="8" value="Leadership">
                        Leadership
                      </option>
                      <option key="9" value="Evaluation">
                        Evaluation
                      </option>
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label hmtlfor="first_SubCompetency">
                      First Sub-Competency
                    </label>
                    <select
                      id="first_SubCompetency"
                      name="first_SubCompetency"
                      className="form-control"
                      onChange={(e) => {
                        this.handleChange(e);
                        this.selectSubSubCategories(e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      {/* {first_SubCompetencyDropDown} */}
                      {this.state.sub_category.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label hmtlfor="second_SubCompetency">
                      Second Sub-Competency
                    </label>
                    <select
                      id="second_SubCompetency"
                      name="second_SubCompetency"
                      className="form-control"
                      onChange={(e) => {
                        this.handleChange(e);
                        this.populateLevel(e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      {/* {second_SubCompetencyDropDown} */}
                      {this.state.sub_sub_category.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label hmtlfor="learning_Level">Level of Learning</label>
                    <select
                      id="learning_Level"
                      name="learning_Level"
                      className="form-control"
                      onChange={this.handleChange}
                    >
                      <option value="">Select</option>
                      {this.state.level.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label hmtlfor="organization">Organization</label>
                    <select
                      id="organization"
                      name="organization"
                      className="form-control"
                      onChange={this.handleChange}
                    >
                      <option value="">Select</option>
                      {orgDropDown}
                    </select>
                  </div>
                </div>
                <div className={this.props.formDisplay ? "" : "advance_search"}>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label hmtlfor="delivery_Method">
                        Method of Delivery
                      </label>
                      <select
                        id="delivery_Method"
                        name="delivery_Method"
                        className="form-control"
                        onChange={this.handleChange}
                      >
                        <option key="0" value="">
                          Select
                        </option>
                        <option key="1" value="in_person">
                          In-person
                        </option>
                        <option key="2" value="online">
                          Online
                        </option>
                        <option key="3" value="Self_paced">
                          Self-paced
                        </option>
                        <option key="4" value="Cohort_discussions">
                          cohort discussions
                        </option>
                        <option key="5" value="Site_visits">
                          Site Visits
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label hmtlfor="audience">Audience</label>
                      <select
                        id="audience"
                        name="audience"
                        className="form-control"
                        onChange={this.handleChange}
                      >
                        <option key="0" value="">
                          Select
                        </option>
                        <option key="1" value="Farmers">
                          Farmers
                        </option>
                        <option key="2" value="Food_Businesses">
                          Food Businesses
                        </option>
                        <option key="3" value="College_Students">
                          College Students
                        </option>
                        <option key="4" value="Extension_Staff">
                          Extension Staff
                        </option>
                        <option key="5" value="Agriculture_Professionals">
                          Agriculture Professionals
                        </option>
                        <option key="6" value=" Non_profit_Staff">
                          Non-profit Staff
                        </option>
                        <option key="7" value="Community_Advocates">
                          Community Advocates
                        </option>
                        <option
                          key="8"
                          value="Community_Planners_and_Developers"
                        >
                          Community Planners and Developers
                        </option>
                        <option key="9" value="Early_Childhood_Educators">
                          Early Childhood Educators
                        </option>
                        <option key="10" value="Policy_Makers">
                          Policy Makers
                        </option>
                        <option key="11" value="Food_Service_Directors">
                          Food Service Directors
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label hmtlfor="location">Area of Work</label>
                      <select
                        id="location"
                        name="location"
                        className="form-control"
                        onChange={this.handleChange}
                      >
                        <option key="0" value="">
                          Select
                        </option>
                        <option key="1" value="City">
                          City
                        </option>
                        <option key="2" value="County">
                          County
                        </option>
                        <option key="3" value="State">
                          State
                        </option>
                        <option key="4" value="Region">
                          Region
                        </option>
                        <option key="5" value="National">
                          National
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label hmtlfor="completion_Hours">
                        Hours to Complete
                      </label>
                      <select
                        id="completion_Hours"
                        name="completion_Hours"
                        className="form-control"
                        onChange={this.handleChange}
                      >
                        <option key="0" value="">
                          Select
                        </option>
                        <option key="1" value="BETWEEN 0 AND 10">
                          Less Than 10 hours
                        </option>
                        <option key="2" value="BETWEEN 10 AND 20">
                          Between 10 and 20 hours
                        </option>
                        <option key="3" value="BETWEEN 20 AND 30">
                          Between 20 and 30 hours
                        </option>
                        <option key="4" value="BETWEEN 30 AND 500">
                          Greater than 30 hours
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label hmtlfor="fee">Fee</label>
                      <select
                        id="fee"
                        name="fee"
                        className="form-control"
                        onChange={this.handleChange}
                      >
                        <option key="0" value="">
                          Select
                        </option>
                        <option key="1" value="Free">
                          Free
                        </option>
                        <option key="2" value="Tuition">
                          Tuition
                        </option>
                        <option key="3" value="Fee">
                          Fee
                        </option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label hmtlfor="completion_Ack">
                        Completion Acknowledgement
                      </label>
                      <select
                        id="completion_Ack"
                        name="completion_Ack"
                        className="form-control"
                        onChange={this.handleChange}
                      >
                        <option key="0" value="">
                          Select
                        </option>
                        <option key="1" value="Completion_Certificate">
                          Completion Certificate
                        </option>
                        <option key="2" value="Course_Credit">
                          Course Credit
                        </option>
                        <option key="3" value="Transferable_Materials">
                          Transferable Materials
                        </option>
                        <option key="4" value="Train_the_Trainer">
                          Train-the-Trainer
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-8 text-left text-wrap">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.props.toggleAdvForm}
                    >
                      Advanced Search <FaPlus className="mb-0.5 fa-xs" />
                    </button>
                  </div>
                  <div className="col-4 text-right">
                    <button type="submit" className="btn btn-primary">
                      Search
                    </button>
                  </div>
                </div>
              </form>
              {/* {this.state.curricula.length > 1 && ( */}
                <div className="row bg-light justify-content-center align-items-center mt-5">
                  <MapContainer
                    center={[37.09, -95.71]}
                    zoom={5}
                    scrollWheelZoom={false}
                  >
                    <Legend />
                    <LayersControl position="topright">
                      <LayersControl.BaseLayer
                        checked
                        name="OpenStreetMap"
                      >
                        <TileLayer
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                      </LayersControl.BaseLayer>
                      <LayersControl.BaseLayer name="Aerial Imagery">
                        <TileLayer
                          attribution='Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                          url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}"
                        />
                      </LayersControl.BaseLayer>
                      {/* <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                        <TileLayer
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                          url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                        />
                      </LayersControl.BaseLayer> */}
                      <LayersControl.Overlay
                        checked
                        name="Educational Resource"
                      >
                        <MarkerClusterGroup>
                          {this.state.curricula.map(
                            (ven, index) =>
                              ven.geojson != null && (
                                <Marker
                                  key={index}
                                  position={JSON.parse(ven.geojson).coordinates}
                                  icon={
                                    ven.location == "National"
                                      ? nationalEduMarkerIcon
                                      : eduMarkerIcon
                                  }
                                >
                                  <Popup>
                                    <div className="poup-text">
                                      <Link
                                        to={
                                          "/eduResourceSearch?Id=" +
                                          ven.curricula_id
                                        }
                                      >
                                        {ven.curricula_title}
                                      </Link>
                                    </div>
                                  </Popup>
                                  );
                                </Marker>
                              )
                          )}
                        </MarkerClusterGroup>
                      </LayersControl.Overlay>
                      <LayersControl.Overlay name="US Counties" id="countyGrid">
                        {this.state.us_counties != null && (
                          <GeoJSON
                            data={this.state.us_counties}
                            style={this.style}
                          />
                        )}
                      </LayersControl.Overlay>
                    </LayersControl>
                    <LeafletgeoSearch />
                  </MapContainer>
                </div>
              {/* )} */}
              <div>
                {this.state.curricula.map((item) => (
                  <section className="card mt-3 mb-3" key={item.curricula_id}>
                    <div className="card-body text-center">
                      <Link to={"/eduResourceSearch?Id=" + item.curricula_id}>
                        <h2 className="card-title">{item.curricula_title}</h2>
                      </Link>
                      <h5 className="card-subtitle">{item.org_name}</h5>
                      <p className="card-text">{item.description}</p>
                      <ul className="list-group list-group-horizontal-md row">
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">Fee:</span>{" "}
                          <span>{item.full_fee}</span>
                        </li>
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">
                            Delivery Method:
                          </span>{" "}
                          <span>{item.method_of_delivery}</span>
                        </li>
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">Hours:</span>{" "}
                          <span>{item.hours}</span>
                        </li>
                      </ul>
                      <ul className="list-group list-group-horizontal-md row">
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">
                            Certification:
                          </span>{" "}
                          <span>{item.completion}</span>
                        </li>
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">Audience:</span>{" "}
                          <span>{item.audience}</span>
                        </li>
                        <li className="list-group-item col-sm-4">
                          <span className="font-weight-bold">Location:</span>{" "}
                          <span>{item.location_desc}</span>
                        </li>
                      </ul>
                      <ul className="list-group list-group-horizontal-md row">
                        <li className="list-group-item col-sm-6">
                          <span className="font-weight-bold">Website: </span>{" "}
                          <span>
                            <a
                              href={this.getClickableLink(item.website)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.website}
                            </a>
                          </span>
                        </li>
                        <li className="list-group-item col-sm-6">
                          <span className="font-weight-bold">Contact:</span>{" "}
                          <span>
                            <Link to={"/profileSearch?Id=" + item.profileId}>
                              {item.name};
                            </Link>{" "}
                            <a href={"mailto:" + item.email}>{item.email}</a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </section>
                ))}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default Search;
