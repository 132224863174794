import React, { Component } from "react";
import FormError from "./FormError";
import Axios from "axios";
import FormSuccess from "./FormSuccess";

class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passOne: "",
      passTwo: "",
      errorMessage: null,
      successMessage: null,
      loginDisplay: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;
    this.setState({ [itemName]: itemValue }, () => {
      if (itemName === "passTwo") {
        if (this.state.passOne !== this.state.passTwo) {
          this.setState({ errorMessage: "Passwords no not match" });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    });
  }

  onChangeCheckbox = (event) => {
    this.setState({
      isChecked: event.target.checked,
    });
  };
  handleSubmit(e) {
    e.preventDefault();
    Axios.post("https://foodsystemsdb.extension.iastate.edu/api/resetPass", {
      token: this.props.id,
      password: this.state.passOne,
    }).then((response) => {
      e.target.reset();
      this.setState({ passTwo: "" });
      if (response.data.error) {
        this.setState({ errorMessage: response.data.error });
      } else {
        this.setState({
          successMessage: response.data.message,
          loginDisplay: true,
        });
      }
    });
  }

  render() {
    return (
      <form className="my-5 pb-5 mnHeight" onSubmit={this.handleSubmit}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="card bg-light">
                <div className="card-body">
                  <h3 className="font-weight-light mb-3">Reset Password</h3>
                  <section className="form-group">
                    {this.state.errorMessage !== null ? (
                      <FormError theMessage={this.state.errorMessage} />
                    ) : null}
                    {this.state.successMessage !== null ? (
                      <FormSuccess
                        theMessage={this.state.successMessage}
                        loginDisplay={this.state.loginDisplay}
                      />
                    ) : null}
                  </section>
                  <section className="form-group">
                    <input
                      required
                      className="form-control"
                      type="password"
                      name="passOne"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                  </section>
                  <section className="form-group">
                    <input
                      className="form-control"
                      type="password"
                      required
                      name="passTwo"
                      placeholder="Confirm Password"
                      value={this.state.passTwo}
                      onChange={this.handleChange}
                    />
                  </section>
                  <div className="form-group text-center">
                    <button className="btn col-6 btn-primary" type="submit">
                      Reset Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Reset;
