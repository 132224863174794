import React, { Component } from "react";
import Axios from "axios";
import { Link } from "@reach/router";

class ProfileSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practitioner: [],
      email: null,
      educationalRes: [],
      categories: [
        {
          id: 1,
          value: "Food Systems",
          dbValue: "Food_Systems",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 2,
          value: "Equity",
          dbValue: "Equity",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 3,
          value: "Community Capacity",
          dbValue: "Community_Capacity",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 4,
          value: "Economy and Business Analysis",
          dbValue: "Economy_and_Business_Analysis",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 5,
          value: "Governance and Policy",
          dbValue: "Governance_and_Policy",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 6,
          value: "Health and Wellness",
          dbValue: "Health_and_Wellness",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 7,
          value: "Environment",
          dbValue: "Environment",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 8,
          value: "Leadership",
          dbValue: "Leadership",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 9,
          value: "Evaluation",
          dbValue: "Evaluation",
          isChecked: false,
          sub_categories: [],
        },
      ],
      sub_categoriesLocdict: null,
      c_ids: {},
      propCategories: this.props.categories,
      propSub_categories_dict: this.props.sub_categories_dict,
    };
  }
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const profileId = query.get("Id");
    if (profileId != null) {
      Axios.post(
        "https://foodsystemsdb.extension.iastate.edu/api/profileSearch",
        {
          profileId: profileId,
        }
      ).then(
        (response) => {
          console.log(response.data);
          if (response.data.length > 0) {
            this.setState(
              {
                practitioner: response.data,
                email: response.data[0].email,
                errorMessage: null,
              },
              () => {
                this.populateEducationalResource(profileId);
                if (this.state.propCategories.length <= 0) {
                  this.getProfileCategories(response.data[0].full_competency);
                } else {
                  this.populateProfileCompetency(
                    response.data[0].full_competency
                  );
                }
              }
            );
          }
        },
        (error) => {
          if (error.message !== null) {
            this.setState({ errorMessage: error.message });
          } else {
            this.setState({ errorMessage: null });
          }
        }
      );
    }
  }

  getProfileCategories(full_competency) {
    Axios.get(
      "https://foodsystemsdb.extension.iastate.edu/api/getProfileCategories"
    ).then((response) => {
      if (response) {
        if (response.data.result.length > 0) {
          this.setState({ propCategories: response.data.result }, () => {
            this.populateProfileSubCategoryState(
              response.data.result,
              full_competency
            );
          });
        }
      }
    });
  }

  populateProfileSubCategoryState(categories, full_competency) {
    var sub_category_list = [];
    categories.forEach((category) => {
      sub_category_list.push(category.sub_category);
    });
    sub_category_list = Array.from(new Set(sub_category_list));
    var sub_category_dict = {};
    sub_category_list.forEach((data) => {
      var dd = { isChecked: false };
      sub_category_dict[data] = dd;
    });
    this.setState(
      {
        propSub_categories_dict: sub_category_dict,
      },
      () => {
        this.populateProfileCompetency(full_competency);
      }
    );
  }
  populateEducationalResource(profileId) {
    Axios.post(
      "https://foodsystemsdb.extension.iastate.edu/api/getCurriculaByProfile",
      {
        profileId: profileId,
      }
    ).then(
      (response) => {
        if (response.data.result.length > 0) {
          this.setState({ educationalRes: response.data.result });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }

  populateProfileCompetency(full_competency) {
    if (full_competency != null && this.state.propCategories.length > 0) {
      var full_competencyList = JSON.parse(full_competency);
      var categories_dict = this.state.categories;
      var categories = this.state.propCategories;
      var sub_categories_dict = this.state.propSub_categories_dict;
      Object.keys(full_competencyList).forEach((key) => {
        categories_dict.map((item) => {
          if (item.value === key) {
            item.isChecked = true;
            this.handleChecks(true, item.value);
            return;
          }
          full_competencyList[key].map((comp_id) => {
            var sub_cat = categories.filter(
              (cat) => cat.category_id === comp_id
            )[0];
            sub_categories_dict[sub_cat.sub_category].isChecked = true;
            return;
          });
        });
      });

      var c_ids = {};
      Object.keys(full_competencyList).forEach((key) => {
        full_competencyList[key].forEach((item) => {
          c_ids[item] = key;
        });
      });
      this.setState({
        categories: categories_dict,
        sub_categoriesLocdict: sub_categories_dict,
        c_ids: c_ids,
      });
    }
  }

  populateSubCategory(category) {
    var sub_categories = [];
    this.state.propCategories
      .filter((item) => item.category === category)
      .map((filteredItem) => sub_categories.push(filteredItem.sub_category));
    sub_categories = Array.from(new Set(sub_categories));
    return sub_categories;
  }

  handleChecks(checked, checkedCat) {
    var categories = this.state.categories;
    categories.forEach((item) => {
      if (item.value === checkedCat) {
        item.isChecked = checked;
        var sub_categories = this.populateSubCategory(item.value);
        if (item.isChecked) {
          item.sub_categories = sub_categories;
        }
      }
    });
    this.setState({ categories: categories });
  }

  getClickableLink = (link) => {
    if (link != null) {
      return link.startsWith("http://") || link.startsWith("https://")
        ? link
        : `http://${link}`;
    }
  };

  render() {
    return (
      <div className="container mb-5 pb-5 mnHeight">
        {this.state.practitioner.map((item) => (
          <section className="my-3" key={item.email}>
            <h1 className="text-center mb-4">{item.name}</h1>
            <div id="eduContainer">
              <h4>Primary Organization:</h4> <p>{item.aff1}</p>
              <br />
              <h4>Secondary Affliations</h4> <p>{item.aff2}</p>
              <br />
              <h4>Bio:</h4> <p>{item.bio}</p>
              <br />
              <h4>Work Location:</h4> <p>{item.work_location}</p>
              <br />
              <h4>Area Of Work:</h4> <p>{item.area_of_work}</p>
              <br />
              <h4>Contact:</h4>{" "}
              <p>
                <a href={"mailto:" + item.email}>{item.email}</a>
              </p>
              <br />
              <h4>Website:</h4>{" "}
              <p>
                <a href={this.getClickableLink(item.website)}>{item.website}</a>
              </p>
              <br />
              <h4>Additional Information:</h4>{" "}
              {/* <p>{item.additionalInfo}</p> */}
              <ul>
                <li
                  className={
                    item.urlAdd1 == null || item.urlAdd1 == "" ? "d-none" : ""
                  }
                >
                  {item.urlAddText1}:{" "}
                  <a
                    href={this.getClickableLink(item.urlAdd1)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.urlAdd1}
                  </a>
                </li>
                <li
                  className={
                    item.urlAdd2 == null || item.urlAdd2 == "" ? "d-none" : ""
                  }
                >
                  {item.urlAddText2}:{" "}
                  <a
                    href={this.getClickableLink(item.urlAdd2)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.urlAdd2}
                  </a>
                </li>
                <li
                  className={
                    item.urlAdd3 == null || item.urlAdd3 == "" ? "d-none" : ""
                  }
                >
                  {item.urlAddText3}:{" "}
                  <a
                    href={this.getClickableLink(item.urlAdd3)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.urlAdd3}
                  </a>
                </li>
                <li
                  className={
                    item.urlAdd4 == null || item.urlAdd4 == "" ? "d-none" : ""
                  }
                >
                  {item.urlAddText4}:{" "}
                  <a
                    href={this.getClickableLink(item.urlAdd4)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.urlAdd4}
                  </a>
                </li>
                <li
                  className={
                    item.urlAdd5 == null || item.urlAdd5 == "" ? "d-none" : ""
                  }
                >
                  {item.urlAddText5}:{" "}
                  <a
                    href={this.getClickableLink(item.urlAdd5)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.urlAdd5}
                  </a>
                </li>
              </ul>
              <br />
            </div>
            <div className="form-group">
              <h4 hmtlfor="input-subAff">
                <a href="/competencies" target="_blank">
                  Competencies:
                </a>
              </h4>
              <div className="form-group mx-4">
                {this.state.categories.map((category, index) => {
                  return (
                    <div key={index}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          value={category.value}
                          checked={category.isChecked}
                          disabled
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox1"
                        >
                          {category.value}
                        </label>
                      </div>
                      {category.isChecked && (
                        <div className="mx-5">
                          {category.sub_categories.map(
                            (sub_category, index) => {
                              return (
                                <div key={index}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox2"
                                      value={sub_category}
                                      checked={
                                        this.state.sub_categoriesLocdict[
                                          sub_category
                                        ].isChecked
                                      }
                                      disabled
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineCheckbox2"
                                    >
                                      {sub_category}
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        ))}
        <h1>Education Resources:</h1>
        {this.state.educationalRes.map((item) => (
          <section className="card mt-3 mb-3" key={item.curricula_id}>
            <div className="card-body text-center">
              <a href={"/eduResourceSearch?Id=" + item.curricula_id}>
                <h2 className="card-title">{item.curricula_title}</h2>
              </a>
              <h5 className="card-subtitle mb-2">{item.org_name}</h5>
              <p className="card-text">{item.description}</p>
              <ul className="list-group list-group-horizontal-md row">
                <li className="list-group-item col-sm-4">
                  <span className="font-weight-bold">Cost:</span>{" "}
                  <span>{item.full_fee}</span>
                </li>
                <li className="list-group-item col-sm-4">
                  <span className="font-weight-bold">Delivery Method:</span>{" "}
                  <span>{item.method_of_delivery}</span>
                </li>
                <li className="list-group-item col-sm-4">
                  <span className="font-weight-bold">Hours:</span>{" "}
                  <span>{item.hours}</span>
                </li>
              </ul>
              <ul className="list-group list-group-horizontal-md row">
                <li className="list-group-item col-sm-6">
                  <span className="font-weight-bold">Certification:</span>{" "}
                  <span>{item.completion}</span>
                </li>
                <li className="list-group-item col-sm-6">
                  <span className="font-weight-bold">Location:</span>{" "}
                  <span>{item.location_desc}</span>
                </li>
              </ul>
              <ul className="list-group list-group-horizontal-md row">
                <li className="list-group-item col-sm-6">
                  <span className="font-weight-bold">Website:</span>{" "}
                  <span>{item.website}</span>
                </li>
                <li className="list-group-item col-sm-6">
                  <span className="font-weight-bold">Contact:</span>{" "}
                  <span>
                    <Link to={"/profileSearch?Id=" + item.profileId}>
                      {item.name};
                    </Link>{" "}
                    <a href={"mailto:" + item.email}>{item.email}</a>
                  </span>
                </li>
              </ul>
            </div>
          </section>
        ))}
      </div>
    );
  }
}

export default ProfileSearch;
