import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="container-fluid mb-3 mnHeight">
        <div className="row justify-content-center align-items-center bg-colorPantone p-4">
          <h1 className="text-dark">About Us</h1>
        </div>
        <div className="row">
          <div className="container text-left mb-2">
            <img
              className="foodsystemLogo mt-3"
              src="images/FS Database Logo_2021.png"
            />
            <h2 className="mt-5">Mission</h2>
            <p>
              Our mission is to identify and aggregate individual practitioner
              and educational resources to improve the competencies of food
              systems practitioners through various levels of learning in order
              to advance food systems development.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="container text-left mb-4">
            <h2 className="mt-3">Purpose</h2>
            <p>
              We believe this is a critical juncture of local, regional and
              global food systems evolution. We are continually experiencing
              changes in educational offerings related to accessibility and
              equitable access whether through language translations, virtual
              and in-person offerings, and sliding-scale pricing. This database
              fills a gap to identify and showcase resources based on competency
              as well as logistical offerings such as language, price, location,
              and method of delivery.{" "}
            </p>
            <p>
              The database exists to highlight and lift-up partner networks,
              educational resources and food systems practitioners and serves as
              an identification tool for mentorship and learning opportunities
              related to personal and professional growth. Additionally, it
              serves as a research tool for assessing educational resources
              related to competency, location of offerings, methods of delivery,
              language accessibility, and location.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="container text-left mb-4">
            <h2 className="mt-3">Scope</h2>
            <p>This database will: </p>
            <ul>
              <li>
                Identify educational resources provided by organizations and
                businesses based on food systems competencies; however this
                database will not accredit, validate or vet any resources shared
                through the database, no will it be used to offer specific
                professional development programs.{" "}
              </li>
              <li>
                Allow individual practitioners associated with organizations or
                businesses to create profiles as a means to support personal and
                professional growth through mentorship, networking, etc.; it
                will not provide facilitated meetings, listservs, or leadership
                professional development offerings.
              </li>
              <li>
                Identify educational resources that have specified learning
                objectives and outcomes related to food systems competencies;
                this will not include resources such as listservs, publications,
                newsletters, etc.
              </li>
              <li>
                Promote and share additional directories and networks related to
                personal and professional growth in food systems.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
