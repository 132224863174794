import React, { Component } from "react";
import { states } from "./us_states.js";
import Axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FormError from "./FormError";
import ReactTooltip from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";

class NewEducationalResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // enableOrg: false,
      check_limit: 0,
      org: null,
      c_ids: {},
      curricula_title: null,
      selected_location: null,
      selected_location_value: null,
      selected_location_state: null,
      desc: null,
      categories: [
        {
          id: 1,
          value: "Food Systems",
          dbValue: "Food_Systems",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 2,
          value: "Equity",
          dbValue: "Equity",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 3,
          value: "Community Capacity",
          dbValue: "Community_Capacity",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 4,
          value: "Economy and Business Analysis",
          dbValue: "Economy_and_Business_Analysis",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 5,
          value: "Governance and Policy",
          dbValue: "Governance_and_Policy",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 6,
          value: "Health and Wellness",
          dbValue: "Health_and_Wellness",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 7,
          value: "Environment",
          dbValue: "Environment",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 8,
          value: "Leadership",
          dbValue: "Leadership",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 9,
          value: "Evaluation",
          dbValue: "Evaluation",
          isChecked: false,
          sub_categories: [],
        },
      ],
      m_o_d: [
        { id: 1, value: "Online Course", dbValue: "online", isChecked: false },
        { id: 2, value: "In-person", dbValue: "in_person", isChecked: false },
        {
          id: 3,
          value: "Site visit",
          dbValue: "site_visits",
          isChecked: false,
        },
        { id: 4, value: "Self-paced", dbValue: "self_paced", isChecked: false },
        {
          id: 5,
          value: "Cohort Dicussions",
          dbValue: "cohort_discussions",
          isChecked: false,
        },
        { id: 6, value: "Other", dbValue: "MODOther", isChecked: false },
      ],
      audience: [
        { id: 1, value: "Farmers", dbValue: "Farmers", isChecked: false },
        {
          id: 2,
          value: "Food Businesses",
          dbValue: "Food_Businesses",
          isChecked: false,
        },
        {
          id: 3,
          value: "College Students",
          dbValue: "College_Students",
          isChecked: false,
        },
        {
          id: 4,
          value: "Extension Staff",
          dbValue: "Extension_Staff",
          isChecked: false,
        },
        {
          id: 5,
          value: "Agriculture Professionals",
          dbValue: "Agriculture_Professionals",
          isChecked: false,
        },
        {
          id: 6,
          value: "Non-profit Staff",
          dbValue: "Non_profit_Staff",
          isChecked: false,
        },
        {
          id: 7,
          value: "Community Advocates",
          dbValue: "Community_Advocates",
          isChecked: false,
        },
        {
          id: 8,
          value: "Community Planners and Developers",
          dbValue: "Community_Planners_and_Developers",
          isChecked: false,
        },
        {
          id: 9,
          value: "Early Childhood Educators",
          dbValue: "Early_Childhood_Educators",
          isChecked: false,
        },
        {
          id: 10,
          value: "Policy Makers",
          dbValue: "Policy_Makers",
          isChecked: false,
        },
        {
          id: 11,
          value: "Food Service Directors",
          dbValue: "Food_Service_Directors",
          isChecked: false,
        },
        { id: 12, value: "Other", dbValue: "AUDOther", isChecked: false },
      ],
      fee: [
        { id: 1, value: "Free", dbValue: "Free", isChecked: false },
        { id: 2, value: "Tuition", dbValue: "Tuition", isChecked: false },
        { id: 3, value: "Fee", dbValue: "Fee", isChecked: false },
      ],
      comp_ack: [
        {
          id: 1,
          value: "Completion Certificate",
          dbValue: "Completion_Certificate",
          isChecked: false,
        },
        {
          id: 2,
          value: "Course Credit",
          dbValue: "Course_Credit",
          isChecked: false,
        },
        {
          id: 3,
          value: "Transferable Materials",
          dbValue: "Transferable_Materials",
          isChecked: false,
        },
        {
          id: 4,
          value: "Train the Trainer",
          dbValue: "Train_the_Trainer",
          isChecked: false,
        },
        { id: 5, value: "Other", dbValue: "ACKOther", isChecked: false },
      ],
      location: [
        { id: 1, value: "City", dbValue: "City", isChecked: false },
        { id: 2, value: "County", dbValue: "County", isChecked: false },
        { id: 3, value: "State", dbValue: "State", isChecked: false },
        { id: 4, value: "Region", dbValue: "Region", isChecked: false },
        { id: 5, value: "National", dbValue: "National", isChecked: false },
      ],
      otherMOD: false,
      otherAUD: false,
      otherACK: false,
      otherFee: false,
      otherFeeTut: false,
      otherMODtxt: null,
      otherAUDtxt: null,
      otherACKtxt: null,
      otherFeetxt: null,
      website: null,
      hours: "",
      errorMessage: null,
      editCurriculaActive: false,
      curricula_id: null,
      language: null,
    };
    // this.enableOrgText = this.enableOrgText.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChecks = this.handleChecks.bind(this);
    this.handleChecksSubCat = this.handleChecksSubCat.bind(this);
    this.handleChecksSSCat = this.handleChecksSSCat.bind(this);
    this.handleCheckLevel = this.handleCheckLevel.bind(this);
    this.getCatDetails = this.getCatDetails.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.populateSubCategory = this.populateSubCategory.bind(this);
    this.handleCheckOnMOD = this.handleCheckOnMOD.bind(this);
    this.handleCheckOnAudience = this.handleCheckOnAudience.bind(this);
    this.handleCheckOnFee = this.handleCheckOnFee.bind(this);
    this.handleCheckOnCompACK = this.handleCheckOnCompACK.bind(this);
    this.resetAll = this.resetAll.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const curricula_id = query.get("ID");
    if (curricula_id != null && this.props.profileId) {
      this.setState({ editCurriculaActive: true, curricula_id: curricula_id });
      this.populateCurricula(curricula_id);
    } else {
      this.setState({ editCurriculaActive: false });
      this.props.populateSubCategoryState(this.props.categories);
      this.props.populateSScategoryState(this.props.categories);
    }
  }

  populateCurricula(curricula_id) {
    Axios.post("https://foodsystemsdb.extension.iastate.edu/api/getCurricula", {
      // email: this.state.email,
      profileId: this.state.profileId,
      curricula_id: curricula_id,
    }).then(
      (response) => {
        if (response.data.length > 0) {
          var full_curriculaData = response.data[0];
          this.setState({
            curricula_title: response.data[0].curricula_title,
            org: response.data[0].org_id,
            hours: response.data[0].hours,
            website: response.data[0].website,
            language: response.data[0].languageOffered,
            desc: response.data[0].description,
            selected_location: response.data[0].location,
            selected_location_value:
              response.data[0].location_desc != null
                ? response.data[0].location_desc.split(",")[0]
                : response.data[0].location_desc,
            selected_location_state:
              response.data[0].location_desc != null
                ? response.data[0].location_desc.split(", ")[1]
                : response.data[0].location_desc,
            otherFeetxt: response.data[0].FEEOther,
            otherACKtxt: response.data[0].ACKOther,
            otherAUDtxt: response.data[0].AUDOther,
            otherMODtxt: response.data[0].MODOther,
          });
          //Competencies Block
          var full_competency = JSON.parse(response.data[0].listOfComp);
          var categories_dict = this.state.categories;
          // var sub_categories_dict = this.props.sub_categories;
          // var ss_categories_dict = this.props.ss_categories;
          var categories = this.props.categories;
          Object.keys(full_competency).forEach((key) => {
            categories_dict.map((item) => {
              if (item.value === key) {
                item.isChecked = true;
                this.handleChecks(true, item);
                return;
              }
            });
            full_competency[key].map((comp_id) => {
              var all_cat = categories.filter(
                (item) => item.category_id === comp_id
              );
              //sub_categories_dict[all_cat[0].sub_category].isChecked = true;
              //ss_categories_dict[all_cat[0].ss_category].isChecked = true;
              this.handleChecksSubCat(
                true,
                all_cat[0].category,
                all_cat[0].sub_category
              );
              this.handleChecksSSCat(
                true,
                all_cat[0].category,
                all_cat[0].sub_category,
                all_cat[0].ss_category
              );
              this.handleCheckLevel(
                true,
                all_cat[0].ss_category,
                all_cat[0].level
              );
              this.getCatDetails(
                true,
                all_cat[0].category,
                all_cat[0].sub_category,
                all_cat[0].ss_category,
                all_cat[0].level
              );
              return;
            });
          });

          this.setState({ categories: categories_dict });

          Object.keys(full_curriculaData).forEach((element) => {
            //MOD
            this.state.m_o_d.map((item) => {
              if (
                full_curriculaData[element] === "x" ||
                (element === "MODOther" && full_curriculaData[element] !== null)
              ) {
                if (item.dbValue === element) {
                  this.handleCheckOnMOD(item.value, true);
                  return;
                }
              }
            });

            //AUD
            this.state.audience.map((item) => {
              if (
                full_curriculaData[element] === "x" ||
                (element === "AUDOther" && full_curriculaData[element] !== null)
              ) {
                if (item.dbValue === element) {
                  this.handleCheckOnAudience(item.value, true);
                  return;
                }
              }
            });

            //Fee
            this.state.fee.map((item) => {
              if (
                full_curriculaData[element] === "x" ||
                (element === "FEEOther" && full_curriculaData[element] !== null)
              ) {
                if (item.dbValue === element) {
                  this.handleCheckOnFee(item.value, true);
                  return;
                }
              }
            });

            //Completion ACK
            this.state.comp_ack.map((item) => {
              if (
                full_curriculaData[element] === "x" ||
                (element === "ACKOther" && full_curriculaData[element] !== null)
              ) {
                if (item.dbValue === element) {
                  this.handleCheckOnCompACK(item.value, true);
                  return;
                }
              }
            });
          });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }

  handleChange(e) {
    e.preventDefault();
    var itemName = e.target.name;
    var itemValue = e.target.value;
    this.setState({
      [itemName]: itemValue,
    });
  }

  populateSubCategory(category) {
    var sub_categories = [];
    this.props.categories
      .filter((item) => item.category === category)
      .map((filteredItem) => sub_categories.push(filteredItem.sub_category));
    sub_categories = Array.from(new Set(sub_categories));
    return sub_categories;
  }

  handleChecks(checked, checkedCat) {
    var categories = this.state.categories;
    categories.forEach((item) => {
      if (item.id === checkedCat.id) {
        item.isChecked = checked;
        var sub_categories = this.populateSubCategory(item.value);
        if (item.isChecked) {
          item.sub_categories = sub_categories;
          //this.setState({ check_limit: this.state.check_limit + 1 });
        } else {
          //this.setState({ check_limit: this.state.check_limit - 1 });
          var sub_categories_dict = this.props.sub_categories;
          var ss_categories_dict = this.props.ss_categories;
          var ss_categories = {};
          var selected_cat = item.value;
          var selected_sub_cat,
            selected_ss_cat = "";
          sub_categories.forEach((sub_key) => {
            if (sub_categories_dict[sub_key].isChecked) {
              selected_sub_cat = sub_key;
              sub_categories_dict[sub_key].isChecked = false;

              ss_categories = this.populateSSubCategory(
                item.value,
                selected_sub_cat
              );
              ss_categories.forEach((ss_key) => {
                if (ss_categories_dict[ss_key].isChecked) {
                  selected_ss_cat = ss_key;
                  ss_categories_dict[ss_key].isChecked = false;

                  Object.keys(ss_categories_dict[ss_key].level).forEach(
                    (key) => {
                      if (ss_categories_dict[ss_key].level[key].isChecked) {
                        ss_categories_dict[ss_key].level[key].isChecked = false;
                        this.getCatDetails(
                          checked,
                          selected_cat,
                          selected_sub_cat,
                          selected_ss_cat,
                          key
                        );
                      }
                    }
                  );
                }
              });
            }
          });
          this.setState({
            sub_categories: sub_categories_dict,
            ss_categories: ss_categories_dict,
          });
        }
      }
    });
    // if (event.target.checked) {
    //     if (this.state.check_limit >= 2) {
    //         categories.forEach(item => {
    //             if (!item.isChecked) {
    //                 item.disabled = true;
    //             }
    //         })
    //     } else {
    //         this.setState({ check_limit: this.state.check_limit + 1 });
    //     }
    // } else {
    //     if (this.state.check_limit >= 2) {
    //         categories.forEach(item => {
    //             if (item.disabled) {
    //                 item.disabled = false;
    //             }
    //         })
    //     }
    //     this.setState({ check_limit: this.state.check_limit - 1 })
    // }
    this.setState({ categories: categories });
  }

  populateSSubCategory(category, checked_subcategory) {
    var ss_categories = [];
    this.props.categories
      .filter(
        (item) =>
          item.category === category &&
          item.sub_category === checked_subcategory
      )
      .map((filteredItem) => ss_categories.push(filteredItem.ss_category));
    ss_categories = Array.from(new Set(ss_categories));
    return ss_categories;
  }

  handleChecksSubCat(checked, category, checkedSubCat) {
    var sub_categories = this.props.sub_categories;
    sub_categories[checkedSubCat].isChecked = checked;
    var ss_categories = this.populateSSubCategory(category, checkedSubCat);
    if (checked) {
      sub_categories[checkedSubCat].ss_categories = ss_categories;
    } else {
      var ss_categories_dict = this.props.ss_categories;
      var selected_sub_cat = checkedSubCat;
      var selected_ss_cat = "";
      ss_categories.forEach((ss_key) => {
        if (ss_categories_dict[ss_key].isChecked) {
          selected_ss_cat = ss_key;
          ss_categories_dict[ss_key].isChecked = false;

          Object.keys(ss_categories_dict[ss_key].level).forEach((key) => {
            if (ss_categories_dict[ss_key].level[key].isChecked) {
              ss_categories_dict[ss_key].level[key].isChecked = false;
              this.getCatDetails(
                checked,
                category,
                selected_sub_cat,
                selected_ss_cat,
                key
              );
            }
          });
        }
      });
      this.setState({ ss_categories: ss_categories_dict });
    }
    this.setState({ sub_categories: sub_categories });
  }

  handleChecksSSCat(checked, category, sub_category, checkedSSCat) {
    var ss_categories = this.props.ss_categories;
    ss_categories[checkedSSCat].isChecked = checked;
    if (!checked) {
      Object.keys(ss_categories[checkedSSCat].level).forEach((key) => {
        if (ss_categories[checkedSSCat].level[key].isChecked) {
          ss_categories[checkedSSCat].level[key].isChecked = false;
          this.getCatDetails(
            checked,
            category,
            sub_category,
            checkedSSCat,
            key
          );
        }
      });
    }
    this.setState({ ss_categories: ss_categories });
  }

  handleCheckLevel(checked, ss_category, checkedLevel) {
    var ss_categories = this.props.ss_categories;
    ss_categories[ss_category].level[checkedLevel].isChecked = checked;
    this.setState({ ss_categories: ss_category });
  }

  getCatDetails(checked, category, sub_category, ss_category, level) {
    var c_ids = this.state.c_ids;
    var checked_cat = this.props.categories.filter(
      (item) =>
        item.category === category &&
        item.sub_category === sub_category &&
        item.ss_category === ss_category &&
        item.level === level
    );
    if (checked) {
      c_ids[checked_cat[0].category_id] = checked_cat[0].category;
    } else {
      delete c_ids[checked_cat[0].category_id];
    }
    this.setState({ c_ids: c_ids });
  }

  resetAll() {
    this.setState({
      check_limit: 0,
      org: null,
      c_ids: {},
      curricula_title: null,
      selected_location: null,
      selected_location_value: null,
      selected_location_state: null,
      desc: null,
      categories: [
        {
          id: 1,
          value: "Food Systems",
          dbValue: "Food_Systems",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 2,
          value: "Equity",
          dbValue: "Equity",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 3,
          value: "Community Capacity",
          dbValue: "Community_Capacity",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 4,
          value: "Economy and Business Analysis",
          dbValue: "Economy_and_Business_Analysis",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 5,
          value: "Governance and Policy",
          dbValue: "Governance_and_Policy",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 6,
          value: "Health and Wellness",
          dbValue: "Health_and_Wellness",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 7,
          value: "Environment",
          dbValue: "Environment",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 8,
          value: "Leadership",
          dbValue: "Leadership",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 9,
          value: "Evaluation",
          dbValue: "Evaluation",
          isChecked: false,
          sub_categories: [],
        },
      ],
      m_o_d: [
        { id: 1, value: "Online Course", dbValue: "online", isChecked: false },
        { id: 2, value: "In-person", dbValue: "in_person", isChecked: false },
        {
          id: 3,
          value: "Site visit",
          dbValue: "site_visits",
          isChecked: false,
        },
        { id: 4, value: "Self-paced", dbValue: "self_paced", isChecked: false },
        {
          id: 5,
          value: "Cohort Dicussions",
          dbValue: "cohort_discussions",
          isChecked: false,
        },
        { id: 6, value: "Other", dbValue: "MODOther", isChecked: false },
      ],
      audience: [
        { id: 1, value: "Farmers", dbValue: "Farmers", isChecked: false },
        {
          id: 2,
          value: "Food Businesses",
          dbValue: "Food_Businesses",
          isChecked: false,
        },
        {
          id: 3,
          value: "College Students",
          dbValue: "College_Students",
          isChecked: false,
        },
        {
          id: 4,
          value: "Extension Staff",
          dbValue: "Extension_Staff",
          isChecked: false,
        },
        {
          id: 5,
          value: "Agriculture Professionals",
          dbValue: "Agriculture_Professionals",
          isChecked: false,
        },
        {
          id: 6,
          value: "Non-profit Staff",
          dbValue: "Non_profit_Staff",
          isChecked: false,
        },
        {
          id: 7,
          value: "Community Advocates",
          dbValue: "Community_Advocates",
          isChecked: false,
        },
        {
          id: 8,
          value: "Community Planners and Developers",
          dbValue: "Community_Planners_and_Developers",
          isChecked: false,
        },
        {
          id: 9,
          value: "Early Childhood Educators",
          dbValue: "Early_Childhood_Educators",
          isChecked: false,
        },
        {
          id: 10,
          value: "Policy Makers",
          dbValue: "Policy_Makers",
          isChecked: false,
        },
        {
          id: 11,
          value: "Food Service Directors",
          dbValue: "Food_Service_Directors",
          isChecked: false,
        },
        { id: 12, value: "Other", dbValue: "AUDOther", isChecked: false },
      ],
      fee: [
        { id: 1, value: "Free", dbValue: "Free", isChecked: false },
        { id: 2, value: "Tuition", dbValue: "Tuition", isChecked: false },
        { id: 3, value: "Fee", dbValue: "Fee", isChecked: false },
      ],
      comp_ack: [
        {
          id: 1,
          value: "Completion Certificate",
          dbValue: "Completion_Certificate",
          isChecked: false,
        },
        {
          id: 2,
          value: "Course Credit",
          dbValue: "Course_Credit",
          isChecked: false,
        },
        {
          id: 3,
          value: "Transferable Materials",
          dbValue: "Transferable_Materials",
          isChecked: false,
        },
        {
          id: 4,
          value: "Train the Trainer",
          dbValue: "Train_the_Trainer",
          isChecked: false,
        },
        { id: 5, value: "Other", dbValue: "ACKOther", isChecked: false },
      ],
      location: [
        { id: 1, value: "City", dbValue: "City", isChecked: false },
        { id: 2, value: "County", dbValue: "County", isChecked: false },
        { id: 3, value: "State", dbValue: "State", isChecked: false },
        { id: 4, value: "Region", dbValue: "Region", isChecked: false },
        { id: 5, value: "National", dbValue: "National", isChecked: false },
      ],
      otherMOD: false,
      otherAUD: false,
      otherACK: false,
      otherFee: false,
      otherFeeTut: false,
      otherMODtxt: null,
      otherAUDtxt: null,
      otherACKtxt: null,
      otherFeetxt: null,
      website: null,
      hours: null,
      errorMessage: null,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    var mod_fields = [];
    this.state.m_o_d
      .filter((item) => item.isChecked === true)
      .map((filteredItem) => mod_fields.push(filteredItem.dbValue));
    var audience_fields = [];
    this.state.audience
      .filter((item) => item.isChecked === true)
      .map((filteredItem) => audience_fields.push(filteredItem.dbValue));
    var fee_fields = [];
    this.state.fee
      .filter((item) => item.isChecked === true)
      .map((filteredItem) => fee_fields.push(filteredItem.dbValue));
    var compAck_fields = [];
    this.state.comp_ack
      .filter((item) => item.isChecked === true)
      .map((filteredItem) => compAck_fields.push(filteredItem.dbValue));

    var c_ids = this.state.c_ids;

    if (!Object.keys(c_ids).length) {
      alert("Please Select atleast on level in Comptencies");
      return;
    }

    if (!mod_fields.length) {
      alert("Please Select atleast one Method of delivery");
      return;
    }
    if (!audience_fields.length) {
      alert("Please Select atleast one Audience");
      return;
    }

    var comp_ids = {};
    Object.keys(c_ids).forEach((key) => {
      if (c_ids[key] in comp_ids) {
        comp_ids[c_ids[key]].push(key);
      } else {
        comp_ids[c_ids[key]] = [key];
      }
    });
    Axios.post(
      "https://foodsystemsdb.extension.iastate.edu/api/addNewCurricula",
      {
        comp_ids: comp_ids,
        profileId: this.props.profileId,
        title: this.state.curricula_title,
        org: this.state.org,
        description: this.state.desc,
        mod_fields: mod_fields,
        audience_fields: audience_fields,
        fee_fields: fee_fields,
        compAck_fields: compAck_fields,
        location: this.state.selected_location,
        locationDesc: this.state.selected_location_value,
        locationState: this.state.selected_location_state,
        otherACK: this.state.otherACKtxt,
        otherAUD: this.state.otherAUDtxt,
        otherFee: this.state.otherFeetxt,
        otherMOD: this.state.otherMODtxt,
        hours: this.state.hours,
        website: this.state.website,
        language: this.state.language,
        curricula_id: this.state.curricula_id,
      }
    ).then(
      (response) => {
        if (response.data.message !== "NewCurriculumAdded") {
          this.setState({ errorMessage: response.data.error.sqlMessage });
        } else {
          if (!this.state.editCurriculaActive) {
            this.resetAll();
            e.target.reset();
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui">
                    <h1>New Educational Resource Added Successfully</h1>
                    <button className="btn btn-primary mr-2" onClick={onClose}>
                      OK
                    </button>
                  </div>
                );
              },
            });
          } else if (this.state.editCurriculaActive) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui">
                    <h1>Educational Resource Saved Successfully</h1>
                    <button className="btn btn-primary mr-2" onClick={onClose}>
                      OK
                    </button>
                  </div>
                );
              },
            });
          }
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }

  handleCheckOnMOD(value, checked) {
    var m_o_d = this.state.m_o_d;
    m_o_d.forEach((item) => {
      if (item.value === value) {
        item.isChecked = checked;
      }
      return;
    });
    if (value === "Other") {
      this.setState({ otherMOD: !this.state.otherMOD });
    }
    this.setState({ m_o_d: m_o_d });
  }

  handleCheckOnAudience(value, checked) {
    var audience = this.state.audience;
    audience.forEach((item) => {
      if (item.value === value) {
        item.isChecked = checked;
      }
      return;
    });
    if (value === "Other") {
      this.setState({ otherAUD: !this.state.otherAUD });
    }
    this.setState({ audience: audience });
  }

  handleCheckOnCompACK(value, checked) {
    var comp_ack = this.state.comp_ack;
    comp_ack.forEach((item) => {
      if (item.value === value) {
        item.isChecked = checked;
      }
      return;
    });
    if (value === "Other") {
      this.setState({ otherACK: !this.state.otherACK });
    }
    this.setState({ comp_ack: comp_ack });
  }

  handleCheckOnFee(value, checked) {
    if (value === "Tuition") {
      this.setState({ otherFeeTut: checked });
    }
    if (value === "Fee") {
      this.setState({ otherFee: checked });
    }

    var fee = this.state.fee;
    fee.forEach((item) => {
      if (item.value === value) {
        item.isChecked = checked;
      }
      return;
    });
    this.setState({ fee: fee });
  }

  render() {
    return (
      <div className="mb-5 pb-5 mnHeight">
        {this.props.userName && (
          <div
            className="container mt-4 bg-light border border-info"
            style={{ borderWidth: "thick" }}
          >
            <form id="newCurform" className="p-4" onSubmit={this.handleSubmit}>
              <div className="form-row">
                <div className="col-12 ">
                  <h3>Resource details</h3>
                  <p className="mb-0">
                    Educational resources will be publicly searchable. It is
                    required to share your title, audience, location, method of
                    delivery, core competencies, learning objectives and primary
                    contact for each resource. A website link with more
                    information about the resource is also encouraged.
                    Additional options information includes hours of completion,
                    fees, and completion acknowledgement
                  </p>
                </div>
              </div>
              {this.state.errorMessage !== null ? (
                <FormError theMessage={this.state.errorMessage} />
              ) : null}
              <hr className="my-4" />
              <label hmtlfor="input-curricula-title">Title of Resource*</label>
              <div className="form-group mx-4">
                <input
                  type="text"
                  id="input-curricula-title"
                  className="form-control form-control-alternative"
                  maxLength={150}
                  name="curricula_title"
                  required
                  onChange={(e) => this.handleChange(e)}
                  value={this.state.curricula_title}
                />
              </div>
              <label hmtlfor="input-org">Organization* </label>{" "}
              <FaInfoCircle
                data-tip="State the primary organization offering the educational resource"
                data-place="right"
                color="blue"
                data-background-color="#fff"
                data-text-color="black "
                data-arrow-color="#fff"
              ></FaInfoCircle>
              <ReactTooltip place="right" type="info" effect="solid" />
              <div className="form-group mx-4">
                <select
                  id="inputState"
                  className="form-control"
                  name="org"
                  onChange={this.handleChange}
                  required
                  value={this.state.org}
                >
                  <option value="">Select</option>
                  {this.props.organizations &&
                    this.props.organizations.map((item, index) => {
                      return (
                        <option key={index} value={item.org_id}>
                          {item.org_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <label className="mr-2">
                Select{" "}
                <a href="/competencies" target="_blank">
                  Competencies*
                </a>{" "}
              </label>{" "}
              <FaInfoCircle
                data-tip="Identify the categories, theme, competence and learning objectives that the resource meets, see core competency page for additional information on each level"
                data-place="right"
                color="blue"
                data-background-color="#fff"
                data-text-color="black "
                data-arrow-color="#fff"
              ></FaInfoCircle>
              <ReactTooltip place="right" type="info" effect="solid" />
              <br />
              <div className="form-group mx-4">
                {this.state.categories.map((category, index) => {
                  return (
                    <div key={index}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          value={category.value}
                          onChange={(e) =>
                            this.handleChecks(e.target.checked, category)
                          }
                          disabled={category.disabled}
                          checked={category.isChecked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox1"
                        >
                          {category.value}
                        </label>
                      </div>
                      {category.isChecked && (
                        <div className="mx-5">
                          {category.sub_categories.map(
                            (sub_category, index) => {
                              return (
                                <div key={index}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="inlineCheckbox2"
                                      value={sub_category}
                                      onChange={(e) =>
                                        this.handleChecksSubCat(
                                          e.target.checked,
                                          category.value,
                                          sub_category
                                        )
                                      }
                                      disabled={category.disabled}
                                      checked={
                                        this.props.sub_categories[sub_category]
                                          .isChecked
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineCheckbox2"
                                    >
                                      {sub_category}
                                    </label>
                                  </div>
                                  {this.props.sub_categories[sub_category]
                                    .isChecked && (
                                    <div className="mx-5">
                                      {this.props.sub_categories[
                                        sub_category
                                      ].ss_categories.map(
                                        (ss_category, index) => {
                                          return (
                                            <div key={index}>
                                              <div className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  id="inlineCheckbox3"
                                                  value={ss_category}
                                                  onChange={(e) =>
                                                    this.handleChecksSSCat(
                                                      e.target.checked,
                                                      category.value,
                                                      sub_category,
                                                      ss_category
                                                    )
                                                  }
                                                  disabled={category.disabled}
                                                  checked={
                                                    this.props.ss_categories[
                                                      ss_category
                                                    ].isChecked
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor="inlineCheckbox3"
                                                >
                                                  {ss_category}
                                                </label>
                                              </div>
                                              {this.props.ss_categories[
                                                ss_category
                                              ].isChecked && (
                                                <div className="mx-5">
                                                  {Object.keys(
                                                    this.props.ss_categories[
                                                      ss_category
                                                    ].level
                                                  ).map((level, index) => {
                                                    return (
                                                      <div
                                                        className="form-check"
                                                        key={index}
                                                      >
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id="inlineCheckbox4"
                                                          value={level}
                                                          checked={
                                                            this.props
                                                              .ss_categories[
                                                              ss_category
                                                            ].level[level]
                                                              .isChecked
                                                          }
                                                          onChange={(e) => {
                                                            this.handleCheckLevel(
                                                              e.target.checked,
                                                              ss_category,
                                                              level
                                                            );
                                                            this.getCatDetails(
                                                              e.target.checked,
                                                              category.value,
                                                              sub_category,
                                                              ss_category,
                                                              level
                                                            );
                                                          }}
                                                          disabled={
                                                            category.disabled
                                                          }
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor="inlineCheckbox4"
                                                        >
                                                          {level}
                                                        </label>
                                                      </div>
                                                    );
                                                  })}
                                                </div>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <label>Method Of Delivery* </label>{" "}
              <FaInfoCircle
                data-tip="Select how the resource is offered, whether online, in-person, etc."
                data-place="right"
                color="blue"
                data-background-color="#fff"
                data-text-color="black "
                data-arrow-color="#fff"
              ></FaInfoCircle>
              <ReactTooltip place="right" type="info" effect="solid" />
              <br />
              <div className="form-group mx-4">
                {this.state.m_o_d.map((method, index) => {
                  return (
                    <div key={index}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          name="MODreq"
                          type="checkbox"
                          id="MOD"
                          value={method.value}
                          onChange={(e) =>
                            this.handleCheckOnMOD(
                              e.target.value,
                              e.target.checked
                            )
                          }
                          checked={method.isChecked}
                        />
                        <label className="form-check-label" htmlFor={index}>
                          {method.value}
                        </label>
                      </div>
                    </div>
                  );
                })}
                {this.state.otherMOD && (
                  <div className="form-group">
                    <input
                      type="text"
                      id="input-org"
                      className="form-control form-control-alternative"
                      placeholder="Type other method of delivery"
                      name="otherMODtxt"
                      required
                      value={this.state.otherMODtxt}
                      onChange={(e) => {
                        this.handleChange(e);
                        this.handleCheckOnMOD(e.target.value, e.target.checked);
                      }}
                    />
                  </div>
                )}
              </div>
              <label>Audience* </label>{" "}
              <FaInfoCircle
                data-tip="Identify the primary audiences targeted for the resource"
                data-place="right"
                color="blue"
                data-background-color="#fff"
                data-text-color="black "
                data-arrow-color="#fff"
              ></FaInfoCircle>
              <ReactTooltip place="right" type="info" effect="solid" />
              <br />
              <div className="form-group mx-4">
                {this.state.audience.map((aud, index) => {
                  return (
                    <div key={index}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="AUD"
                          value={aud.value}
                          onChange={(e) =>
                            this.handleCheckOnAudience(
                              e.target.value,
                              e.target.checked
                            )
                          }
                          checked={aud.isChecked}
                        />
                        <label className="form-check-label" htmlFor={index}>
                          {aud.value}
                        </label>
                      </div>
                    </div>
                  );
                })}
                {this.state.otherAUD && (
                  <div className="form-group">
                    <input
                      type="text"
                      id="input-org"
                      className="form-control form-control-alternative"
                      placeholder="Type other Audience"
                      name="otherAUDtxt"
                      value={this.state.otherAUDtxt}
                      required
                      onChange={(e) => {
                        this.handleChange(e);
                        this.handleCheckOnAudience(
                          e.target.value,
                          e.target.checked
                        );
                      }}
                    />
                  </div>
                )}
              </div>
              <label>Location Offering* </label>{" "}
              <FaInfoCircle
                data-tip="Identify the location or region that the resource is offered"
                data-place="right"
                color="blue"
                data-background-color="#fff"
                data-text-color="black "
                data-arrow-color="#fff"
              ></FaInfoCircle>
              <ReactTooltip place="right" type="info" effect="solid" />
              <br />
              <div className="form-group mx-4">
                <div className="form-row">
                  <select
                    id="location"
                    name="selected_location"
                    className="form-control"
                    onChange={(e) => this.handleChange(e)}
                    required
                    value={this.state.selected_location}
                  >
                    <option value="">Choose...</option>
                    {this.state.location.map((item, index) => {
                      return <option key={index}>{item.value}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="form=group mx-4 mb-4">
                <div className="form-row">
                  {["City", "County", "Region"].indexOf(
                    this.state.selected_location
                  ) > -1 && (
                    <input
                      type="text"
                      id="input-hrs"
                      className="form-control form-control-alternative col-sm-6"
                      placeholder={"Enter " + this.state.selected_location}
                      name="selected_location_value"
                      onChange={(e) => this.handleChange(e)}
                      required
                      value={this.state.selected_location_value}
                    />
                  )}
                  {(this.state.selected_location === "State" ||
                    this.state.selected_location === "City" ||
                    this.state.selected_location === "County") && (
                    <select
                      id="location"
                      name="selected_location_state"
                      className="form-control col-sm-6"
                      onChange={this.handleChange}
                      required
                      value={this.state.selected_location_state}
                    >
                      <option value="">Select State</option>
                      {Object.keys(states).map((key, index) => {
                        return <option key={index}>{states[key]}</option>;
                      })}
                    </select>
                  )}
                </div>
              </div>
              <label className="mr-2">Hours to Complete </label>{" "}
              <FaInfoCircle
                data-tip="State the length of time (in hours) for completing the educational offering"
                data-place="right"
                color="blue"
                data-background-color="#fff"
                data-text-color="black "
                data-arrow-color="#fff"
              ></FaInfoCircle>
              <ReactTooltip place="right" type="info" effect="solid" />
              <br />
              <div className="form-group mx-4 ">
                <div className="form-row">
                  <div className="col-12">
                    <input
                      type="number"
                      id="input-hrs"
                      className="form-control form-control-alternative"
                      placeholder="Length of time in hours"
                      name="hours"
                      onChange={this.handleChange}
                      value={this.state.hours}
                    />
                  </div>
                </div>
              </div>
              <label>Fee for Participation </label>{" "}
              <FaInfoCircle
                data-tip="select if your offering is free, fee-based or tuition-based; if you have options for more than one, select all that apply. If applicable, state the fee in dollars"
                data-place="right"
                color="blue"
                data-background-color="#fff"
                data-text-color="black "
                data-arrow-color="#fff"
              ></FaInfoCircle>
              <ReactTooltip place="right" type="info" effect="solid" />
              <br />
              <div className="form-group mx-4">
                {this.state.fee.map((fee, index) => {
                  return (
                    <div key={index}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={index}
                          value={fee.value}
                          onChange={(e) =>
                            this.handleCheckOnFee(
                              e.target.value,
                              e.target.checked
                            )
                          }
                          checked={fee.isChecked}
                        />
                        <label className="form-check-label" htmlFor={index}>
                          {fee.value}
                        </label>
                      </div>
                    </div>
                  );
                })}
                <div className="form-group">
                  <input
                    type="text"
                    id="input-org"
                    className={
                      this.state.otherFeeTut || this.state.otherFee
                        ? "form-control form-control-alternative"
                        : "d-none"
                    }
                    placeholder="Please specify the Tution or Fee"
                    name="otherFeetxt"
                    value={this.state.otherFeetxt}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  />
                </div>
              </div>
              <label>Completion Acknowledgement </label>{" "}
              <FaInfoCircle
                data-tip="Select the type of completion acknowledgement, for example, certificate of completion, diploma, etc. that participants will receive after participating in the educational resource"
                data-place="right"
                color="blue"
                data-background-color="#fff"
                data-text-color="black "
                data-arrow-color="#fff"
              ></FaInfoCircle>
              <ReactTooltip place="right" type="info" effect="solid" />
              <br />
              <div className="form-group mx-4">
                {this.state.comp_ack.map((ack, index) => {
                  return (
                    <div key={index}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={index}
                          value={ack.value}
                          onChange={(e) =>
                            this.handleCheckOnCompACK(
                              e.target.value,
                              e.target.checked
                            )
                          }
                          checked={ack.isChecked}
                        />
                        <label className="form-check-label" htmlFor={index}>
                          {ack.value}
                        </label>
                      </div>
                    </div>
                  );
                })}
                {this.state.otherACK && (
                  <div className="form-group">
                    <input
                      type="text"
                      id="input-org"
                      className="form-control form-control-alternative"
                      placeholder="Type other Completion Acknowledgement"
                      name="otherACKtxt"
                      required
                      value={this.state.otherACKtxt}
                      onChange={(e) => {
                        this.handleChange(e);
                        this.handleCheckOnCompACK(
                          e.target.value,
                          e.target.checked
                        );
                      }}
                    />
                  </div>
                )}
              </div>
              <label hmtlfor="input-curricula-title">Website* </label>{" "}
              <FaInfoCircle
                data-tip="Provide the website link with details of educational resource"
                data-place="right"
                color="blue"
                data-background-color="#fff"
                data-text-color="black "
                data-arrow-color="#fff"
              ></FaInfoCircle>
              <ReactTooltip place="right" type="info" effect="solid" />
              <div className="form-group mx-4">
                <input
                  type="text"
                  id="input-curricula-title"
                  className="form-control form-control-alternative"
                  maxLength={1000}
                  name="website"
                  required
                  value={this.state.website}
                  onChange={(e) => this.handleChange(e)}
                />
              </div>
              <label hmtlfor="input-curricula-title">Languages Offered</label>{" "}
              <FaInfoCircle
                data-tip="Provide the Languages offered for the Educational Resources"
                data-place="right"
                color="blue"
                data-background-color="#fff"
                data-text-color="black "
                data-arrow-color="#fff"
              ></FaInfoCircle>
              <ReactTooltip place="right" type="info" effect="solid" />
              <div className="form-group mx-4">
                <input
                  type="text"
                  id="input-curricula-title"
                  className="form-control form-control-alternative"
                  maxLength={255}
                  name="language"
                  required
                  value={this.state.language}
                  onChange={(e) => this.handleChange(e)}
                />
              </div>
              <label>Description about the Educational Resource </label>{" "}
              <FaInfoCircle
                data-tip="Describe your educational resources- limit up to 250 words"
                data-place="right"
                color="blue"
                data-background-color="#fff"
                data-text-color="black "
                data-arrow-color="#fff"
              ></FaInfoCircle>
              <ReactTooltip place="right" type="info" effect="solid" />
              <br />
              <div className="form-group mx-4">
                <textarea
                  type="text"
                  rows="5"
                  id="input-hrs"
                  maxLength={500}
                  className="form-control form-control-alternative"
                  name="desc"
                  value={this.state.desc}
                  onChange={(e) => this.handleChange(e)}
                />
              </div>
              {!this.state.editCurriculaActive && (
                <div className="form-row justify-content-center">
                  <div className="col-3">
                    <button
                      className="btn btn-block btn-primary"
                      data-toggle="modal"
                      data-target="#CurriculaAdded"
                      type="submit"
                    >
                      {" "}
                      Add{" "}
                    </button>
                  </div>
                </div>
              )}
              {this.state.editCurriculaActive && (
                <div className="form-row justify-content-center">
                  <button
                    className="btn btn-block btn-primary col-sm-3 mr-3"
                    type="submit"
                  >
                    {" "}
                    Save{" "}
                  </button>
                </div>
              )}
            </form>
          </div>
        )}
        {!this.props.userName && this.props.navigateToLogin()}
      </div>
    );
  }
}

export default NewEducationalResource;
