import React, { useEffect, Component } from "react";
// import AwesomeSlider from "react-awesome-slider";
// import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "leaflet/dist/leaflet.css";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  LayersControl,
  } from "react-leaflet";
import Axios from "axios";
import { divIcon } from "leaflet";
import L from "leaflet";
// import { Link } from "@reach/router";
import "leaflet-geosearch/dist/geosearch.css";
// import { GeoSearchControl, GoogleProvider } from "leaflet-geosearch";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { renderToStaticMarkup } from "react-dom/server";

// function LeafletgeoSearch() {
//   const map = useMap();
//   useEffect(() => {
//     const provider = new GoogleProvider({
//       params: {
//         key: "AIzaSyBILXbE28XInB6iIU-y3vzbIo1jvUr0Zo0",
//       },
//     });

//     const searchControl = new GeoSearchControl({
//       provider,
//     });

//     map.addControl(searchControl);

//     return () => map.removeControl(searchControl);
//   }, []);

//   return null;
// }

function Legend() {
  const map = useMap();
  useEffect(() => {
    const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");
      div.innerHTML =
        '<span><i class="far fa-user"></i><span class ="legend_color">  National Practitioner</span></br>' +
        '<i class="far fa-book-open"></i> <span class ="legend_color">  National Educational Resource</span></br>' +
        '<i class="fas fa-user-alt"></i> <span class ="legend_color"> State/Local Practitioner</span></br>' +
        '<i class="fas fa-book-open"></i> <span class ="legend_color"> State/Local Educational Resource</span></span>';
      return div;
    };

    legend.addTo(map);
  }, []);

  return null;
}
const RecenterAutomatically = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng], 7);
  }, [lat, lng]);
  return null;
};

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      eduMarkers: [],
      lat: null,
      lng: null,
    };
  }
  updateDimensions() {
    const height = window.innerWidth >= 992 ? window.innerHeight : 400
    this.setState({ height: height })
  }

    componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }
  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions.bind(this))
    var query = new URLSearchParams(this.props.location.search);
    var mapArea = query.get("Id");
    if (mapArea == "Iowa") {
      console.log(mapArea);
      // map.setView([41.59,-91.6], 7);
      this.setState({ lat: 41.90608485410003, lng: -93.62886581819892 });
    }
    Axios.get("https://foodsystemsdb.extension.iastate.edu/api/allGeoLoc").then(
      (response) => {
        if (response.data.length > 0) {
          this.setState({ markers: response.data });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );

    Axios.get("https://foodsystemsdb.extension.iastate.edu/api/allEduGeoLoc").then(
      (response) => {
        if (response.data.length > 0) {
          this.setState({ eduMarkers: response.data });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );

    Axios.get("files/us-counties.json").then((response) => {
      this.setState({ us_counties: response.data });
    });
  }
  // ChangeView(){
  //   const map = useMap();
  //   var query = new URLSearchParams(this.props.location.search);
  //   var mapArea = query.get("Id");
  //   if(mapArea == "Iowa"){
  //     console.log(mapArea);
  //     map.setView([41.59,-91.6], 7);
  //   }
  //   //map.setView(center, zoom);
  //   return null;
  // }

  render() {
    const practitionerIconMarkup = renderToStaticMarkup(
      <i className="fas fa-user-alt fa-2x" />
    );
    const practitionerMarkerIcon = divIcon({
      html: practitionerIconMarkup,
    });

    const eduIconMarkup = renderToStaticMarkup(
      <i className="fas fa-book-open fa-2x" />
    );
    const eduMarkerIcon = divIcon({
      html: eduIconMarkup,
    });

    const nationalPractitionerIconMarkup = renderToStaticMarkup(
      <i className="far fa-user fa-2x" />
    );
    const nationalPractitionerMarkerIcon = divIcon({
      html: nationalPractitionerIconMarkup,
    });

    const nationalEduIconMarkup = renderToStaticMarkup(
      <i className="far fa-book-open fa-2x" />
    );
    const nationalEduMarkerIcon = divIcon({
      html: nationalEduIconMarkup,
    });

    return (
      <div className="bg-light justify-content-center align-items-center height4Map">
        <MapContainer center={[37.09, -95.71]} zoom={5} scrollWheelZoom={false}>
          <Legend />
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="OpenStreetMap">
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Aerial Imagery">
              <TileLayer
                attribution='Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a> | Yellow and green circles on the map represent a cluster of points. Green indicates less than 10 points, and yellow indicates more than 10.'
                url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}"
              />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay checked name="Practitioner">
          <MarkerClusterGroup>
            {this.state.markers.map((ven, index) => (
              <Marker
                key={index}
                position={JSON.parse(ven.geojson).coordinates}
                icon={
                  ven.area_of_work == "National"
                    ? nationalPractitionerMarkerIcon
                    : practitionerMarkerIcon
                }
              >
                <Popup>
                  <div className="poup-text">
                    <a href={"/profileSearch?Id=" + ven.profileId} target={"_blank"} rel="noopener noreferrer">{ven.name}</a>
                    {/* <Link target={"_blank"} to={"/profileSearch?Id=" + ven.profileId} > */}
                      {/* {ven.name}
                    </Link> */}
                  </div>
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay checked name="Educational Resource">
          <MarkerClusterGroup>
            {this.state.eduMarkers.map((ven, index) => (
              <Marker
                key={index}
                position={JSON.parse(ven.geojson).coordinates}
                icon={
                  ven.location == "National"
                    ? nationalEduMarkerIcon
                    : eduMarkerIcon
                }
              >
                <Popup>
                  <div className="poup-text">
                    {/* <Link target="_blank" rel="noopener noreferrer" to={"/eduResourceSearch?Id=" + ven.curricula_id} >
                      {ven.curricula_title}
                    </Link> */}
                    <a target="_blank" rel="noopener noreferrer" href={"/eduResourceSearch?Id=" + ven.curricula_id}>{ven.curricula_title}</a>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
          </LayersControl.Overlay>
          </LayersControl>
          {/* <LeafletgeoSearch /> */}
          <RecenterAutomatically lat={this.state.lat} lng={this.state.lng} />
        </MapContainer>
      </div>
    );
  }
}

export default Map;
