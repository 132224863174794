import React, { Component } from "react";

class Competencies extends Component {
  render() {
    return (
      <div className="container-fluid mnHeight">
        <div className="row justify-content-center align-items-center bg-colorPantone p-4">
          <h1 className="text-dark">Food Systems Core Competencies </h1>
        </div>
        <div className="container py-5 mb-5 justify-content-center">
          <p>
            This database is developed based on core competencies for
            practitioners in food systems. In 2019, with support from{" "}
            <a href="https://www.ams.usda.gov/" target="_blank" rel="noopener noreferrer"> USDA Agricultural Marketing Service,</a>{" "}
            a team of over 30 individuals across the nation identified and
            discussed each competency, leading to nine categories, 41 themes, and 142 competencies with three levels of learning that include example learning objectives for each Level 1: Knowing, Level 2: Doing, and Level 3: Teaching.
            For more information on project objective, methodology and findings, view the <a href="https://www.extension.iastate.edu/ffed/files/documents/2020_CoreCompetencyFinalReport2.pdf" target="_blank" rel="noopener noreferrer">Food System Core Competency Project </a> final report. 
            
            {" "}
          </p>
          <p>
            The framework for developing learning objectives for each competency
            is displayed within the diagram below. This starts with building
            awareness, increasing knowledge, practicing, and then teaching or
            mentoring (<a href="https://www.mccc.edu/~lyncha/documents/stagesofcompetence.pdf" target="_blank" rel="noopener noreferrer">Four Stages of Learning</a>). Another way to think about it is moving from a beginner, to becoming proficient and then becoming the expert, or moving from unconscious incompetence to unconscious competence as shown below. This also sheds
            light on the need for lifelong learning. Not all educational
            resources will teach or get an individual to “level 3” or expertise,
            rather to master something, that involves continued practice,
            mentorship, connections, and lifelong professional development.
            <img src="images/compPage.JPG" width="100%" alt="competencies and learning" />
          </p>
          {/* <p>
            For more information on competencies and learning objectives, please{" "}
            <a
              href="https://www.extension.iastate.edu/ffed/wp-content/uploads/2019_Example-Core-Competency-Learning-Objectives.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              visit here.
            </a>
          </p>
          <p>
          For more information on the Four Stages of Competence, please{" "} 
          <a
              href="https://www.mccc.edu/~lyncha/documents/stagesofcompetence.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              visit here.
            </a>{" "}  
          </p>
          <p>
            For a final report on the project, please{" "}
            <a
              href="https://www.extension.iastate.edu/ffed/wp-content/uploads/2020_CoreCompetencyFinalReport2.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              visit here.
            </a>{" "}
          </p> */}
          {/* <p>
          Each of the nine categories are described below. The additional 41 themes and 142 competencies with levels of learning can be found in the downloadable matrix at the bottom of the page.
          </p> */}
          <h2><b>Categories</b></h2>
          <p>This research led to the identification of nine categories and an additional 41 themes and 142 competencies with three levels of learning. </p>
          <h5>Food Systems:</h5>{" "}
          <p className="pb-2 ml-3">
            {" "}
            Sum of actors and interactions along and within the food value chain
            and community from production, fishing and harvesting, to
            transportation and processing, to consumption and resource
            management. Food Systems encompass a broad range of technical
            skills, community action, and environmental considerations across
            value chain development, production and harvesting techniques,
            consumption patterns and general understanding of markets and
            opportunities across the system.
          </p>
          <h5>Equity:</h5>{" "}
          <p className="pb-2 ml-3">
            {" "}
            Quality of a community of individual of being impartial and fair and
            is a structural and systemic concept across all work. It includes
            aspects of cultural and self-awareness, historical acknowledgement
            and appreciation, identification of power and privilege in society,
            and inclusion for marginalized populations.
          </p>
          <h5>Community Capacity:</h5>{" "}
          <p className="pb-2 ml-3">
            {" "}
            Interaction between people and organizations to develop social
            structures that solve complex problems in community. This includes
            building trust and relationships across and within existing
            networks, strategic planning and facilitation, and the ability to
            identify existing resources and limitations.{" "}
          </p>
          <h5>Economy and Business Analysis:</h5>{" "}
          <p className="pb-2 ml-3">
            {" "}
            Quantifying and evaluating the economy based on finical
            considerations and economic conditions, including business plans and
            structures, finance and funding options, market channels and plans,
            and economic frameworks.{" "}
          </p>
          <h5>Governance and Policy:</h5>{" "}
          <p className="pb-2 ml-3">
            {" "}
            Local, state and federal control systems as well as laws or policies
            put in place have considerable impact on the ability for
            individuals, organizations and communities to act. This includes
            understanding existing policies and the policy makers involved,
            organizing policy change, and identifying regulations and licensing
            standards.{" "}
          </p>
          <h5>Public Health and Wellness:</h5>{" "}
          <p className="pb-2 ml-3">
            {" "}
            Public health promotes the health of all people within communities
            and is directly tied to where we live, play and work. This involves
            social determinants of health based on population and context,
            personal health, and the ability to identify food access
            opportunities within community.{" "}
          </p>
          <h5>Environment:</h5>{" "}
          <p className="pb-2 ml-3">
            {" "}
            Surrounding conditions in which we live make up our environment,
            from the natural and built world. This involves planning for new
            development, assessing the agroecological systems in place including
            climate and resilience for our natural and built environments.{" "}
          </p>
          <h5>Leadership:</h5>
          <p className="pb-2 ml-3">
            {" "}
            The ability to motivate a group of people to achieve a common goal
            and includes the ability to understand personal leadership styles,
            communication and personality types, and teamwork.{" "}
          </p>
          <h5>Evaluation:</h5>{" "}
          <p className="pb-2 ml-3">
            {" "}
            Making a judgement of assessment of a project, program or system.
            This includes understanding bias and overall goals for a project;
            identifying, gathering and analyzing data; and the ability to create
            and execute evaluation plans.{" "}
          </p>
          <h2><b>
            {/* <a
              href="files/Final Learning Competency and Learning Objective Table_Database.xlsx"
              target="_blank"
              rel="noopener noreferrer"
            > */}
              Competencies and learning objective matrix{" "}
            {/* </a> */}</b>
          </h2>
          <p className="ml-3">
          This downloadable matrix shares the categories, themes, and competencies, including example learning objectives for each of the levels of learning. The matrix is available to review prior to adding educational resources to your profile. Microsoft Word and Adobe PDF have integrated checkboxes to mark competencies as you work through the document. {" "}
          </p> 
          <p><b>Download as:</b></p>
          <a
              href="files/Final Learning Competency and Learning Objective Table_Database.xlsx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Microsoft Excel {" "}
            </a>
          <br />
          <a
            href="files/Downloadable Learning Objective workbook_restricted.docx"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Word
          </a>{" "}
          <br />
          <a href="files/Downloadable Checklist_ Learning Objective workbook_restricted.pdf" target="_blank" rel="noopener noreferrer"> Adobe PDF </a>{" "}
          
          <div className="mb-3 mt-4 h-100">
            <iframe
              src="https://docs.google.com/spreadsheets/d/1XnwiDonfgNr_glItuPBS7o17do4Q1P3p/edit?usp=share_link&ouid=103169856810781554303&rtpof=true&sd=true"
              // src="https://view.officeapps.live.com/op/embed.aspx?src=https%3A%2F%2Fwww.extension.iastate.edu%2Fffed%2Fwp-content%2Fuploads%2F2021-Final-Learning-Competency-and-Learning-Objective-Table_Database_read-only-1.xlsx"
              title="title"
              className="w-100 heightForPDF"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Competencies;
