import React, { Component } from "react";
import { Router, navigate,globalHistory } from "@reach/router";
import Axios from "axios";
import Home from "./Home";
import Footer from "./Footer";
import Navigation from "./Navigation";
import Login from "./Login";
import Register from "./Register";
import Search from "./Search";
import Competencies from "./Competencies";
import About from "./About";
import Profile from "./Profile";
import FAQ from "./FAQ";
import NewEducationalResource from "./NewEducationalResource";
import ChangePass from "./ChangePass";
import MyEducationalResource from "./MyEducationalResource";
import EduResourceSearch from "./EduResourceSearch";
import ProfileSearch from "./ProfileSearch";
import ForgotPass from "./ForgotPass";
import Reset from "./Reset";
import Map from "./Map";
import ReactGA from "react-ga4";

ReactGA.initialize('G-JFZMRQNB6R');
Axios.defaults.withCredentials = true;

globalHistory.listen(() => {
  // ReactGA.set({ page: location.pathname });
  // ReactGA.send(location.pathname);
  ReactGA.send({hitType:"pageview", page:window.location.pathname + window.location.search, title: window.location.pathname + window.location.search });
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      profileId: null,
      displayName: null,
      email: null,
      formDisplay: false,
      enableProfileEdit: false,
      orgList: [],
      categories: [],
      sub_categories: {},
      ss_categories: {},
      profCategories: [],
      profSubCategories: {},
    };
    this.toggleAdvForm = this.toggleAdvForm.bind(this);
    this.enableProfileForm = this.enableProfileForm.bind(this);
    this.updateOrgList = this.updateOrgList.bind(this);
    this.getAllCategories = this.getAllCategories.bind(this);
    this.getProfileCategories = this.getProfileCategories.bind(this);
    this.updateName = this.updateName.bind(this);
    this.navigateToLogin = this.navigateToLogin.bind(this);
    this.populateSScategoryState = this.populateSScategoryState.bind(this);
    this.populateSubCategoryState = this.populateSubCategoryState.bind(this);
  }

  componentDidMount() {
    Axios.get("https://foodsystemsdb.extension.iastate.edu/api/isUserAuth", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.loggedIn) {
        this.setState({
          displayName: response.data.user[0].name,
          email: response.data.user[0].email,
          profileId: response.data.user[0].profileId,
        });
      } else {
        this.setState({ displayName: null });
      }
    });
    this.updateOrgList();
    this.getAllCategories();
    this.getProfileCategories();
  }

  loginUser = () => {
    Axios.get("https://foodsystemsdb.extension.iastate.edu/api/isUserAuth", {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      if (response.data.loggedIn) {
        this.setState({
          displayName: response.data.user[0].name,
          email: response.data.user[0].email,
          profileId: response.data.user[0].profileId,
        });
      } else {
        this.setState({ displayName: null });
      }
    });
  };

  updateName(name) {
    this.setState({ displayName: name });
  }

  updateOrgList() {
    Axios.get("https://foodsystemsdb.extension.iastate.edu/api/org").then(
      (response) => {
        if (response.data.length > 0) {
          this.setState({ orgList: response.data });
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }

  getAllCategories() {
    Axios.get(
      "https://foodsystemsdb.extension.iastate.edu/api/getCategories"
    ).then((response) => {
      if (response) {
        if (response.data.result.length > 0) {
          this.setState({ categories: response.data.result });
          this.populateSScategoryState(response.data.result);
          this.populateSubCategoryState(response.data.result);
        }
      }
    });
  }

  getProfileCategories() {
    Axios.get(
      "https://foodsystemsdb.extension.iastate.edu/api/getProfileCategories"
    ).then((response) => {
      if (response) {
        if (response.data.result.length > 0) {
          this.setState({ profCategories: response.data.result }, () => {
            this.populateProfileSubCategoryState(response.data.result);
          });
        }
      }
    });
  }

  populateProfileSubCategoryState(categories) {
    var sub_category_list = [];
    categories.forEach((category) => {
      sub_category_list.push(category.sub_category);
    });
    sub_category_list = Array.from(new Set(sub_category_list));
    var sub_category_dict = {};
    sub_category_list.forEach((data) => {
      var dd = { isChecked: false };
      sub_category_dict[data] = dd;
    });
    this.setState({
      profSubCategories: sub_category_dict,
    });
  }

  populateSScategoryState(categories) {
    var ss_category_list = [];
    categories.forEach((category) => {
      ss_category_list.push(category.ss_category);
    });
    ss_category_list = Array.from(new Set(ss_category_list));
    var ss_category_dict = {};
    ss_category_list.forEach((data, index) => {
      var dd = {
        id: data + index,
        isChecked: false,
        level: {
          "Level 1": { id: 1, isChecked: false },
          "Level 2": { id: 2, isChecked: false },
          "Level 3": { id: 3, isChecked: false },
        },
      };
      ss_category_dict[data] = dd;
    });
    this.setState({
      ss_categories: ss_category_dict,
    });
  }

  populateSubCategoryState(categories) {
    var sub_category_list = [];
    categories.forEach((category) => {
      sub_category_list.push(category.sub_category);
    });
    sub_category_list = Array.from(new Set(sub_category_list));
    var sub_category_dict = {};
    sub_category_list.forEach((data) => {
      var dd = { isChecked: false, ss_categories: [] };
      sub_category_dict[data] = dd;
    });
    this.setState({
      sub_categories: sub_category_dict,
    });
  }

  logOutUser = (e) => {
    e.preventDefault();
    this.setState({
      displayName: null,
      email: null,
    });

    localStorage.removeItem("token");
    this.navigateToLogin();
  };

  navigateToLogin() {
    navigate("/login");
  }

  toggleAdvForm() {
    this.setState({
      formDisplay: !this.state.formDisplay,
    });
  }

  enableProfileForm() {
    this.setState({
      enableProfileEdit: !this.state.enableProfileEdit,
    });
  }

  render() {
    return (
      <div>
        {location.pathname !== '/map' &&  <Navigation
          email={this.state.email}
          user={this.state.displayName}
          logOutUser={this.logOutUser}
        /> }
        <Router  primary={false}>
          <Home path="/" user={this.state.displayName} />
          <Search
            path="/search"
            user={this.state.displayName}
            formDisplay={this.state.formDisplay}
            toggleAdvForm={this.toggleAdvForm}
            orgList={this.state.orgList}
            categories={this.state.categories}
          />
          <Competencies path="/competencies" user={this.state.displayName} />
          <About path="/about" user={this.state.displayName} />
          <Profile
            path="/profile"
            userName={this.state.displayName}
            email={this.state.email}
            logOutUser={this.logOutUser}
            enableProfileEdit={this.state.enableProfileEdit}
            enableProfileForm={this.enableProfileForm}
            orgList={this.state.orgList}
            updateOrgList={this.updateOrgList}
            categories={this.state.profCategories}
            sub_categories_dict={this.state.profSubCategories}
            updateName={this.updateName}
            navigateToLogin={this.navigateToLogin}
          />
          <Login path="/login" loginUser={this.loginUser} />
          <Register
            path="/register"
            orgList={this.state.orgList}
            updateOrgList={this.updateOrgList}
            categories={this.state.profCategories}
            sub_categories={this.state.profSubCategories}
          />
          <FAQ path="/FAQ" />

          <ChangePass
            path="/changePass"
            email={this.state.email}
            userName={this.state.displayName}
            navigateToLogin={this.navigateToLogin}
          />

          <NewEducationalResource
            path="/newEducationalResource"
            userName={this.state.displayName}
            email={this.state.email}
            profileId={this.state.profileId}
            organizations={this.state.orgList}
            categories={this.state.categories}
            sub_categories={this.state.sub_categories}
            ss_categories={this.state.ss_categories}
            navigateToLogin={this.navigateToLogin}
            populateSScategoryState={this.populateSScategoryState}
            populateSubCategoryState={this.populateSubCategoryState}
          />
          <MyEducationalResource
            path="/myEducationalResource"
            userName={this.state.displayName}
            profileId={this.state.profileId}
            // email={this.state.email}
            navigateToLogin={this.navigateToLogin}
          />
          <EduResourceSearch
            path="/eduResourceSearch"
            categories={this.state.categories}
            sub_categories={this.state.sub_categories}
            ss_categories={this.state.ss_categories}
          />
          <ProfileSearch
            path="/profileSearch"
            getProfileCat={this.getProfileCategories}
            categories={this.state.profCategories}
            sub_categories_dict={this.state.profSubCategories}
          />
          <ForgotPass path="/forgotPassword" />
          <Reset path="/reset/:id" />
        </Router>
        {location.pathname !== '/map' && <Footer /> }
        
        <Router>
          <Map path="/map" />
        </Router>
      </div>
    );
  }
}
export default App;
