import React, { Component } from "react";
import Axios from "axios";
import { Link } from "@reach/router";

class EduResourceSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      educationResource: [],
      categories: [
        {
          id: 1,
          value: "Food Systems",
          dbValue: "Food_Systems",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 2,
          value: "Equity",
          dbValue: "Equity",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 3,
          value: "Community Capacity",
          dbValue: "Community_Capacity",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 4,
          value: "Economy and Business Analysis",
          dbValue: "Economy_and_Business_Analysis",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 5,
          value: "Governance and Policy",
          dbValue: "Governance_and_Policy",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 6,
          value: "Health and Wellness",
          dbValue: "Health_and_Wellness",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 7,
          value: "Environment",
          dbValue: "Environment",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 8,
          value: "Leadership",
          dbValue: "Leadership",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 9,
          value: "Evaluation",
          dbValue: "Evaluation",
          isChecked: false,
          sub_categories: [],
        },
      ],
      c_ids: {},
      propCategories: this.props.categories,
      propSubcategories: this.props.sub_categories,
      propSSubcategories: this.props.ss_categories,
    };
  }
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const eduId = query.get("Id");
    if (eduId != null) {
      Axios.post(
        "https://foodsystemsdb.extension.iastate.edu/api/educationResourceSearch",
        {
          curricula_id: eduId,
        }
      ).then(
        (response) => {
          if (response.data.length > 0) {
            this.setState(
              { educationResource: response.data, errorMessage: null },
              () => {
                if (this.state.propCategories.length <= 0) {
                  this.getAllCategories(response.data[0].listOfComp);
                } else {
                  this.populateCompetencies(response.data[0].listOfComp);
                }
              }
            );
          }
        },
        (error) => {
          if (error.message !== null) {
            this.setState({ errorMessage: error.message });
          } else {
            this.setState({ errorMessage: null });
          }
        }
      );
    }
  }

  getAllCategories(full_competency) {
    Axios.get(
      "https://foodsystemsdb.extension.iastate.edu/api/getCategories"
    ).then((response) => {
      if (response) {
        if (response.data.result.length > 0) {
          this.setState({ propCategories: response.data.result });
          this.populateSubCategoryState(response.data.result);
          this.populateSScategoryState(response.data.result, full_competency);
        }
      }
    });
  }
  populateSScategoryState(categories, full_competency) {
    var ss_category_list = [];
    categories.forEach((category) => {
      ss_category_list.push(category.ss_category);
    });
    ss_category_list = Array.from(new Set(ss_category_list));
    var ss_category_dict = {};
    ss_category_list.forEach((data, index) => {
      var dd = {
        id: data + index,
        isChecked: false,
        level: {
          "Level 1": { id: 1, isChecked: false },
          "Level 2": { id: 2, isChecked: false },
          "Level 3": { id: 3, isChecked: false },
        },
      };
      ss_category_dict[data] = dd;
    });
    this.setState(
      {
        propSSubcategories: ss_category_dict,
      },
      () => {
        this.populateCompetencies(full_competency);
      }
    );
  }

  populateSubCategoryState(categories) {
    var sub_category_list = [];
    categories.forEach((category) => {
      sub_category_list.push(category.sub_category);
    });
    sub_category_list = Array.from(new Set(sub_category_list));
    var sub_category_dict = {};
    sub_category_list.forEach((data) => {
      var dd = { isChecked: false, ss_categories: [] };
      sub_category_dict[data] = dd;
    });
    this.setState({
      propSubcategories: sub_category_dict,
    });
  }

  populateCompetencies(full_competency) {
    if (full_competency != null && this.state.propCategories.length > 0) {
      var full_competencyList = JSON.parse(full_competency);
      var categories_dict = this.state.categories;
      var categories = this.state.propCategories;
      Object.keys(full_competencyList).forEach((key) => {
        categories_dict.map((item) => {
          if (item.value === key) {
            item.isChecked = true;
            this.handleChecks(true, item);
            return;
          }
        });
        full_competencyList[key].map((comp_id) => {
          var all_cat = categories.filter(
            (item) => item.category_id === comp_id
          );
          //sub_categories_dict[all_cat[0].sub_category].isChecked = true;
          //ss_categories_dict[all_cat[0].ss_category].isChecked = true;
          this.handleChecksSubCat(
            true,
            all_cat[0].category,
            all_cat[0].sub_category
          );
          this.handleChecksSSCat(
            true,
            all_cat[0].category,
            all_cat[0].sub_category,
            all_cat[0].ss_category
          );
          this.handleCheckLevel(true, all_cat[0].ss_category, all_cat[0].level);
          this.getCatDetails(
            true,
            all_cat[0].category,
            all_cat[0].sub_category,
            all_cat[0].ss_category,
            all_cat[0].level
          );
          return;
        });
      });

      this.setState({ categories: categories_dict });
    }
  }
  populateSubCategory(category) {
    var sub_categories = [];
    this.state.propCategories
      .filter((item) => item.category === category)
      .map((filteredItem) => sub_categories.push(filteredItem.sub_category));
    sub_categories = Array.from(new Set(sub_categories));
    return sub_categories;
  }

  handleChecks(checked, checkedCat) {
    var categories = this.state.categories;
    categories.forEach((item) => {
      if (item.id === checkedCat.id) {
        item.isChecked = checked;
        var sub_categories = this.populateSubCategory(item.value);
        if (item.isChecked) {
          item.sub_categories = sub_categories;
          //this.setState({ check_limit: this.state.check_limit + 1 });
        } else {
          //this.setState({ check_limit: this.state.check_limit - 1 });
          var sub_categories_dict = this.state.propSubcategories;
          var ss_categories_dict = this.state.propSSubcategories;
          var ss_categories = {};
          var selected_cat = item.value;
          var selected_sub_cat,
            selected_ss_cat = "";
          sub_categories.forEach((sub_key) => {
            if (sub_categories_dict[sub_key].isChecked) {
              selected_sub_cat = sub_key;
              sub_categories_dict[sub_key].isChecked = false;

              ss_categories = this.populateSSubCategory(
                item.value,
                selected_sub_cat
              );
              ss_categories.forEach((ss_key) => {
                if (ss_categories_dict[ss_key].isChecked) {
                  selected_ss_cat = ss_key;
                  ss_categories_dict[ss_key].isChecked = false;

                  Object.keys(ss_categories_dict[ss_key].level).forEach(
                    (key) => {
                      if (ss_categories_dict[ss_key].level[key].isChecked) {
                        ss_categories_dict[ss_key].level[key].isChecked = false;
                        this.getCatDetails(
                          checked,
                          selected_cat,
                          selected_sub_cat,
                          selected_ss_cat,
                          key
                        );
                      }
                    }
                  );
                }
              });
            }
          });
          this.setState({
            sub_categories: sub_categories_dict,
            ss_categories: ss_categories_dict,
          });
        }
      }
    });
    // if (event.target.checked) {
    //     if (this.state.check_limit >= 2) {
    //         categories.forEach(item => {
    //             if (!item.isChecked) {
    //                 item.disabled = true;
    //             }
    //         })
    //     } else {
    //         this.setState({ check_limit: this.state.check_limit + 1 });
    //     }
    // } else {
    //     if (this.state.check_limit >= 2) {
    //         categories.forEach(item => {
    //             if (item.disabled) {
    //                 item.disabled = false;
    //             }
    //         })
    //     }
    //     this.setState({ check_limit: this.state.check_limit - 1 })
    // }
    this.setState({ categories: categories });
  }

  populateSSubCategory(category, checked_subcategory) {
    var ss_categories = [];
    this.state.propCategories
      .filter(
        (item) =>
          item.category === category &&
          item.sub_category === checked_subcategory
      )
      .map((filteredItem) => ss_categories.push(filteredItem.ss_category));
    ss_categories = Array.from(new Set(ss_categories));
    return ss_categories;
  }

  handleChecksSubCat(checked, category, checkedSubCat) {
    var sub_categories = this.state.propSubcategories;
    sub_categories[checkedSubCat].isChecked = checked;
    var ss_categories = this.populateSSubCategory(category, checkedSubCat);
    if (checked) {
      sub_categories[checkedSubCat].ss_categories = ss_categories;
    } else {
      var ss_categories_dict = this.state.propSSubcategories;
      var selected_sub_cat = checkedSubCat;
      var selected_ss_cat = "";
      ss_categories.forEach((ss_key) => {
        if (ss_categories_dict[ss_key].isChecked) {
          selected_ss_cat = ss_key;
          ss_categories_dict[ss_key].isChecked = false;

          Object.keys(ss_categories_dict[ss_key].level).forEach((key) => {
            if (ss_categories_dict[ss_key].level[key].isChecked) {
              ss_categories_dict[ss_key].level[key].isChecked = false;
              this.getCatDetails(
                checked,
                category,
                selected_sub_cat,
                selected_ss_cat,
                key
              );
            }
          });
        }
      });
      this.setState({ ss_categories: ss_categories_dict });
    }
    this.setState({ sub_categories: sub_categories });
  }

  handleChecksSSCat(checked, category, sub_category, checkedSSCat) {
    var ss_categories = this.state.propSSubcategories;
    ss_categories[checkedSSCat].isChecked = checked;
    if (!checked) {
      Object.keys(ss_categories[checkedSSCat].level).forEach((key) => {
        if (ss_categories[checkedSSCat].level[key].isChecked) {
          ss_categories[checkedSSCat].level[key].isChecked = false;
          this.getCatDetails(
            checked,
            category,
            sub_category,
            checkedSSCat,
            key
          );
        }
      });
    }
    this.setState({ ss_categories: ss_categories });
  }

  handleCheckLevel(checked, ss_category, checkedLevel) {
    var ss_categories = this.state.propSSubcategories;
    ss_categories[ss_category].level[checkedLevel].isChecked = checked;
    this.setState({ ss_categories: ss_category });
  }

  getCatDetails(checked, category, sub_category, ss_category, level) {
    var c_ids = this.state.c_ids;
    var checked_cat = this.state.propCategories.filter(
      (item) =>
        item.category === category &&
        item.sub_category === sub_category &&
        item.ss_category === ss_category &&
        item.level === level
    );
    if (checked) {
      c_ids[checked_cat[0].category_id] = checked_cat[0].category;
    } else {
      delete c_ids[checked_cat[0].category_id];
    }
    this.setState({ c_ids: c_ids });
  }
  getClickableLink = (link) => {
    if (link != null) {
      return link.startsWith("http://") || link.startsWith("https://")
        ? link
        : `http://${link}`;
    }
  };

  render() {
    return (
      <div className="container mb-5 pb-5 mnHeight">
        {this.state.educationResource.map((item) => (
          <section className="my-3" key={item.curricula_id}>
            <h1 className="text-center mb-4">{item.curricula_title}</h1>
            <div id="eduContainer">
              <h4>Organization:</h4> <p>{item.org_name}</p>
              <br />
              <h4>Contact:</h4>{" "}
              <p>
                <Link to={"/profileSearch?Id=" + item.profileId}>
                  {item.name};
                </Link>{" "}
                <a href={"mailto:" + item.email}>{item.email}</a>
              </p>
              <br />
              <h4>Description:</h4> <p>{item.description}</p>
              <br />
              <h4>Fee:</h4> <p>{item.full_fee}</p>
              <br />
              <h4>Delivery Method:</h4> <p>{item.method_of_delivery}</p>
              <br />
              <h4>Hours:</h4> <p>{item.hours}</p>
              <br />
              <h4>Certification:</h4> <p>{item.completion}</p>
              <br />
              <h4>Audience:</h4> <p>{item.audience}</p>
              <br />
              <h4>Location:</h4> <p>{item.location_desc}</p>
              <br />
              <h4>Language Offered:</h4> <p>{item.languageOffered}</p>
              <br />
              <h4>Website:</h4>{" "}
              <p>
                <a href={this.getClickableLink(item.website)}>{item.website}</a>
              </p>
              <br />
            </div>
            <h4 className="mr-2">
              <a href="/competencies" target="_blank">
                Competencies:
              </a>{" "}
            </h4>
            <div className="form-group mx-4">
              {this.state.categories.map((category, index) => {
                return (
                  <div key={index}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value={category.value}
                        onChange={(e) =>
                          this.handleChecks(e.target.checked, category)
                        }
                        disabled
                        checked={category.isChecked}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox1"
                      >
                        {category.value}
                      </label>
                    </div>
                    {category.isChecked && (
                      <div className="mx-5">
                        {category.sub_categories.map((sub_category, index) => {
                          return (
                            <div key={index}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox2"
                                  value={sub_category}
                                  onChange={(e) =>
                                    this.handleChecksSubCat(
                                      e.target.checked,
                                      category.value,
                                      sub_category
                                    )
                                  }
                                  disabled
                                  checked={
                                    this.state.propSubcategories[sub_category]
                                      .isChecked
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineCheckbox2"
                                >
                                  {sub_category}
                                </label>
                              </div>
                              {this.state.propSubcategories[sub_category]
                                .isChecked && (
                                <div className="mx-5">
                                  {this.state.propSubcategories[
                                    sub_category
                                  ].ss_categories.map((ss_category, index) => {
                                    return (
                                      <div key={index}>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="inlineCheckbox3"
                                            value={ss_category}
                                            onChange={(e) =>
                                              this.handleChecksSSCat(
                                                e.target.checked,
                                                category.value,
                                                sub_category,
                                                ss_category
                                              )
                                            }
                                            disabled
                                            checked={
                                              this.state.propSSubcategories[
                                                ss_category
                                              ].isChecked
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="inlineCheckbox3"
                                          >
                                            {ss_category}
                                          </label>
                                        </div>
                                        {this.state.propSSubcategories[
                                          ss_category
                                        ].isChecked && (
                                          <div className="mx-5">
                                            {Object.keys(
                                              this.state.propSSubcategories[
                                                ss_category
                                              ].level
                                            ).map((level, index) => {
                                              return (
                                                <div
                                                  className="form-check"
                                                  key={index}
                                                >
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="inlineCheckbox4"
                                                    value={level}
                                                    checked={
                                                      this.state
                                                        .propSSubcategories[
                                                        ss_category
                                                      ].level[level].isChecked
                                                    }
                                                    onChange={(e) => {
                                                      this.handleCheckLevel(
                                                        e.target.checked,
                                                        ss_category,
                                                        level
                                                      );
                                                      this.getCatDetails(
                                                        e.target.checked,
                                                        category.value,
                                                        sub_category,
                                                        ss_category,
                                                        level
                                                      );
                                                    }}
                                                    disabled
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="inlineCheckbox4"
                                                  >
                                                    {level}
                                                  </label>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </section>
        ))}
      </div>
    );
  }
}

export default EduResourceSearch;
