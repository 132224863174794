import React, { Component } from "react";
import { Link, navigate } from "@reach/router";
import FormError from "./FormError";
import FormSuccess from "./FormSuccess";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      email: this.props.email,
      position:"",
      Affliations: "",
      Sub_Affliations: "",
      full_competency: null,
      pronouns: "",
      work_location: "",
      work_area: "",
      bio: "",
      website: "",
      tempUser: null,
      tempAffliations: null,
      tempSub_Affliations: null,
      tempFull_competency: null,
      categories: [
        {
          id: 1,
          value: "Food Systems",
          dbValue: "Food_Systems",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 2,
          value: "Equity",
          dbValue: "Equity",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 3,
          value: "Community Capacity",
          dbValue: "Community_Capacity",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 4,
          value: "Economy and Business Analysis",
          dbValue: "Economy_and_Business_Analysis",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 5,
          value: "Governance and Policy",
          dbValue: "Governance_and_Policy",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 6,
          value: "Health and Wellness",
          dbValue: "Health_and_Wellness",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 7,
          value: "Environment",
          dbValue: "Environment",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 8,
          value: "Leadership",
          dbValue: "Leadership",
          isChecked: false,
          sub_categories: [],
        },
        {
          id: 9,
          value: "Evaluation",
          dbValue: "Evaluation",
          isChecked: false,
          sub_categories: [],
        },
      ],
      tempWork_location: null,
      tempWork_area: null,
      tempBio: null,
      tempWebsite: null,
      errorMessage: null,
      successMessage: null,
      sub_category: [],
      enableOtherOrg: false,
      enableOtherSubOrg: false,
      orgOneOther: "",
      orgTwoOther: "",
      c_ids: {},
      sub_categoriesLocdict: null,
      //additionalInfo: null,
      urlAdd1: null,
      urlAdd2: null,
      urlAdd3: null,
      urlAdd4: null,
      urlAdd5: null,
      urlAddText1: null,
      urlAddText2: null,
      urlAddText3: null,
      urlAddText4: null,
      urlAddText5: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.selectSubCategories = this.selectSubCategories.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.newEducationalResource = this.newEducationalResource.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.enableOrgOtherText = this.enableOrgOtherText.bind(this);
    // this.enableOrgOtherSubText = this.enableOrgOtherSubText.bind(this);
    this.handleSecondaryAffChange = this.handleSecondaryAffChange.bind(this);

  }
  componentDidMount() {
    this.getProfile();
  }

  getProfile() {
    Axios.post("https://foodsystemsdb.extension.iastate.edu/api/getprofile", {
      email: this.state.email,
    }).then(
      (response) => {
        if (response.data.length > 0) {
          this.setState(
            {
              user: response.data[0].name,
              Affliations: response.data[0].aff1,
              Sub_Affliations: response.data[0].aff2,
              full_competency: response.data[0].full_competency,
              pronouns: response.data[0].pronouns,
              position:response.data[0].position,
              work_location: response.data[0].work_location,
              work_area: response.data[0].area_of_work,
              bio: response.data[0].bio,
              website: response.data[0].website,
              // additionalInfo: response.data[0].additionalInfo,
              urlAdd1: response.data[0].urlAdd1,
              urlAdd2: response.data[0].urlAdd2,
              urlAdd3: response.data[0].urlAdd3,
              urlAdd4: response.data[0].urlAdd4,
              urlAdd5: response.data[0].urlAdd5,
              urlAddText1: response.data[0].urlAddText1,
              urlAddText2: response.data[0].urlAddText2,
              urlAddText3: response.data[0].urlAddText3,
              urlAddText4: response.data[0].urlAddText4,
              urlAddText5: response.data[0].urlAddText5,
            },
            () => {
              this.populateProfileCompetency();
            }
          );
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }

  populateProfileCompetency() {
    var categories_dict = this.state.categories;
    var categories = this.props.categories;
    var sub_categories_dict = this.props.sub_categories_dict;
    if (
      (this.state.full_competency != null) &
      (this.state.full_competency != "")
    ) {
      var full_competency = JSON.parse(this.state.full_competency);
      Object.keys(full_competency).forEach((key) => {
        categories_dict.map((item) => {
          if (item.value === key) {
            item.isChecked = true;
            this.handleChecks(true, item.value);
            return;
          }
          full_competency[key].map((comp_id) => {
            var sub_cat = categories.filter(
              (cat) => cat.category_id === comp_id
            )[0];
            sub_categories_dict[sub_cat.sub_category].isChecked = true;
            return;
          });
        });
      });

      var c_ids = {};
      Object.keys(full_competency).forEach((key) => {
        full_competency[key].forEach((item) => {
          c_ids[item] = key;
        });
      });   
      this.setState({
        categories: categories_dict,
        sub_categoriesLocdict: sub_categories_dict,
        c_ids: c_ids,
      });
    }
  }

  handleSecondaryAffChange = (e) => {
    var str = "";
    for(var i = 0; i < e.length;i++){
      if(e[i].value != "other"){
        str += e[i].value + ";";
      }
    }
    str = str.slice(0, str.length -1);
    this.setState({ tempSub_Affliations: str });

    if (e.length>0 && e.some(function(o){return o["value"] === "other";})) {
      this.setState({ enableOtherSubOrg: true});
    } else {
      this.setState({ enableOtherSubOrg: false});
    }
  };

  // enableOrgOtherSubText(e) {
  //   e.preventDefault();
  //   if (e.target.value === "OtherSub") {
  //     this.setState({ enableOtherSubOrg: true });
  //   } else {
  //     this.setState({ enableOtherSubOrg: false });
  //   }
  // }
  enableOrgOtherText(e) {
    e.preventDefault();
    if (e.target.value === "Other") {
      this.setState({ enableOtherOrg: true });
    } else {
      this.setState({ enableOtherOrg: false });
    }
  }

  handleChange(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;
    this.setState({ [itemName]: itemValue });
  }

  newEducationalResource(e) {
    e.preventDefault();
    navigate("/newEducationalResource");
  }

  changePassword(e) {
    e.preventDefault();
    navigate("/changePass");
  }

  selectSubCategories(coreCatValue) {
    var sub_categories = [];
    this.props.categories
      .filter((item) => item.category === coreCatValue)
      .map((filteredItem) => sub_categories.push(filteredItem.sub_category));
    sub_categories = Array.from(new Set(sub_categories));
    this.setState({
      sub_category: sub_categories,
    });
  }

  updateProfile(e) {
    e.preventDefault();
    var c_ids = this.state.c_ids;

    if (!Object.keys(c_ids).length) {
      alert("Please Select atleast on Sub Competency in Comptencies");
      return;
    }

    var comp_ids = {};
    Object.keys(c_ids).forEach((key) => {
      if (c_ids[key] in comp_ids) {
        comp_ids[c_ids[key]].push(key);
      } else {
        comp_ids[c_ids[key]] = [key];
      }
    });

    Axios.post(
      "https://foodsystemsdb.extension.iastate.edu/api/updateProfile",
      {
        email: this.props.email,
        user: this.state.tempUser,
        position: this.state.position,
        Affliations: this.state.tempAffliations,
        Sub_Affliations: this.state.tempSub_Affliations,
        full_competency: comp_ids,
        oldFull_competency: JSON.parse(this.state.full_competency),
        pronouns: this.state.tempPronouns,
        work_location: this.state.tempWork_location,
        work_area: this.state.tempWork_area,
        bio: this.state.tempBio,
        website: this.state.tempWebsite,
        otherOrg: this.state.enableOtherOrg,
        otherSubOrg: this.state.enableOtherSubOrg,
        orgOneOther: this.state.orgOneOther,
        orgTwoOther: this.state.orgTwoOther,
        urlAdd1: this.state.urlAdd1,
        urlAdd2: this.state.urlAdd2,
        urlAdd3: this.state.urlAdd3,
        urlAdd4: this.state.urlAdd4,
        urlAdd5: this.state.urlAdd5,
        urlAddText1: this.state.urlAddText1,
        urlAddText2: this.state.urlAddText2,
        urlAddText3: this.state.urlAddText3,
        urlAddText4: this.state.urlAddText4,
        urlAddText5: this.state.urlAddText5,
        // additionalInfo: this.state.additionalInfo,
      }
    ).then(
      (response) => {
        if (response.data.message !== "UserUpdated") {
          this.setState({ errorMessage: response.data.message });
        } else {
          this.setState({ successMessage: "User Update Successful!", Sub_Affliations:this.state.tempSub_Affliations });
          if (this.state.tempUser != null) {
            this.props.updateName(this.state.tempUser);
          }
          this.getProfile();
          this.props.enableProfileForm();
          this.props.updateOrgList();
        }
      },
      (error) => {
        if (error.message !== null) {
          this.setState({ errorMessage: error.message });
        } else {
          this.setState({ errorMessage: null });
        }
      }
    );
  }

  populateSubCategory(category) {
    var sub_categories = [];
    this.props.categories
      .filter((item) => item.category === category)
      .map((filteredItem) => sub_categories.push(filteredItem.sub_category));
    sub_categories = Array.from(new Set(sub_categories));
    return sub_categories;
  }

  handleChecks(checked, checkedCat) {
    var categories = this.state.categories;
    categories.forEach((item) => {
      if (item.value === checkedCat) {
        item.isChecked = checked;
        var sub_categories = this.populateSubCategory(item.value);
        if (item.isChecked) {
          item.sub_categories = sub_categories;
        } else {
          var sub_categories_dict = this.state.sub_categoriesLocdict;
          var selected_sub_cat = "";
          sub_categories.forEach((sub_key) => {
            if (sub_categories_dict[sub_key].isChecked) {
              selected_sub_cat = sub_key;
              sub_categories_dict[sub_key].isChecked = false;
              this.getCatDetails(checked, checkedCat, selected_sub_cat);
            }
          });
        }
      }
    });
    this.setState({ categories: categories });
  }

  getCatDetails(checked, category, sub_category) {
    var c_ids = this.state.c_ids;
    var sub_categories_dict = this.state.sub_categoriesLocdict;

    var checked_cat = this.props.categories.filter(
      (item) => item.category === category && item.sub_category === sub_category
    );

    if (checked) {
      sub_categories_dict[sub_category].isChecked = true;
      c_ids[checked_cat[0].category_id] = checked_cat[0].category;
    } else {
      sub_categories_dict[sub_category].isChecked = false;
      delete c_ids[checked_cat[0].category_id];
    }
    console.log(c_ids);
    this.setState({ c_ids: c_ids, sub_categoriesLocdict: sub_categories_dict });
  }

  render() {
    const { logOutUser } = this.props;
    var orgDropDown = this.props.orgList.map((v, i) => (
      <option key={i} value={v.org_name}>
        {v.org_name}
      </option>
    ));

    var multiOrgDropDown = this.props.orgList.map(data => ({ label: data.org_name, value: data.org_name }));
    multiOrgDropDown.push({ value: "other", label: "other" })
    var secAffDefaultDD=new Array();
    var temp = this.state.Sub_Affliations.split(";");
    temp.forEach(element => {
      secAffDefaultDD.push({ label: element, value: element });
    });
    return (
      <div>
        {this.props.email && (
          <div className="container mb-5 pb-5 mnHeight">
            <div className="text-center mt-4">
              <span className="text-secondary font-weight-bold pl-1">
                Welcome {this.state.user}
              </span>
              ,
              <Link
                to="/login"
                className="font-weight-bold text-primary pl-1"
                onClick={(e) => logOutUser(e)}
              >
                log out
              </Link>
            </div>

            <div
              className="mt-4 bg-light border"
              style={{ borderWidth: "thick" }}
            >
              <form className="p-4">
                {!this.props.enableProfileEdit && (
                  <div className="text-right">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={this.props.enableProfileForm}
                    >
                      Edit
                    </button>
                  </div>
                )}
                {this.props.enableProfileEdit && (
                  <div className="text-right">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={(e) => {
                        this.updateProfile(e);
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-sm btn-primary ml-1"
                      onClick={() => {
                        this.getProfile();
                        this.props.enableProfileForm();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}

                {this.state.errorMessage !== null ? (
                  <FormError theMessage={this.state.errorMessage} />
                ) : null}

                {this.state.successMessage !== null ? (
                  <FormSuccess theMessage={this.state.successMessage} />
                ) : null}
                {/* FULL NAME AND EMAIL */}
                <div className="form-group">
                  <label hmtlfor="full-name">Full Name*</label>
                  <input
                    type="text"
                    id="full-name"
                    name="tempUser"
                    className={
                      this.props.enableProfileEdit
                        ? "form-control"
                        : "form-control disableProfile"
                    }
                    defaultValue={this.state.user}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label hmtlfor="input-email">Email address*</label>{" "}
                  <input
                    type="email"
                    id="input-email"
                    className="form-control disableProfile"
                    defaultValue={this.props.email}
                  />
                </div>
                <div className="form-group">
                  <label hmtlfor="Pronouns">Pronouns </label>{" "}
                  <FaInfoCircle
                    data-tip="State preferred pronouns, ex. she, her, hers"
                    data-place="right"
                    color="blue"
                    data-background-color="#fff"
                    data-text-color="black "
                    data-arrow-color="#fff"
                  ></FaInfoCircle>
                  <ReactTooltip place="right" type="info" effect="solid" />
                  <input
                    type="text"
                    id="pronouns"
                    name="tempPronouns"
                    className={
                      this.props.enableProfileEdit
                        ? "form-control"
                        : "form-control disableProfile"
                    }
                    defaultValue={this.state.pronouns}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label hmtlfor="Position">Position Title </label>{" "}
                  <input
                    type="text"
                    id="position"
                    name="position"
                    className={
                      this.props.enableProfileEdit
                        ? "form-control"
                        : "form-control disableProfile"
                    }
                    defaultValue={this.state.position}
                    onChange={this.handleChange}
                  />
                </div>
                {/*Area for   Affliation and competency DropDown*/}
                {!this.props.enableProfileEdit && (
                  <section>
                    <div className="form-group">
                      <label hmtlfor="input-aff1">Primary Organization*</label>
                      <input
                        type="text"
                        id="input-aff1"
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        defaultValue={this.state.Affliations}
                      />
                    </div>
                    <div className="form-group">
                      <label>Secondary Affliation</label>
                      <input
                        type="text"
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        defaultValue={this.state.Sub_Affliations}
                      />
                    </div>
                    <div className="form-group">
                      <label>Website*</label>
                      <input
                        type="text"
                        name="tempWebsite"
                        maxLength={150}
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        defaultValue={this.state.website}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label hmtlfor="input-subAff">
                        <a href="/competencies" target="_blank">
                          Competencies*
                        </a>
                      </label>
                      <br />
                      <div className="form-group mx-4">
                        {this.state.categories.map((category, index) => {
                          return (
                            <div key={index}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="inlineCheckbox1"
                                  value={category.value}
                                  checked={category.isChecked}
                                  disabled
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineCheckbox1"
                                >
                                  {category.value}
                                </label>
                              </div>
                              {category.isChecked && (
                                <div className="mx-5">
                                  {category.sub_categories.map(
                                    (sub_category, index) => {
                                      return (
                                        <div key={index}>
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="inlineCheckbox2"
                                              value={sub_category}
                                              checked={
                                                this.state
                                                  .sub_categoriesLocdict[
                                                  sub_category
                                                ].isChecked
                                              }
                                              disabled
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="inlineCheckbox2"
                                            >
                                              {sub_category}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </section>
                )}
                {this.props.enableProfileEdit && (
                  <section>
                    <div className="form-group">
                      <label>Primary Organization* </label>{" "}
                      <FaInfoCircle
                        data-tip="Primary workplace, business, etc. name"
                        data-place="right"
                        color="blue"
                        data-background-color="#fff"
                        data-text-color="black "
                        data-arrow-color="#fff"
                      ></FaInfoCircle>
                      <ReactTooltip place="right" type="info" effect="solid" />
                      <select
                        className="form-control"
                        required
                        name="tempAffliations"
                        defaultValue={this.state.Affliations}
                        onChange={(e) => {
                          this.handleChange(e);
                          this.enableOrgOtherText(e);
                        }}
                      >
                        {orgDropDown}
                        <option key="other" value="Other">
                          Other
                        </option>
                      </select>
                    </div>
                    {this.state.enableOtherOrg && (
                      <div className="form-group mt-3">
                        <input
                          name="orgOneOther"
                          className="form-control form-control-alternative"
                          placeholder="Type your Organization name"
                          onChange={this.handleChange}
                        />
                      </div>
                    )}
                    <div className="form-group">
                      <label>Secondary Affliations </label>{" "}
                      <FaInfoCircle
                        data-tip="List partner organizations, networks, etc.)"
                        data-place="right"
                        color="blue"
                        data-background-color="#fff"
                        data-text-color="black "
                        data-arrow-color="#fff"
                      ></FaInfoCircle>
                      <ReactTooltip place="right" type="info" effect="solid" />
                      {/* <select
                        className="form-control"
                        name="tempSub_Affliations"
                        defaultValue={this.state.Sub_Affliations}
                        onChange={(e) => {
                          this.handleChange(e);
                          this.enableOrgOtherSubText(e);
                        }}
                      >
                        {orgDropDown}
                        <option key="other" value="OtherSub">
                          Other
                        </option>
                      </select> */}
                      <Select 
                      components={animatedComponents}
                      isMulti
                      defaultValue={secAffDefaultDD}
                      options={multiOrgDropDown} 
                      id="tempSub_Affliations"
                      name="tempSub_Affliations"
                      onChange={this.handleSecondaryAffChange}
                      />
                    </div>
                    {this.state.enableOtherSubOrg && (
                      <div className="form-group mt-3">
                        <input
                          name="orgTwoOther"
                          className="form-control form-control-alternative"
                          placeholder="Type your Organization name"
                          onChange={this.handleChange}
                        />
                      </div>
                    )}
                    <div className="form-group">
                      <label>Website* </label>{" "}
                      <FaInfoCircle
                        data-tip="Preferred webpage to link to website"
                        data-place="right"
                        color="blue"
                        data-background-color="#fff"
                        data-text-color="black "
                        data-arrow-color="#fff"
                      ></FaInfoCircle>
                      <ReactTooltip place="right" type="info" effect="solid" />
                      <input
                        type="text"
                        name="tempWebsite"
                        maxLength={150}
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        defaultValue={this.state.website}
                        onChange={this.handleChange}
                      />
                    </div>
                    {/* Competency BOX */}
                    <label className="mr-2">
                      Select{" "}
                      <a href="/competencies" target="_blank">
                        Competencies*
                      </a>{" "}
                    </label>
                    <FaInfoCircle
                      data-tip="Identify up to three core competencies that you have, including sub-categories, if prefer, can also list learning objectives"
                      data-place="right"
                      color="blue"
                      data-background-color="#fff"
                      data-text-color="black "
                      data-arrow-color="#fff"
                    ></FaInfoCircle>
                    <ReactTooltip place="right" type="info" effect="solid" />
                    <br />
                    <div className="form-group mx-4">
                      {this.state.categories.map((category, index) => {
                        return (
                          <div key={index}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                value={category.value}
                                onChange={(e) =>
                                  this.handleChecks(
                                    e.target.checked,
                                    category.value
                                  )
                                }
                                disabled={category.disabled}
                                checked={category.isChecked}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                {category.value}
                              </label>
                            </div>
                            {category.isChecked && (
                              <div className="mx-5">
                                {category.sub_categories.map(
                                  (sub_category, index) => {
                                    return (
                                      <div key={index}>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="inlineCheckbox2"
                                            value={sub_category}
                                            disabled={category.disabled}
                                            onChange={(e) =>
                                              this.getCatDetails(
                                                e.target.checked,
                                                category.value,
                                                sub_category
                                              )
                                            }
                                            checked={
                                              this.state.sub_categoriesLocdict[
                                                sub_category
                                              ].isChecked
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="inlineCheckbox2"
                                          >
                                            {sub_category}
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </section>
                )}
                {/* WORK LOCATION*/}
                <div className="form-group">
                  <label>Primary Office Location* </label>{" "}
                  <FaInfoCircle
                    data-tip="Primary office address"
                    data-place="right"
                    color="blue"
                    data-background-color="#fff"
                    data-text-color="black "
                    data-arrow-color="#fff"
                  ></FaInfoCircle>
                  <ReactTooltip place="right" type="info" effect="solid" />
                  <input
                    type="text"
                    maxLength={255}
                    name="tempWork_location"
                    className={
                      this.props.enableProfileEdit
                        ? "form-control"
                        : "form-control disableProfile"
                    }
                    defaultValue={this.state.work_location}
                    onChange={this.handleChange}
                  />
                </div>

                {/* AREA OF WORK */}
                {!this.props.enableProfileEdit && (
                  <div className="form-group">
                    <label>Area of Work* </label>{" "}
                    <FaInfoCircle
                      data-tip="Consider the breadth of your work, select if you work on a city, county, state, regional, or national level"
                      data-place="right"
                      color="blue"
                      data-background-color="#fff"
                      data-text-color="black "
                      data-arrow-color="#fff"
                    ></FaInfoCircle>
                    <ReactTooltip place="right" type="info" effect="solid" />
                    <input
                      type="text"
                      name="tempWork_area"
                      className={
                        this.props.enableProfileEdit
                          ? "form-control"
                          : "form-control disableProfile"
                      }
                      defaultValue={this.state.work_area}
                    />
                  </div>
                )}
                {this.props.enableProfileEdit && (
                  <div className="form-group">
                    <label>Area of Work* </label>{" "}
                    <FaInfoCircle
                      data-tip="Consider the breadth of your work, select if you work on a city, county, state, regional, or national level"
                      data-place="right"
                      color="blue"
                      data-background-color="#fff"
                      data-text-color="black "
                      data-arrow-color="#fff"
                    ></FaInfoCircle>
                    <ReactTooltip place="right" type="info" effect="solid" />
                    <select
                      className="form-control"
                      required
                      name="tempWork_area"
                      defaultValue={this.state.work_area}
                      onChange={this.handleChange}
                    >
                      <option value="City">City</option>
                      <option value="County">County</option>
                      <option value="State">State</option>
                      <option value="Region">Region</option>
                      <option value="National">National</option>
                    </select>
                  </div>
                )}
                <div className="form-group">
                  <label>Bio</label>{" "}
                  <FaInfoCircle
                    data-tip="Share about your work in food systems, including experience, education, etc"
                    data-place="right"
                    color="blue"
                    data-background-color="#fff"
                    data-text-color="black "
                    data-arrow-color="#fff"
                  ></FaInfoCircle>
                  <ReactTooltip place="right" type="info" effect="solid" />
                  <textarea
                    rows="5"
                    name="tempBio"
                    maxLength={1500}
                    className={
                      this.props.enableProfileEdit
                        ? "form-control"
                        : "form-control disableProfile"
                    }
                    defaultValue={this.state.bio}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>Additional Resources</label>{" "}
                  <FaInfoCircle
                    data-tip="If you'd like to share additional resources, please add in descriptor text and web hyperlink"
                    data-place="right"
                    color="blue"
                    data-background-color="#fff"
                    data-text-color="black "
                    data-arrow-color="#fff"
                  ></FaInfoCircle>
                  <ReactTooltip place="right" type="info" effect="solid" />
                  {/* <textarea
                    rows="5"
                    name="additionalInfo"
                    maxLength={1500}
                    className={
                      this.props.enableProfileEdit
                        ? "form-control"
                        : "form-control disableProfile"
                    }
                    defaultValue={this.state.additionalInfo}
                    onChange={this.handleChange}
                  /> */}
                  <div className="row">
                    <div className="col-4">
                      <input
                        type="text"
                        maxLength={255}
                        name="urlAddText1"
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        placeholder={
                          this.props.enableProfileEdit
                            ? "Website Text eg:Iowa State Extension"
                            : ""
                        }
                        defaultValue={this.state.urlAddText1}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        maxLength={255}
                        name="urlAdd1"
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        placeholder={
                          this.props.enableProfileEdit
                            ? "Website Address eg:www.extension.iastate.edu/edu"
                            : ""
                        }
                        defaultValue={this.state.urlAdd1}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-4">
                      <input
                        type="text"
                        maxLength={255}
                        name="urlAddText2"
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        placeholder={
                          this.props.enableProfileEdit
                            ? "Website Text eg:Iowa State Extension"
                            : ""
                        }
                        defaultValue={this.state.urlAddText2}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        maxLength={255}
                        name="urlAdd2"
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        placeholder={
                          this.props.enableProfileEdit
                            ? "Website Address eg:www.extension.iastate.edu/edu"
                            : ""
                        }
                        defaultValue={this.state.urlAdd2}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  {/* 3rd addiional Info */}
                  <div className="row pt-3">
                    <div className="col-4">
                      <input
                        type="text"
                        maxLength={255}
                        name="urlAddText3"
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        placeholder={
                          this.props.enableProfileEdit
                            ? "Website Text eg:Iowa State Extension"
                            : ""
                        }
                        defaultValue={this.state.urlAddText3}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        maxLength={255}
                        name="urlAdd3"
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        placeholder={
                          this.props.enableProfileEdit
                            ? "Website Address eg:www.extension.iastate.edu/edu"
                            : ""
                        }
                        defaultValue={this.state.urlAdd3}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  {/* 4th addiional Info */}
                  <div className="row pt-3">
                    <div className="col-4">
                      <input
                        type="text"
                        maxLength={255}
                        name="urlAddText4"
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        placeholder={
                          this.props.enableProfileEdit
                            ? "Website Text eg:Iowa State Extension"
                            : ""
                        }
                        defaultValue={this.state.urlAddText4}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        maxLength={255}
                        name="urlAdd4"
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        placeholder={
                          this.props.enableProfileEdit
                            ? "Website Address eg:www.extension.iastate.edu/edu"
                            : ""
                        }
                        defaultValue={this.state.urlAdd4}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  {/* 5th addiional Info */}
                  <div className="row pt-3">
                    <div className="col-4">
                      <input
                        type="text"
                        maxLength={255}
                        name="urlAddText5"
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        placeholder={
                          this.props.enableProfileEdit
                            ? "Website Text eg:Iowa State Extension"
                            : ""
                        }
                        defaultValue={this.state.urlAddText5}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        maxLength={255}
                        name="urlAdd5"
                        className={
                          this.props.enableProfileEdit
                            ? "form-control"
                            : "form-control disableProfile"
                        }
                        placeholder={
                          this.props.enableProfileEdit
                            ? "Website Address eg:www.extension.iastate.edu/edu"
                            : ""
                        }
                        defaultValue={this.state.urlAdd5}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={this.newEducationalResource}
                >
                  Add New Educational Resource
                </button>
                <button
                  className="btn btn-sm btn-primary float-right"
                  onClick={this.changePassword}
                >
                  Change Password
                </button>
              </form>
            </div>
          </div>
        )}

        {!this.props.email && this.props.navigateToLogin()}
      </div>
    );
  }
}

export default Profile;
